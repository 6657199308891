import { BlockImage, EducationParagraph, EducationTeaser, Money, Output, UpgradeSummary, UpgradeableExpansion, UpgradeableRowContent } from './Content'
import { VideoHomeBlock, useHomeBlockContext, useHomeBlockStateContext } from './HomeBlock'
import { Option, Select, Typography } from '@mui/joy'
import { useModel, useModelInputs, useModelOutputs } from './ModelProvider'
import * as propertyCalculator from '@/lib/calculators/property'
import type * as fabricCalculator from '@/lib/calculators/fabric'
import type * as fabricEnergyCostCalculator from '@/lib/calculators/fabricEnergyCost'
import { FLOAT_PARSER, FieldState, useFieldState } from './form'
import React from 'react'
import { z } from 'zod'
import Model from '@/lib/model'

type FabricLevels = fabricCalculator.FabricLevels
type FabricLevelValues = fabricCalculator.FabricLevelValues

const ID = 'fabric'

export default function FabricBlock({
  playNextButton,
}: {
  playNextButton?: React.ReactNode
}): React.ReactNode {
  const { expandedBlocks } = useHomeBlockContext()
  const { state } = useHomeBlockStateContext()

  const fabricCurrentInputs = useModelInputs('fabric.current')
  const fabricUpgradeInputs = useModelInputs('fabric.upgrade')

  const { totalHeatingCostPerYear: currentCost } = useModelOutputs('fabricEnergyCost.current')
  const { totalHeatingCostPerYear: upgradeCost } = useModelOutputs('fabricEnergyCost.upgrade')

  let title: React.ReactNode
  let instruction: React.ReactNode

  switch (state) {
    case 'setup':
      title = undefined

      instruction = (
        <Typography level="title-sm">
          {expandedBlocks.includes(ID)
            ? 'What insulation do you currently have?'
            : 'Good insulation keeps your home warm and saves you money. Let\'s see how much!'}
        </Typography>
      )

      break
    case 'play':
      title = 'Insulation upgrade'

      const upgrades = getUpgrades(fabricCurrentInputs, fabricUpgradeInputs)
      instruction = upgrades.length > 0
        ? <UpgradeSummary upgrades={upgrades} />
        : 'See how much you can save by upgrading your insulation'

      break
    case 'fix':
      title = 'Current insulation details'
      instruction = 'Update details of your current insulation'
      break
  }

  return (
    <VideoHomeBlock
      id={ID}
      media={<BlockImage alt="An orangutan insulating its home" src="/home_fabric.png" />}
      title={title}
      instruction={instruction}
      savings={currentCost - upgradeCost}
      expansion={<InsulationExpansion playNextButton={playNextButton} />}
    />
  )
}

function InsulationExpansion({ playNextButton }: {
  playNextButton?: React.ReactNode
}) {
  const playNextProps = playNextButton == null
    ? { playNextText: 'Explore heating system savings' }
    : (typeof playNextButton === 'string' ? { playNextText: playNextButton } : { playNextButton })
  return (
    <>
      <EducationTeaser
        text="Insulation keeps your home warm and saves money."
        href="https://www.turbine.education/insulation-upgrade"
      />
      <UpgradeableExpansion
        rows={useRows()}
        {...playNextProps}
      />
    </>
  )
}

function useRows(): UpgradeableRowContent[] {
  const { model } = useModel()

  const current = useFabricFields(model, 'current')
  const upgrade = useFabricFields(model, 'upgrade')

  const propertyOutputs = useModelOutputs('property')

  const fabricEnergyCurrentOutputs = useModelOutputs('fabricEnergy.current')
  const fabricEnergyUpgradeOutputs = useModelOutputs('fabricEnergy.upgrade')

  const fabricEnergyCostCurrentOutputs = useModelOutputs('fabricEnergyCost.current')
  const fabricEnergyCostUpgradeOutputs = useModelOutputs('fabricEnergyCost.upgrade')

  const totalUpgradeCost = getTotalUpgradeCost({
    current: useModelInputs('fabric.current'),
    upgrade: useModelInputs('fabric.upgrade'),
    property: propertyOutputs,
  })

  return [
    {
      current: 'Current insulation',
      upgrade: 'Insulation upgrade',
    },
    {
      icon: 'windsock',
      label: 'Draught proofing',
      education: (
        <EducationParagraph>
          Gaps in window or door frames let cold air in.
          Draught proofing covers these gaps and is therefore a simple and low cost way to reduce your heating bill.
          For example, by adding draught-proofing to ill-fitted outside doors, you can significantly reduce heat loss from your property.
        </EducationParagraph>
      ),
      current: <CurrentInsulationSelect states={{ current, upgrade }} field="infiltration" />,
      upgrade: <UpgradeInsulationSelect states={{ current, upgrade }} field="infiltration" />,
    },
    ...propertyOutputs.roofArea === 0
      ? []
      : [
          {
            icon: 'loftinsulation',
            label: 'Loft insulation',
            education: (
              <EducationParagraph>
                Loft insulation acts a blanket keeping warm air inside the property.
                Most homes have some form of roof or loft insulation, but it's often too thin.
                Upgrading to thicker insulation is straightforward and significantly reduces your energy bill.
              </EducationParagraph>
            ),
            current: <CurrentInsulationSelect states={{ current, upgrade }} field="roofU" />,
            upgrade: <UpgradeInsulationSelect states={{ current, upgrade }} field="roofU" />,
          } as const,
        ],
    {
      icon: 'wallinsulation',
      label: 'Wall insulation',
      education: (
        <EducationParagraph>
          Homes built after the 1990s have well insulated walls.
          For older properties you can reduce your energy bills by adding insulation to outer walls.
          Cavity walls can often be insulated by injecting insulation material into the walls through drilled holes, which are then sealed.
          If cavity wall insulation is not possible, then external wall insulation is another option.
        </EducationParagraph>
      ),
      current: <CurrentInsulationSelect states={{ current, upgrade }} field="wallU" />,
      upgrade: <UpgradeInsulationSelect states={{ current, upgrade }} field="wallU" />,
    },
    {
      icon: 'house',
      label: 'Floor insulation',
      education: (
        <EducationParagraph>
          Insulating your ground floor helps keep your property warm.
          Generally, only the ground floor needs it.
          However, if your floor is above an unheated space like a garage, consider insulating it to prevent heat loss.
        </EducationParagraph>
      ),
      current: <CurrentInsulationSelect states={{ current, upgrade }} field="floorU" />,
      upgrade: <UpgradeInsulationSelect states={{ current, upgrade }} field="floorU" />,
    },
    {
      icon: 'window',
      label: 'Double glazing',
      education: (
        <EducationParagraph>
          Single glazed or old double glazed windows are responsible for significant heat loss.
          Installing modern double glazed or triple glazed windows is expensive, however you will see a sizeable reduction in your energy bill.
        </EducationParagraph>
      ),
      current: <CurrentInsulationSelect states={{ current, upgrade }} field="windowU" />,
      upgrade: <UpgradeInsulationSelect states={{ current, upgrade }} field="windowU" />,
    },
    {
      icon: 'money',
      label: 'Upgrade cost',
      education: (
        <EducationParagraph>
          This is the ballpark cost to upgrade your insulation.
          Investing in insulation saves you money in the long run, especially as energy bills continue to rise.
        </EducationParagraph>
      ),
      current: null,
      upgrade: <Money value={totalUpgradeCost} />,
    },
    {
      current: 'Cost of heating',
      upgrade: 'Insulation savings',
    },
    {
      icon: 'gasburner',
      label: 'Heat loss in winter',
      education: (
        <EducationParagraph>
          'Heat loss in winter' is also known as 'peak heat loss'.
          This is an estimate of how quickly your home loses heat energy on the coldest days in winter.
          The outside temperature used in this calculation is based on your location and guidance provided by MCS.
          The peak heat loss determines the power of heating system that you need to ensure your rooms are cosy even when it's freezing outside.
        </EducationParagraph>
      ),
      current: <Output value={fabricEnergyCurrentOutputs.peakHeatLossPerHour} unit="kW" maximumFractionDigits={1} />,
      upgrade: <Output value={fabricEnergyUpgradeOutputs.peakHeatLossPerHour} unit="kW" maximumFractionDigits={1} />,
    },
    {
      icon: 'gasburner',
      label: 'Room heating energy',
      education: (
        <EducationParagraph>
          'Room heating energy' is the total heat energy needed to heat your rooms for the months your heating system is on.
          This estimate is based on hourly temperature data that covers the last few years.
          Properties with good insulation use less energy.
        </EducationParagraph>
      ),
      current: <Output value={fabricEnergyCurrentOutputs.totalHeatLossPerYear} unit="kWh pa" roundingIncrement={100} />,
      upgrade: <Output value={fabricEnergyUpgradeOutputs.totalHeatLossPerYear} unit="kWh pa" roundingIncrement={100} />,
    },
    {
      icon: 'energybill',
      label: 'Room heating cost',
      education: (
        <>
          <EducationParagraph>
            'Room heating cost' is a rough estimate of the cost of gas or electricity used to heat your rooms each year.
          </EducationParagraph>
          <EducationParagraph>
            This estimate is based on your current daily routine and current heating system.
            It does not include the cost of heating your hot water or the standing charge.
          </EducationParagraph>
        </>
      ),
      current: <Money value={fabricEnergyCostCurrentOutputs.totalHeatingCostPerYear} suffix="pa" />,
      upgrade: <Money value={fabricEnergyCostUpgradeOutputs.totalHeatingCostPerYear} suffix="pa" />,
    },
    {
      icon: 'energybill',
      label: 'Estimated savings',
      education: (
        <>
          <EducationParagraph>
            'Estimated savings' is how much you could save on your annual energy bill when you upgrade your insulation.
          </EducationParagraph>
          <EducationParagraph>
            This estimate assumes a year with an average winter.
            If you would like a more accurate estimate, please arrange for a professional installer to survey your property.
          </EducationParagraph>
        </>
      ),
      current: null,
      upgrade: (
        <Savings
          current={fabricEnergyCostCurrentOutputs}
          upgrade={fabricEnergyCostUpgradeOutputs}
          k="totalHeatingCostPerYear"
        />
      ),
    },
  ]
}

function CurrentInsulationSelect({
  states,
  field,
}: {
  states: { current: FieldStates, upgrade: FieldStates }
  field: keyof typeof INSULATION_OPTIONS
}): React.ReactNode {
  const options = INSULATION_OPTIONS[field]
  return <InsulationSelect options={options} field={field} states={states} />
}

function UpgradeInsulationSelect({
  states,
  field,
}: {
  states: { current: FieldStates, upgrade: FieldStates }
  field: keyof typeof INSULATION_OPTIONS
}): React.ReactNode {
  const currentValue = states.current[field].value.data

  const options: InsulationOptions = [
    ...INSULATION_OPTIONS[field].filter(([max, _, cost]) => {
      if (cost == null) return false
      if (currentValue == null) return false
      if (currentValue <= max) return false

      // Special case to exclude cavity wall insulation for walls without cavity
      if (currentValue > 1.1 && max === 0.5) return false

      return true
    }),
    [currentValue ?? 0, 'Add upgrade', null],
  ]

  return <InsulationSelect options={options} field={field} states={{ upgrade: states.upgrade }} />
}

function InsulationSelect<K extends keyof FabricLevels>({ states, field, options }: {
  states: { current?: FieldStates, upgrade: FieldStates }
  field: K
  options: InsulationOptions
}): React.ReactNode {
  const [state, name] = 'current' in states && states.current != null
    ? [states.current[field], `fabric.current.${field}`]
    : [states.upgrade[field], `fabric.upgrade.${field}`]

  function onChange(_event: unknown, value: FabricLevels[K] | null): void {
    state.setInput(value != null ? String(value) : '')

    if ('current' in states && states.current != null) {
      if (value != null) {
        states.upgrade[field].setValue(value)
      } else {
        states.upgrade[field].setInput('')
      }
    }
  }

  return (
    <Select
      name={name}
      value={state.value.data}
      onChange={onChange}
      size="sm"
      disabled={options.length === 1}
      placeholder="No upgrades available"
    >
      {options.length > 1 && options.toReversed().map(([max, label]) => (
        <Option key={max} value={max}>{label}</Option>
      ))}
    </Select>
  )
}

function Savings({
  current,
  upgrade,
  k,
}: {
  current: fabricEnergyCostCalculator.Outputs
  upgrade: fabricEnergyCostCalculator.Outputs
  k: keyof fabricEnergyCostCalculator.Outputs
}): React.ReactNode {
  return <Money value={current[k] - upgrade[k]} suffix="pa" />
}

function getUpgrades(current: fabricCalculator.Inputs, upgrade: fabricCalculator.Inputs): string[] {
  return [
    ...current.infiltration !== upgrade.infiltration
      ? [getInsulationOption(upgrade, 'infiltration')[1]]
      : [],
    ...current.roofU !== upgrade.roofU
      ? [getInsulationOption(upgrade, 'roofU')[1]]
      : [],
    ...current.wallU !== upgrade.wallU
      ? [getInsulationOption(upgrade, 'wallU')[1]]
      : [],
    ...current.floorU !== upgrade.floorU
      ? [getInsulationOption(upgrade, 'floorU')[1]]
      : [],
    ...current.windowU !== upgrade.windowU
      ? [getInsulationOption(upgrade, 'windowU')[1]]
      : [],
  ]
}

function getTotalUpgradeCost({
  current,
  upgrade,
  property,
}: {
  current: fabricCalculator.Inputs
  upgrade: fabricCalculator.Inputs
  property: propertyCalculator.Outputs
}): number {
  return componentCost('roofU')
    + componentCost('wallU')
    + componentCost('floorU')
    + componentCost('windowU')
    + componentCost('infiltration')

  function componentCost(k: keyof fabricCalculator.Inputs): number {
    const currentCost = getInsulationOption(current, k)[2]
    const upgradeCost = getInsulationOption(upgrade, k)[2]

    const unitPrice = upgradeCost === null || upgradeCost === currentCost ? 0 : upgradeCost

    let dimension: number
    switch (k) {
      case 'roofU':
        dimension = property.roofArea
        break
      case 'wallU':
        dimension = property.exteriorWallMinusWindowArea
        break
      case 'floorU':
        dimension = property.groundFloorArea
        break
      case 'windowU':
        dimension = property.windowArea
        break
      case 'infiltration':
        dimension = property.floorArea
        break
    }

    return dimension * unitPrice
  }
}

function getInsulationOption(
  states: fabricCalculator.Inputs,
  field: keyof typeof INSULATION_OPTIONS,
): InsulationOption {
  return parseU(field, states[field])
}

type InsulationOption<U extends number = number> = [U, string, number | null]

type InsulationOptions<U extends number = number> = readonly InsulationOption<U>[]

const INSULATION_OPTIONS: {
  [K in keyof FabricLevels]: InsulationOptions<FabricLevels[K]>
} = {
  roofU: [
    [0.15, 'Modern loft 270mm', 20],
    [0.3, 'Medium insulation', null],
    [1, 'Thin insulation', null],
    [2, 'No insulation', null],
  ],
  wallU: [
    [0.2, 'Modern walls', null],
    [0.35, 'External wall insulation', 150],
    [0.5, 'Cavity wall insulation', 35],
    [1.1, 'Walls with unfilled cavity', null],
    [2, 'Walls with no cavity', null],
  ],
  floorU: [
    [0.2, 'Underfloor insulation', 100],
    [0.6, 'Some insulation', null],
    [1, 'No insulation', null],
  ],
  windowU: [
    [1.2, 'Triple glazing', 785],
    [1.8, 'Modern double glazing', 685],
    [3, 'Old double glazing', null],
    [4, 'Some double glazing', null],
    [6, 'No double glazing', null],
  ],
  infiltration: [
    [0.25, 'Draught proofing', 2],
    [0.5, 'A little draughty', null],
    [0.75, 'Draughty', null],
    [1.0, 'Very draughty', null],
  ],
} as const satisfies {
  [K in keyof FabricLevelValues]: FabricLevelOptions<FabricLevelValues[K]>
}

// NOTE: utility type to ensure that each fabric level is mapped
type FabricLevelOptions<L extends readonly [...any[]]> =
  & { [I in keyof L]: InsulationOption<L[I]> }
  & { length: L['length'] }

type FieldStates = {
  [K in keyof fabricCalculator.Inputs]: FieldState<fabricCalculator.Inputs[K]>
}

function useFabricFields(model: Model, instance: 'current' | 'upgrade'): FieldStates {
  const inputs = useModelInputs(`fabric.${instance}`)

  const fields = {
    roofU: useFabricFieldState(model, instance, inputs, 'roofU'),
    wallU: useFabricFieldState(model, instance, inputs, 'wallU'),
    floorU: useFabricFieldState(model, instance, inputs, 'floorU'),
    windowU: useFabricFieldState(model, instance, inputs, 'windowU'),
    infiltration: useFabricFieldState(model, instance, inputs, 'infiltration'),
  }

  return fields
}

function useFabricFieldState<K extends keyof FabricLevels>(
  model: Model,
  instance: 'current' | 'upgrade',
  inputs: fabricCalculator.Inputs,
  field: K,
): FieldState<FabricLevels[K]> {
  return useFieldState(
    U_PARSER[field],
    U_RENDERER[field],
    inputs[field],
    (u) => {
      model.setInputs(`fabric.${instance}`, { [field]: u })
      if (instance === 'current') {
        model.setInputs('fabric.upgrade', { [field]: u })
      }
    },
  )
}

const U_PARSER: {
  [K in keyof FabricLevels]: z.ZodType<FabricLevels[K], z.ZodTypeDef, string>
} = {
  infiltration: FLOAT_PARSER.transform(u => parseU('infiltration', u)[0]),
  roofU: FLOAT_PARSER.transform(u => parseU('roofU', u)[0]),
  wallU: FLOAT_PARSER.transform(u => parseU('wallU', u)[0]),
  floorU: FLOAT_PARSER.transform(u => parseU('floorU', u)[0]),
  windowU: FLOAT_PARSER.transform(u => parseU('windowU', u)[0]),
}

const U_RENDERER: {
  [K in keyof typeof INSULATION_OPTIONS]: (u: number) => string
} = {
  infiltration: renderU('infiltration'),
  roofU: renderU('roofU'),
  wallU: renderU('wallU'),
  floorU: renderU('floorU'),
  windowU: renderU('windowU'),
}

function parseU<K extends keyof FabricLevels>(field: K, u: number): InsulationOption<FabricLevels[K]> {
  const options = INSULATION_OPTIONS[field]
  const option = options.find(([max]) => u <= max)

  return option ?? options[options.length - 1]!
}

function renderU<K extends keyof typeof INSULATION_OPTIONS>(field: K): (u: number) => string {
  return u => String(parseU(field, u))
}
