import { Option, Typography } from '@mui/joy'
import React from 'react'
import { z } from 'zod'
import * as solarCalculator from '@/lib/calculators/solar'
import { BlockImage, EducationTeaser, Money, Output, UpgradeableExpansion, UpgradeableRowContent } from './Content'
import { useHomeBlockStateContext, VideoHomeBlock } from './HomeBlock'
import { FieldState, INT_PARSER, Select, useFieldState } from './form'
import { useModel, useModelInputs, useModelOutputs } from './ModelProvider'

const ID = 'solar'

export default function SolarBlock({
  playNextButton,
}: {
  playNextButton?: React.ReactNode
}): React.ReactNode {
  const { state } = useHomeBlockStateContext()
  const upgradeOutputs = useModelOutputs('solar.upgrade')

  let title: React.ReactNode
  let instruction: React.ReactNode

  switch (state) {
    case 'setup':
      instruction = (
        <Typography level="title-sm">
          Upgrade to free, renewable, and low-carbon electricity.
        </Typography>
      )

      break
    case 'play':
      title = 'Solar upgrade'

      instruction = 'See how much you can save by installing solar panels'

      break
    case 'fix':
      title = 'Current solar details'

      instruction = 'Update your current solar configuration'

      break
  }

  return (
    <VideoHomeBlock
      id={ID}
      media={<BlockImage alt="An orangutan insulating its home" src="/home_solar.png" />}
      title={title}
      instruction={instruction}
      savings={upgradeOutputs.estimatedSavings}
      expansion={<SolarExpansion playNextButton={playNextButton} />}
    />
  )
}

function SolarExpansion({
  playNextButton,
}: {
  playNextButton?: React.ReactNode
}): React.ReactNode {
  return (
    <>
      <EducationTeaser
        text="Upgrade to free, renewable, and low-carbon electricity."
        href="https://www.turbine.education/solar-panels"
      />
      <UpgradeableExpansion rows={useRows()} playNextButton={playNextButton} />
    </>
  )
}

function useRows(): UpgradeableRowContent[] {
  const upgradeOutputs = useModelOutputs('solar.upgrade')

  const upgradeFields = useFieldStates()
  const currentFields = useFieldStates(upgradeFields)

  return [
    {
      current: 'Solar panel base',
      upgrade: 'Solar & battery upgrade',
    },
    {
      icon: 'loftinsulation',
      label: 'Roof type',
      current: <RoofTypeSelect current={currentFields.roofType} />,
      upgrade: <RoofTypeSelect upgrade={upgradeFields.roofType} />,
    },
    {
      icon: 'loftinsulation',
      label: 'Slope',
      current: <RoofSlopeSelect current={currentFields.roofSlope} />,
      upgrade: <RoofSlopeSelect upgrade={upgradeFields.roofSlope} />,
    },
    {
      icon: 'loftinsulation',
      label: 'Panels facing',
      current: <RoofAzimuthSelect current={currentFields.roofAzimuth} />,
      upgrade: <RoofAzimuthSelect upgrade={upgradeFields.roofAzimuth} />,
    },
    {
      icon: 'lightningbolt',
      label: 'Solar panels',
      current: null,
      upgrade: <SolarPanelCountSelect upgrade={upgradeFields} />,
    },
    {
      icon: 'loftinsulation',
      label: 'Roof covered',
      current: null,
      upgrade: (
        <Output
          value={upgradeOutputs.roofCoverage * 100}
          unit="%"
          maximumFractionDigits={0}
        />
      ),
    },
    {
      icon: 'lightningbolt',
      label: 'Peak power',
      current: null,
      upgrade: (
        <Output
          value={upgradeOutputs.solarPeakPower}
          unit="kW"
          maximumFractionDigits={1}
        />
      ),
    },
    {
      icon: 'lightningbolt',
      label: 'Battery size',
      current: null,
      upgrade: (
        <Output
          value={upgradeOutputs.batterySize}
          unit="kW"
          maximumFractionDigits={0}
        />
      ),
    },
    {
      icon: 'money',
      label: 'Upgrade cost',
      current: null,
      upgrade: <Money value={upgradeOutputs.upgradeCost} />,
    },
    {
      current: '',
      upgrade: 'Solar savings',
    },
    {
      icon: 'lightningbolt',
      label: 'Solar energy used',
      current: null,
      upgrade: (
        <Output
          value={upgradeOutputs.solarEnergyUsed}
          unit="kWh pa"
          maximumFractionDigits={0}
          roundingIncrement={100}
        />
      ),
    },
    {
      icon: 'energybill',
      label: 'Estimated savings',
      current: null,
      upgrade: (
        <Money value={upgradeOutputs.estimatedSavings} suffix="pa" />
      ),
    },
  ]
}

type Upgradable<T> = { current: T } | { upgrade: T }

function RoofTypeSelect(
  props: Upgradable<FieldState<solarCalculator.RoofType>>,
): React.ReactNode {
  const name = `solar.${'current' in props ? 'current' : 'upgrade'}.roofType`

  const state = 'current' in props ? props.current : props.upgrade

  return (
    <Select name={name} state={state} disabled={'upgrade' in props}>
      <Option value="flat">Flat</Option>
      <Option value="pitched">Pitched</Option>
    </Select>
  )
}

function RoofSlopeSelect(
  props: Upgradable<FieldState<solarCalculator.RoofSlope>>,
): React.ReactNode {
  const instance = 'current' in props ? 'current' : 'upgrade'

  const name = `solar.${instance}.roofSlope`

  const state = 'current' in props ? props.current : props.upgrade

  const { roofType } = useModelInputs(`solar.${instance}`)

  return (
    <Select name={name} state={state} disabled={'upgrade' in props || roofType == 'flat'}>
      {roofType === 'flat' && <Option value="0">0°C</Option>}
      <Option value="25">25°</Option>
      <Option value="30">30°</Option>
      <Option value="35">35°</Option>
      <Option value="40">40°</Option>
      <Option value="45">45°</Option>
    </Select>
  )
}

function RoofAzimuthSelect(
  props: Upgradable<FieldState<RoofAzimuthOption>>,
): React.ReactNode {
  const instance = 'current' in props ? 'current' : 'upgrade'

  const name = `solar.${instance}.roofAzimuth`

  const state = 'current' in props ? props.current : props.upgrade

  const { roofType } = useModelInputs(`solar.${instance}`)

  return (
    <Select name={name} state={state} disabled={'upgrade' in props || roofType === 'flat'}>
      <Option value="East">East</Option>
      <Option value="South East">South East</Option>
      <Option value="South">South</Option>
      <Option value="South West">South West</Option>
      <Option value="West">West</Option>
      <Option value="East & West">East & West</Option>
    </Select>
  )
}

function SolarPanelCountSelect(props: Upgradable<{
  roofAzimuth: FieldState<RoofAzimuthOption>
  solarPanelCount: FieldState<number>
}>): React.ReactNode {
  const instance = 'current' in props ? 'current' : 'upgrade'

  const name = `solar.${instance}.solarPanelCount`

  const state = 'current' in props ? props.current : props.upgrade

  const property = useModelOutputs('property')
  const { roofType } = useModelInputs(`solar.${instance}`)

  const options = React.useMemo(() => {
    const maxCoverage = roofType === 'flat' || state.roofAzimuth.value.data === 'East & West'
      ? 1
      : 0.5

    return new Array(20)
      .fill(null)
      .map((_, index) => index + 1)
      .filter((value) => {
        return solarCalculator.getRoofCoverage(property, value) <= maxCoverage
      })
  }, [property, roofType, state.roofAzimuth.value])

  React.useEffect(() => {
    const solarPanelCount = state.solarPanelCount.value.data
    if (solarPanelCount != null && !options.includes(solarPanelCount)) {
      state.solarPanelCount.setInput(String(options[options.length - 1]))
    }
  }, [state.solarPanelCount, options])

  return (
    <Select name={name} state={state.solarPanelCount}>
      {options.map(option => (
        <Option key={option} value={String(option)}>{option}</Option>
      ))}
    </Select>
  )
}

type FieldStates = {
  [K in keyof solarCalculator.Inputs]: K extends 'roofAzimuth'
    ? FieldState<RoofAzimuthOption>
    : FieldState<solarCalculator.Inputs[K]>
}

function useFieldStates(
  upgrade?: FieldStates,
): FieldStates {
  const instance = upgrade != null ? 'current' : 'upgrade'

  const { model } = useModel()
  const inputs = useModelInputs(`solar.${instance}`)
  const { roofType, roofSlope, roofAzimuth, solarPanelCount } = inputs

  const states: FieldStates = {
    roofType: useFieldState(ROOF_TYPE_PARSER, String, roofType, (roofType) => {
      const roofSlope = 35
      const roofAzimuth = 0

      model.setInputs(`solar.${instance}`, { roofType, roofSlope, roofAzimuth })

      states.roofSlope.setValue(roofSlope)

      states.roofAzimuth.setValue(ROOF_AZIMUTH_VALUE_TO_OPTION[roofAzimuth])

      if (upgrade != null) {
        model.setInputs('solar.upgrade', { roofType, roofSlope, roofAzimuth })

        upgrade.roofType.setValue(roofType)

        if (roofSlope != null) {
          upgrade.roofSlope.setValue(roofSlope)
        }

        if (roofAzimuth != null) {
          upgrade.roofAzimuth.setValue(ROOF_AZIMUTH_VALUE_TO_OPTION[roofAzimuth])
        }
      }
    }),
    roofSlope: useFieldState(ROOF_SLOPE_PARSER, String, roofSlope, (roofSlope) => {
      model.setInputs(`solar.${instance}`, { roofSlope })
      if (upgrade != null) {
        model.setInputs('solar.upgrade', { roofSlope })
        upgrade?.roofSlope.setValue(roofSlope)
      }
    }),
    roofAzimuth: useFieldState(ROOF_AZIMUTH_OPTION_PARSER, String, ROOF_AZIMUTH_VALUE_TO_OPTION[roofAzimuth], (option) => {
      const roofAzimuth = ROOF_AZIMUTH_OPTION_TO_VALUE[option]
      model.setInputs(`solar.${instance}`, { roofAzimuth })
      if (upgrade != null) {
        model.setInputs('solar.upgrade', { roofAzimuth })
        upgrade?.roofAzimuth.setValue(option)
      }
    }),
    solarPanelCount: useFieldState(INT_PARSER, String, solarPanelCount, (solarPanelCount) => {
      model.setInputs(`solar.${instance}`, { solarPanelCount })
      if (upgrade != null) {
        model.setInputs('solar.upgrade', { solarPanelCount })
        upgrade?.solarPanelCount.setValue(solarPanelCount)
      }
    }),
  }

  return states
}

const ROOF_TYPE_PARSER = z.union([
  z.literal('flat'),
  z.literal('pitched'),
]) satisfies z.ZodType<solarCalculator.RoofType, z.ZodTypeDef, string>

const ROOF_SLOPE_PARSER = INT_PARSER.pipe(z.union([
  z.literal(25),
  z.literal(30),
  z.literal(35),
  z.literal(40),
  z.literal(45),
])) satisfies z.ZodType<solarCalculator.RoofSlope, z.ZodTypeDef, string>

type RoofAzimuthOption = typeof ROOF_AZIMUTH_OPTIONS[number]

const ROOF_AZIMUTH_OPTIONS = [
  'East',
  'South East',
  'South',
  'South West',
  'West',
  'East & West',
] as const

const ROOF_AZIMUTH_OPTION_PARSER = z.union([
  z.literal(ROOF_AZIMUTH_OPTIONS[0]),
  z.literal(ROOF_AZIMUTH_OPTIONS[1]),
  ...ROOF_AZIMUTH_OPTIONS.slice(2).map(o => z.literal(o)),
]) satisfies z.ZodType<RoofAzimuthOption>

const ROOF_AZIMUTH_VALUE_TO_OPTION: { [K in solarCalculator.RoofAzimuth]: RoofAzimuthOption } = {
  [-90]: 'East',
  [-45]: 'South East',
  [0]: 'South',
  [45]: 'South West',
  [90]: 'West',
}

const ROOF_AZIMUTH_OPTION_TO_VALUE: { [K in RoofAzimuthOption]: solarCalculator.RoofAzimuth } = {
  'East': -90,
  'South East': -45,
  'South': 0,
  'South West': 45,
  'West': 90,
  // NOTE: we might need to do something here if azimuth is used in the calculation
  'East & West': 90,
}
