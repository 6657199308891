'use client'

import { getEnvKey } from '@/lib/env'
import React from 'react'
import { publicUrl } from '@/lib/public'
import { DEFAULT_LOCATION_INPUTS } from '@/app/default-location'

const GOOGLE_STEETVIEW_URL = 'https://maps.googleapis.com/maps/api/streetview'
const DEFAULT_LOCATION = '27 Upper Belgrave Road, Bristol, BS8 2XL'
const FALLBACK_SRC = publicUrl('/home_property.png')

const API_KEY = getEnvKey('NEXT_PUBLIC_GOOGLE_MAPS_API_KEY')

type PropertyImageProps = {
  address: string
  postcode: string
  width?: number
  height?: number
}

export default function PropertyImage({
  address,
  postcode,
  width,
  height,
}: PropertyImageProps): React.ReactNode {
  const [url, setUrl] = React.useState(getImageUrl({
    address,
    postcode,
    width,
    height,
  }))

  const handleError = async (): Promise<void> => {
    setUrl(FALLBACK_SRC)
  }

  React.useEffect(() => {
    const url = getImageUrl({
      address,
      postcode,
      width,
      height,
    })
    setUrl(url)
  }, [address, postcode, width, height])

  return (
    <img
      src={url.toString()}
      width={width ?? 480}
      height={height ?? 100}
      style={{ display: 'block', objectFit: 'cover', width: '100%', height: '100%' }}
      alt={`Image of ${address}`}
      onError={handleError}
    />
  )
}

function getImageUrl({
  address,
  postcode,
  width,
  height,
}: PropertyImageProps): string {
  const location = address === DEFAULT_LOCATION_INPUTS.address
    ? DEFAULT_LOCATION
    : `${address}, ${postcode}`

  const url = new URL(GOOGLE_STEETVIEW_URL)
  url.searchParams.append('size', `${width ?? 480}x${height ?? 240}`)
  url.searchParams.append('fov', String(120))
  url.searchParams.append('key', API_KEY)
  url.searchParams.append('return_error_code', 'true')
  url.searchParams.append('location', location)
  return url.toString()
}
