import z from 'zod'
import { AgeBand, EpcSchema, type Epc, compareEpc } from './common'

const GET_REQUEST_URL = 'https://epc.opendatacommunities.org/api/v1/domestic/search'

const BAND_MAPPING: Record<string, AgeBand> = {
  'England and Wales: before 1900': 'A',
  'England and Wales: 1900-1929': 'B',
  'England and Wales: 1930-1949': 'C',
  'England and Wales: 1950-1966': 'D',
  'England and Wales: 1967-1975': 'E',
  'England and Wales: 1976-1982': 'F',
  'England and Wales: 1983-1990': 'G',
  'England and Wales: 1991-1995': 'H',
  'England and Wales: 1996-2002': 'I',
  'England and Wales: 2003-2006': 'J',
  'England and Wales: 2007 onwards': 'K',
  'England and Wales: 2007-2011': 'K',
  'England and Wales: 2012 onwards': 'L',
}

const UPPER_BAND_MAPPING: Array<[AgeBand, number]> = [
  ['A', 1899],
  ['B', 1929],
  ['C', 1949],
  ['D', 1966],
  ['E', 1975],
  ['F', 1982],
  ['G', 1990],
  ['H', 1995],
  ['I', 2002],
  ['J', 2006],
  ['K', 2011],
  ['L', 9999],
]

const EpcRowSchema = EpcSchema.extend({
  'construction-age-band': z.string().transform((val) => {
    const maybeMapped = BAND_MAPPING[val]
    if (maybeMapped) return maybeMapped

    const age = parseInt(val)
    if (!isNaN(age)) {
      return UPPER_BAND_MAPPING.find(([, upper]) => age <= upper)?.[0]
    }
    return undefined
  }).optional().catch(undefined),
}) satisfies z.ZodType<Epc, z.ZodTypeDef, unknown>

const EpcResponseSchema = z.object({
  rows: z.array(EpcRowSchema),
})

type Config = {
  username: string
  apiKey: string
}

export async function getEpc(
  postcode: string,
  address: string,
  { username, apiKey }: Config
): Promise<Epc | null> {
  const url = `${GET_REQUEST_URL}?${new URLSearchParams({ postcode, address })}`
  const auth = Buffer.from(username + ':' + apiKey).toString('base64')

  const response = await fetch(url, {
    headers: {
      accept: 'application/json',
      authorization: `Basic ${auth}`,
    },
  })

  if (!response.ok) {
    throw new Error(`EPC request failed: ${response.status} - ${response.statusText}`)
  }

  let result: unknown
  try {
    result = await response.json()
  } catch {
    return null
  }

  try {
    const parsed = EpcResponseSchema.parse(result)

    const latest = parsed.rows.sort(compareEpc)

    return latest[0] ?? null
  } catch (cause) {
    throw new Error('Unexpected EPC response', { cause })
  }
}
