import * as locationCalculator from '@/lib/calculators/location'

export const DEFAULT_SEARCH_ID = 'a530d31d-fe25-4467-a527-71af9769909d'

export const DEFAULT_LOCATION_INPUTS: locationCalculator.Inputs = {
  address: 'Upper Belgrave Road, Bristol',
  postcode: 'BS8 2XL',
  latitude: 51.4692,
  longitude: -2.61834,
}
