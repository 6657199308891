import * as fabricEnergyCalculator from './fabricEnergy'
import * as heatingCalculator from './heating'

export type Dependencies = {
  fabricEnergy: fabricEnergyCalculator.Outputs
  heating: heatingCalculator.Outputs
}

export type Inputs = {
  // NOTE: This fields is unused, but having `{}` as an input type weakens type-checking
  _fabricEnergyCost: null
}

export type Outputs = {
  roofHeatingCostPerYear: number
  wallHeatingCostPerYear: number
  floorHeatingCostPerYear: number
  windowHeatingCostPerYear: number
  draughtHeatingCostPerYear: number
  totalHeatingCostPerYear: number
}

export function getOutputs({ fabricEnergy, heating }: Dependencies, {}: Inputs): Outputs {
  const roofHeatingCostPerYear = getHeatingCostPerYear(
    { heating },
    fabricEnergy.roofHeatLossPerYear,
  )

  const wallHeatingCostPerYear = getHeatingCostPerYear(
    { heating },
    fabricEnergy.wallHeatLossPerYear,
  )

  const floorHeatingCostPerYear = getHeatingCostPerYear(
    { heating },
    fabricEnergy.floorHeatLossPerYear,
  )

  const windowHeatingCostPerYear = getHeatingCostPerYear(
    { heating },
    fabricEnergy.windowHeatLossPerYear,
  )

  const draughtHeatingCostPerYear = getHeatingCostPerYear(
    { heating },
    fabricEnergy.draughtHeatLossPerYear,
  )

  const totalHeatingCostPerYear = roofHeatingCostPerYear + wallHeatingCostPerYear + floorHeatingCostPerYear + windowHeatingCostPerYear + draughtHeatingCostPerYear

  return {
    roofHeatingCostPerYear,
    wallHeatingCostPerYear,
    floorHeatingCostPerYear,
    windowHeatingCostPerYear,
    draughtHeatingCostPerYear,
    totalHeatingCostPerYear,
  }
}

function getHeatingCostPerYear(
  { heating }: Pick<Dependencies, 'heating'>,
  heatLossPerYear: number,
): number {
  return heating.heatUnitCost * heatLossPerYear
}
