'use client'

import React from 'react'
import { Button, FormControl, Option, Stack, Typography, Sheet, Box } from '@mui/joy'
import { EducationParagraph, Row, Table } from './Content'
import * as locationCalculator from '@/lib/calculators/location'
import * as propertyCalculator from '@/lib/calculators/property'
import { Address } from '@/lib/components/AddressAutocomplete'
import { z } from 'zod'
import { useModel, useModelInputs, useModelOutputs } from './ModelProvider'
import { useHomeBlockContext, useHomeBlockStateContext } from './HomeBlock'
import { getHeatingRadiatorCountInput, getPropertyFloorCountInput } from './model-inputs'
import { FLOAT_PARSER, FieldState, INT_PARSER, NumberInput, Select, Slider, useFieldState } from './form'
import AddressSelector from '@/lib/components/AddressSelector'
import PropertyImage from '@/lib/PropertyImage'

const BUILD_YEAR_OPTIONS: [string, string][] = [
  ['Before 1900s', '1894'],
  ...Array.from(
    { length: Math.ceil((new Date().getFullYear() - 1900) / 10) },
    (_, i): [string, string] => {
      const decade = 1900 + i * 10
      return [`${decade}s`, `${decade + 4}`]
    }
  ),
]

const PROPERTY_TYPE_OPTIONS: Record<string, string> = {
  bungalow: 'Bungalow',
  detached: 'Detached',
  endTerrace: 'End-terrace',
  flat: 'Flat',
  semiDetached: 'Semi-detached',
  terrace: 'Terrace',
}

export default function PropertyBlock(): React.ReactNode {
  const { model, setReady: setModelReady, saveSearch, searchId, reset: resetModel, ready: modelReady } = useModel()
  const { current, expandedBlocks, nudged, toggleExpanded } = useHomeBlockContext()
  const { state, transition, reset: resetState } = useHomeBlockStateContext()
  const ref = React.useRef<HTMLDivElement>(null)

  React.useEffect(() => {
    if (ref.current && current === 'property') {
      ref.current.scrollIntoView(true)
    }
  }, [current])

  const expanded = expandedBlocks.includes('property') && state !== 'setup'

  const { address, postcode } = useModelInputs('location')

  const { propertyType, floorArea, floorCount, buildYear, ceilingHeight }
    = useModelInputs('property')

  const fields = {
    propertyType: useFieldState(PROPERTY_TYPE_PARSER, String, propertyType, (propertyType) => {
      model.setInputs('property', {
        propertyType,
        // Reset the floor count
        floorCount: getPropertyFloorCountInput(propertyType),
      })
    }),
    floorArea: useFieldState(INT_PARSER, String, floorArea, (floorArea) => {
      model.setInputs('property', { floorArea })

      // Reset the radiator count
      const radiatorCount = getHeatingRadiatorCountInput(floorArea)
      model.setInputs('heating.current', { radiatorCount })
      model.setInputs('heating.upgrade', { radiatorCount })
    }),
    floorCount: useFieldState(INT_PARSER, String, floorCount, (floorCount) => {
      model.setInputs('property', { floorCount })
    }),
    buildYear: useFieldState(INT_PARSER, String, buildYear, (buildYear) => {
      model.setInputs('property', { buildYear })
    }),
    ceilingHeight: useFieldState(FLOAT_PARSER, String, ceilingHeight, (ceilingHeight) => {
      model.setInputs('property', { ceilingHeight })
    }),
  }

  React.useEffect(() => {
    fields.floorCount.setValue(floorCount)
  }, [fields.floorCount, floorCount])

  React.useEffect(() => {
    setModelReady(
      state !== 'setup'
      && fields.propertyType.input !== ''
      && fields.floorArea.input !== ''
      && fields.buildYear.input !== ''
      && fields.ceilingHeight.input !== ''
    )
  }, [setModelReady, state, fields.propertyType.input, fields.floorArea.input, fields.buildYear.input, fields.ceilingHeight.input])

  const actionText = expanded
    ? 'Close'
    : !modelReady
        ? 'Continue setup'
        : searchId == null
          ? 'Enter your address'
          : 'Change'

  async function onExpand(): Promise<void> {
    if (!expanded && state !== 'setup' && searchId == null) {
      resetModel()
      resetState()
    } else {
      await toggleExpanded('property')
    }
  }

  async function onAddressChange(address: Address) {
    const search = await saveSearch(address)

    if (search.epcData == null) {
      fields.propertyType.setInput('')
      fields.floorArea.setInput('')
      fields.buildYear.setInput('')
      setModelReady(false)
    } else {
      const inputs = model.getInputs('property')
      fields.propertyType.setValue(inputs.propertyType)
      fields.floorArea.setValue(inputs.floorArea)
      fields.floorCount.setValue(inputs.floorCount)
      fields.buildYear.setValue(inputs.buildYear)
      fields.ceilingHeight.setValue(inputs.ceilingHeight)
      setModelReady(true)
    }

    await transition('next')
  }

  return (
    <>
      <Sheet
        ref={ref}
        component={Stack}
        gridRow="1"
        gridColumn="1 / span 2"
        direction="column"
        gap={0.5}
        variant={nudged === 'property' ? 'glowing' : 'outlined'}
        borderRadius="xs"
        sx={{
          borderBottomRightRadius: expanded ? 0 : undefined,
          borderBottomLeftRadius: expanded ? 0 : undefined,
          boxShadow: 'none',
        }}
      >
        <Box sx={{
          height: 100,
          mask: 'linear-gradient(white, 85%, transparent)',
          opacity: state === 'setup' ? 0.5 : 1,
        }}
        >
          <PropertyImage address={address} postcode={postcode} />
        </Box>
        <Stack padding={1.5} direction="column">
          {state !== 'setup'
            ? (
              <Stack direction="row" gap={0.5} alignItems="center" justifyContent="space-between">
                <Stack direction="row" alignItems="left" gap={0} sx={{ minWidth: 0 }}>
                  <Typography noWrap level="body-xs">
                    {address}
                    ,
                  </Typography>
                  <Typography level="body-xs" sx={{ minWidth: 72, textAlign: 'left' }}>
                    &nbsp;
                    {postcode}
                  </Typography>
                </Stack>
                <Button
                  size="sm"
                  variant="plain"
                  sx={{ minHeight: 0, padding: 0.5, whiteSpace: 'nowrap' }}
                  color="primary"
                  onClick={onExpand}
                >
                  {actionText}
                </Button>
              </Stack>
              )
            : (
              <AddressSelector submit={onAddressChange} />
              )}
        </Stack>
        {expanded && (
          <PropertyExpansion fields={fields} />
        )}
      </Sheet>
    </>
  )
}

const PROPERTY_TYPE_PARSER = z.enum([
  'detached',
  'semiDetached',
  'terrace',
  'endTerrace',
  'bungalow',
  'flat',
])

function PropertyExpansion({ fields }: {
  fields: {
    [K in keyof propertyCalculator.Inputs]: FieldState<propertyCalculator.Inputs[K]>
  }
}): React.ReactNode {
  const { reset: resetModel, ready: modelReady } = useModel()
  const { setupBlocks } = useHomeBlockContext()
  const { transition, reset: resetState } = useHomeBlockStateContext()

  const [focused, setFocused] = React.useState<keyof propertyCalculator.Inputs | null>(null)

  const locationOutputs = useModelOutputs('location')
  const propertyOutputs = useModelOutputs('property')

  function reset(): void {
    resetModel()
    resetState()
  }

  return (
    <Stack direction="column" gap={1} padding={1.5} sx={{ borderTop: '1px solid var(--joy-palette-neutral-200)' }}>
      <Stack direction="row" justifyContent="space-between" alignItems="center">
        <Typography level="title-md">
          Property Details
        </Typography>
        <Button
          size="sm"
          variant="plain"
          sx={{ alignSelf: 'flex-end', minHeight: 0, padding: 0 }}
          color="primary"
          onClick={reset}
        >
          Change address
        </Button>
      </Stack>
      <Table>
        <Row
          icon="energybill"
          label="Certificates"
          education={(
            <EducationParagraph>
              To sell a property you need an Energy Performance Certificate (EPC).
              If you have an EPC, we use it to automatically find details about your property.
              You will need an EPC before you can get the government BUS grant to help you switch to a heat pump.
            </EducationParagraph>
          )}
        >
          <Typography level="body-sm" textAlign="left">
            {renderHasEpc(locationOutputs, propertyOutputs)}
          </Typography>
        </Row>

        <Row
          icon="house"
          label="Building type"
          education={(
            <EducationParagraph>
              Enter the type of property you have.
              This helps us build a computer model of your property.
            </EducationParagraph>
          )}
          focus={focused === 'propertyType'}
        >
          <FormControl error={fields.propertyType.input === ''} sx={{ flexBasis: 0, flexGrow: 4 }} size="sm">
            <Select name="propertyType" state={fields.propertyType} placeholder="Please select" onFocus={() => setFocused('propertyType')}>
              {Object.entries(PROPERTY_TYPE_OPTIONS).map(([key, value]) => (
                <Option key={key} value={key}>{value}</Option>
              ))}
            </Select>
          </FormControl>
        </Row>

        <Row
          icon="spanner"
          label="Build year"
          education={(
            <EducationParagraph>
              Enter when your property was built.
              If you don't know this, make a guess.
              The build year helps us estimate how well your property is insulated.
            </EducationParagraph>
          )}
          focus={focused === 'buildYear'}
        >
          <FormControl error={fields.buildYear.input === ''} sx={{ flexBasis: 0, flexGrow: 4 }} size="sm">
            <Select name="buildYear" state={fields.buildYear} placeholder="Please select" onFocus={() => setFocused('buildYear')}>
              {BUILD_YEAR_OPTIONS.map(([label, value]) => (
                <Option key={value} value={value}>{label}</Option>
              ))}
            </Select>
          </FormControl>
        </Row>

        <Row
          icon="watertank"
          label="Floor area"
          education={(
            <EducationParagraph>
              Enter the total floor space you have, adding up all of your floors.
              If you don't know this, make a guess.
              Floor area helps us estimate the energy needed to heat your property.
            </EducationParagraph>
          )}
          focus={focused === 'floorArea'}
        >
          <FormControl error={fields.floorArea.input === ''} sx={{ flexBasis: 0, flexGrow: 4, minWidth: 0 }} size="sm">
            <NumberInput
              name="floorArea"
              state={fields.floorArea}
              min={0}
              max={10000}
              placeholder="Please enter"
              unit={(
                <>
                  m
                  <sup>2</sup>
                </>
              )}
              onFocus={() => setFocused('floorArea')}
            />
          </FormControl>
        </Row>

        <Row
          icon="house"
          label="Floors"
          education={(
            <EducationParagraph>
              Enter the number of floors your property has.
              This helps us estimate the size of your outside walls.
            </EducationParagraph>
          )}
          focus={focused === 'floorCount'}
        >
          <Slider name="location.floorCount" state={fields.floorCount} min={1} max={5} onFocus={() => setFocused('floorCount')} />
        </Row>

        <Row
          icon="house"
          label="Room height"
          education={(
            <EducationParagraph>
              Enter the average height of rooms, approximately.
              Floor height helps us estimate the energy needed to heat your property.
            </EducationParagraph>
          )}
          focus={focused === 'ceilingHeight'}
        >
          <FormControl error={fields.ceilingHeight.input === ''} sx={{ flexBasis: 0, flexGrow: 4, minWidth: 0 }} size="sm">
            <NumberInput
              name="location.ceilingHeight"
              state={fields.ceilingHeight}
              min={0}
              max={10}
              placeholder="Please enter"
              unit="m"
              onFocus={() => setFocused('ceilingHeight')}
            />
          </FormControl>
        </Row>
      </Table>

      <Button disabled={!modelReady} onClick={() => transition('next')}>
        {setupBlocks.length > 0
          ? 'Continue setup'
          : 'Explore savings'}
      </Button>
    </Stack>
  )
}

function renderHasEpc(
  { country }: locationCalculator.Outputs,
  { hasEpc }: propertyCalculator.Outputs,
): string {
  if (hasEpc) {
    return 'EPC available'
  } else if (country === 'Northern Ireland') {
    return 'EPC not online in Northern Ireland'
  } else {
    return 'EPC not available'
  }
}
