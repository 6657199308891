import React from 'react'
import * as heatingCalculator from '@/lib/calculators/heating'
import { Option, Select as JoySelect, Typography } from '@mui/joy'
import { z } from 'zod'
import type * as fabricEnergy from '@/lib/calculators/fabricEnergy'
import { formatNumber } from '@/lib/formatters'
import Model from '@/lib/model'
import { useModel, useModelInputs, useModelOutputs } from './ModelProvider'
import { MAX_RADIATORS } from './model-inputs'
import { FieldState, INT_PARSER, Select, useFieldState } from './form'
import { VideoHomeBlock, useHomeBlockContext, useHomeBlockStateContext } from './HomeBlock'
import { BlockImage, EducationParagraph, EducationTeaser, Money, Output, UpgradeSummary, UpgradeableExpansion, UpgradeableRowContent } from './Content'

const ID = 'heating'

const HEATER_UPGRADE_COST = 5600 // Estimate of fees (1000) + labour (1600) + misc materials (3000)
const RADIATOR_UPGRADE_COST = 800 // Estimate of labour (400) + materials (400)
const GRANT = 7500

type HeatPumpOption = [number, number]

const HEAT_PUMP_OPTIONS: HeatPumpOption[] = [
  [3.5, 3500],
  [5, 3600],
  [7, 4000],
  [10, 5500],
  [12, 5800],
]

function getHeatPumpOption(peakHeatLossPerHour: fabricEnergy.Outputs['peakHeatLossPerHour']): HeatPumpOption {
  return HEAT_PUMP_OPTIONS.find(([max]) => peakHeatLossPerHour <= max) ?? HEAT_PUMP_OPTIONS[HEAT_PUMP_OPTIONS.length - 1]!
}

export default function HeatingBlock({
  playNextButton,
}: {
  playNextButton?: React.ReactNode
}): React.ReactNode {
  const { expandedBlocks } = useHomeBlockContext()
  const { state } = useHomeBlockStateContext()

  const heatingCurrentInputs = useModelInputs('heating.current')
  const heatingUpgradeInputs = useModelInputs('heating.upgrade')

  const { totalEnergyCostPerYear: currentCost } = useModelOutputs('heatingEnergyCost.current')
  const { totalEnergyCostPerYear: upgradeCost } = useModelOutputs('heatingEnergyCost.upgrade')

  let title: React.ReactNode
  let instruction: React.ReactNode

  switch (state) {
    case 'setup':
      title = undefined
      instruction = (
        <Typography level="title-sm">
          {expandedBlocks.includes(ID)
            ? 'Describe your current heating system'
            : 'An efficient heating systems uses less energy and saves you money. Let\'s see how much!'}
        </Typography>
      )
      break
    case 'play':
      title = 'Heating system upgrade'

      const upgrades = getUpgrades(heatingCurrentInputs, heatingUpgradeInputs)
      instruction = upgrades.length > 0
        ? <UpgradeSummary upgrades={upgrades} />
        : 'See how much you can save by upgrading your heating system'

      break
    case 'fix':
      title = 'Current heating system'
      instruction = 'Update details of your current heating system'
      break
  }

  return (
    <VideoHomeBlock
      id={ID}
      media={<BlockImage alt="An orangutan tuning its heating system" src="/home_heating.png" />}
      title={title}
      instruction={instruction}
      savings={currentCost - upgradeCost}
      expansion={<HeatingSystemExpansion playNextButton={playNextButton} />}
    />
  )
}

function HeatingSystemExpansion({
  playNextButton,
}: {
  playNextButton?: React.ReactNode
}): React.ReactNode {
  const model = useModel()

  const current = useHeatingFields(model.model, 'current')
  const upgrade = useHeatingFields(model.model, 'upgrade')

  const heatingCurrentInputs = useModelInputs('heating.current')
  const heatingUpgradeInputs = useModelInputs('heating.upgrade')

  const heatingCurrentOutputs = useModelOutputs('heating.current')
  const heatingUpgradeOutputs = useModelOutputs('heating.upgrade')

  const heatingEnergyCostCurrentOutputs = useModelOutputs('heatingEnergyCost.current')
  const heatingEnergyCostUpgradeOutputs = useModelOutputs('heatingEnergyCost.upgrade')

  const { peakHeatLossPerHour } = useModelOutputs('fabricEnergy.current')

  const rows: UpgradeableRowContent[] = [
    {
      current: 'Current heating',
      upgrade: 'Heating upgrade',
    },
    {
      icon: 'heatpump',
      label: 'Heat source',
      education: (
        <>
          <EducationParagraph>
            Your boiler or heat pump heats your rooms and your hot water.
            Gas boilers heat radiators to high temperatures and therefore heat rooms rapidly.
            Whereas heat pumps are on most of the day and heat rooms slowly.
            But contrary to popular belief, heat pumps work even on the coldest winter days, even in an old property with modest insulation.
          </EducationParagraph>
          <EducationParagraph>
            There are two types of heat pumps: air source and ground source.
            The majority of heat pumps are air source, which means they draw air from the outside using a fan.
            Air source heat pumps are typically three or four times more efficient than gas boilers.
            However, the way that electricity is taxed in the UK compared to gas, means that it is only a little cheaper to run a heat pump than a gas boiler.
            There is a strong belief that the government will eventually change the tax to a more equitable system, making heat pumps significantly cheaper to run than gas boilers in future years.
          </EducationParagraph>
          <EducationParagraph>
            To make the switch to heat pumps more affordables, UK governments are currently offering a grant of £7500 to help cover the cost of installing a heat pump.
            In summary, switching to a heat pump future proofs your home!
          </EducationParagraph>
        </>
      ),
      current: (
        <Select
          key={peakHeatLossPerHour}
          name="heating.current.heater"
          state={current.heater}
        >
          {HEATER_OPTIONS.map(([heater, label]) => (
            <Option key={heater} value={heater}>
              {typeof label === 'function' ? label(peakHeatLossPerHour) : label}
            </Option>
          ))}
        </Select>
      ),
      upgrade: heatingCurrentInputs.heater !== 'heatPump'
        ? (
          <Select
            key={peakHeatLossPerHour}
            name="heating.upgrade.heater"
            state={upgrade.heater}
          >
            <Option value={heatingCurrentInputs.heater}>Add upgrade</Option>
            <Option value="heatPump">
              {heatPumpLabel(peakHeatLossPerHour)}
            </Option>
          </Select>
          )
        : (
          <JoySelect
            placeholder="No upgrades available"
            disabled
            size="sm"
          >
          </JoySelect>
          ),
    },
    {
      icon: 'radiator',
      label: 'Radiators',
      education: (
        <EducationParagraph>
          Please select the number of radiators in your property.
          This is used to estimate the cost of any radiator upgrades.
        </EducationParagraph>
      ),
      current: <RadiatorCountSelect state={current.radiatorCount} />,
      upgrade: <RadiatorCountSelect state={current.radiatorCount} disabled />,
    },
    {
      icon: 'radiator',
      label: 'Radiator power',
      education: (
        <>
          <EducationParagraph>
            Depending on how well insulated your property is and how efficient you want your heating system to be, you may need to upgrade some of your radiators to higher power versions that can run at lower flow temperatures.
            Higher power radiators have a greater surface area than traditional radiators.
            Sometimes this means they are a little larger.
          </EducationParagraph>
          <EducationParagraph>
            Only an expert installer who visits your property will be able to advise you whether you should upgrade radiators.
            This decision partly depends on whether you prefer the lowest possible energy bills in the long run, or minimal disruption in the short term.
          </EducationParagraph>
        </>
      ),
      current: (
        <CurrentRadiatorSizeSelect
          states={{ current: current.flowTemp, upgrade: upgrade.flowTemp }}
        />
      ),
      upgrade: (
        <UpgradeRadiatorSizeSelect
          states={{ current: current.flowTemp, upgrade: upgrade.flowTemp }}
        />
      ),
    },
    {
      icon: 'thermostat',
      label: 'Flow temperature',
      education: (
        <>
          <EducationParagraph>
            In the UK, rooms are heated by circulating water through radiators or through underfloor tubes.
            The 'flow temperature' is the temperature of the water that flows through the radiators.
            The lower the flow temperature the more efficient the boiler is.
          </EducationParagraph>
          <EducationParagraph>
            Heat pump systems normally use 'weather compensation'.
            This means in warmer months the flow temperature will automatically reduce.
            Sometimes this means the radiators will not feel warm.
            However, they are still heating the room!
          </EducationParagraph>
        </>
      ),
      current: heatingCurrentInputs.heater === 'heatPump'
        ? (
          <Output value={heatingCurrentInputs.flowTemp} unit="°C" />
          )
        : null,
      upgrade: heatingUpgradeInputs.heater === 'heatPump'
        ? (
          <Output value={heatingUpgradeInputs.flowTemp} unit="°C" />
          )
        : null,
    },
    {
      icon: 'thermostat',
      label: 'Efficiency',
      education: (
        <>
          <EducationParagraph>
            The efficiency of a heat source is also called SCOP (seasonal coefficient of performance).
            The efficiency is the amount heat energy generated by your boiler or heat pump divided by the amount of energy from gas or electrical used by it over a year.
            The higher the efficiency, the lower your energy bills will be!
          </EducationParagraph>
          <EducationParagraph>
            The beauty of heat pumps is that they produce heat energy that is three or four times the electrical energy that's consumed.
            They do this by taking heat energy from the outside air (even on a cold day).
          </EducationParagraph>
        </>
      ),
      current: (
        <Output value={heatingCurrentOutputs.efficiency * 100} unit="%" />
      ),
      upgrade: (
        <Output value={heatingUpgradeOutputs.efficiency * 100} unit="%" />
      ),
    },
    {
      icon: 'funding',
      label: 'Grant',
      education: (
        <EducationParagraph>
          To make the switch to heat pumps more affordable, UK governments are currently offering a grant of £7,500 to help cover the cost of installing a heat pump.
          The grant is not means tested and is available to all homeowenrs with an EPC.
          There are minor differences between the scheme in Scotland and the one in England and Wales.
        </EducationParagraph>
      ),
      current: null,
      upgrade: heatingUpgradeInputs.heater === 'heatPump' && heatingCurrentInputs.heater !== 'heatPump'
        ? <Money value={GRANT} />
        : null,
    },
    {
      icon: 'money',
      label: 'Upgrade cost',
      education: (
        <>
          <EducationParagraph>
            This is the ballpark estimate to upgrade your heating system.
            It includes the cost of installing a new boiler, high power radiators (if selected) and a new hot water tank.
            The cost assumes you are eligible for the UK grant and has been reduced accordingly.
          </EducationParagraph>
          <EducationParagraph>
            To obtain a more accurate estimate, please contact an installer near you and arrange a visit to your property.
          </EducationParagraph>
        </>
      ),
      current: null,
      upgrade: <UpgradeCost current={heatingCurrentInputs} upgrade={heatingUpgradeInputs} peakHeatLossPerHour={peakHeatLossPerHour} />,
    },
    {
      current: 'Cost of heating',
      upgrade: 'Heating savings',
    },
    {
      icon: 'spanner',
      label: 'Energy tariff',
      education: (
        <>
          <EducationParagraph>
            The tariffs listed MAY NOT BE those actually charged by energy companies.
            We have chosen values purely for illustrative purposes.
            For latest tariffs please check energy company websites.
          </EducationParagraph>
          <EducationParagraph>
            Heat pumps are designed to work all day long.
            This means they can use lower cost energy generated early in the day when demand is low.
            To enable this, energy companies have created special heat pump tariffs that change depending on the time of day.
            For these tariffs, we use a simple average of the tarrifs across the day.
            In the future, we aim to work with energy companies to determine a more accurate effective tariff for heat pumps.
          </EducationParagraph>
          <EducationParagraph>
            The UK government sets a limit on how much can be charged by energy companies for each unit energy.
            However, it's stilll worth shopping around to find the best price for your energy.
          </EducationParagraph>
        </>
      ),
      current: (
        <Select name="heating.current.tariff" state={current.tariff}>
          {Object.keys(heatingCalculator.TARIFFS[heatingCurrentOutputs.tariffType]).map(key => (
            <Option key={key} value={key}>
              {getTariffOption(
                key,
                heatingCurrentOutputs.tariffType,
              )}
            </Option>
          ))}
        </Select>
      ),
      upgrade: (
        <Select name="heating.upgrade.tariff" state={upgrade.tariff}>
          {Object.keys(heatingCalculator.TARIFFS[heatingUpgradeOutputs.tariffType]).map(key => (
            <Option key={key} value={key}>
              {getTariffOption(
                key,
                heatingUpgradeOutputs.tariffType,
              )}
            </Option>
          ))}
        </Select>
      ),
    },
    {
      icon: 'gasburner',
      label: 'Energy consumed',
      education: (
        <EducationParagraph>
          'Energy consumed' is the total energy used by your boiler or heat pump to heat rooms and hot water during an average year.
          Energy is measured in kWh (kilowatt-hours).
        </EducationParagraph>
      ),
      current: <Output value={heatingEnergyCostCurrentOutputs.totalEnergyConsumptionPerYear} unit="kWh pa" roundingIncrement={100} />,
      upgrade: <Output value={heatingEnergyCostUpgradeOutputs.totalEnergyConsumptionPerYear} unit="kWh pa" roundingIncrement={100} />,
    },
    {
      icon: 'energybill',
      label: 'Energy cost',
      education: (
        <>
          <EducationParagraph>
            'Energy cost' is a rough estimate of the cost of gas or electricity used by your boiler or heat pump each year.
            It is calculated by multiplying the energy consumed by the selected tariff.
          </EducationParagraph>
          <EducationParagraph>
            This estimate is based on your current daily routine and any upgrades you have entered for insulation.
            It does not include the standing charge.
          </EducationParagraph>
        </>
      ),
      current: (
        <Money value={heatingEnergyCostCurrentOutputs.totalEnergyCostPerYear} />
      ),
      upgrade: (
        <Money value={heatingEnergyCostUpgradeOutputs.totalEnergyCostPerYear} />
      ),
    },
    {
      icon: 'energybill',
      label: 'Estimated savings',
      education: (
        <>
          <EducationParagraph>
            'Estimated savings' is how much you could save on your annual energy bill when you upgrade your heating system.
          </EducationParagraph>
          <EducationParagraph>
            This estimate assumes a year with an average winter.
            If you would like a more accurate estimate, please arrange for a professional installer to survey your property.
          </EducationParagraph>
        </>
      ),
      current: null,
      upgrade: (
        <Money value={heatingEnergyCostCurrentOutputs.totalEnergyCostPerYear - heatingEnergyCostUpgradeOutputs.totalEnergyCostPerYear} />
      ),
    },
  ]

  return (
    <>
      <EducationTeaser
        text="Heat pumps are more efficient than gas boilers."
        href="https://www.turbine.education/heating-systems"
      />
      <UpgradeableExpansion
        rows={rows}
        playNextButton={playNextButton}
      />
    </>
  )
}

function RadiatorCountSelect({ state, disabled }: {
  state: FieldState<number>
  disabled?: boolean
}): React.ReactNode {
  return (
    <Select name="heating.current.radiatorCount" state={state} disabled={disabled}>
      {Array.from({ length: MAX_RADIATORS }, (_, i) => String(i + 1)).map(value => (
        <Option key={value} value={value}>{value}</Option>
      ))}
    </Select>
  )
}

const RADIATOR_SIZE_OPTIONS = [
  ['Standard radiators', 60],
  ['Some high power', 55],
  ['Half high power', 50],
  ['Mostly high power', 45],
  ['All high power', 40],
] as const

function CurrentRadiatorSizeSelect({ states }: {
  states: { current: FieldState<number>, upgrade: FieldState<number> }
}): React.ReactNode {
  return <RadiatorSizeSelect states={states} options={RADIATOR_SIZE_OPTIONS} />
}

function UpgradeRadiatorSizeSelect({ states: { current, upgrade } }: {
  states: { current: FieldState<number>, upgrade: FieldState<number> }
}): React.ReactNode {
  const options = [
    ['Add upgrade', current.value.data ?? 0] as const,
    ...RADIATOR_SIZE_OPTIONS.filter(([, max]) => current.value.data != null && current.value.data > max),
  ]

  return <RadiatorSizeSelect states={{ upgrade }} options={options} />
}

function RadiatorSizeSelect({ states, options }: {
  states: { current?: FieldState<number>, upgrade: FieldState<number> }
  options: readonly (readonly [string, number])[]
}): React.ReactNode {
  const [state, name] = 'current' in states && states.current != null
    ? [states.current, 'heating.current.flowTemp']
    : [states.upgrade, 'heating.upgrade.flowTemp']

  function onChange(_event: unknown, value: number | null): void {
    state.setInput(value != null ? String(value) : '')

    if ('current' in states && states.current != null) {
      if (value != null) {
        states.upgrade.setValue(value)
      } else {
        states.upgrade.setInput('')
      }
    }
  }

  return (
    <JoySelect
      name={name}
      value={state.value.data}
      onChange={onChange}
      size="sm"
      disabled={options.length === 1}
      placeholder="No upgrades available"
    >
      {options.length > 1 && options.map(([label, value]) => (
        <Option key={value} value={value}>{label}</Option>
      ))}
    </JoySelect>
  )
}

function UpgradeCost({
  current,
  upgrade,
  peakHeatLossPerHour,
}: {
  current: heatingCalculator.Inputs
  upgrade: heatingCalculator.Inputs
  peakHeatLossPerHour: fabricEnergy.Outputs['peakHeatLossPerHour']
}): React.ReactNode {
  const desiredUpgradedRadiators = upgrade.heater === 'heatPump'
    ? Math.ceil(((60 - upgrade.flowTemp) / 20) * upgrade.radiatorCount)
    : 0
  const currentUpgradedRadiators = current.heater === 'heatPump'
    ? Math.ceil(((60 - current.flowTemp) / 20) * current.radiatorCount)
    : 0
  const numberOfRadiatorsToUpgrade = desiredUpgradedRadiators - currentUpgradedRadiators

  const heatPumpCost = upgrade.heater === 'heatPump' && current.heater !== 'heatPump'
    ? getHeatPumpOption(peakHeatLossPerHour)[1] + HEATER_UPGRADE_COST - GRANT
    : 0
  const radiatorCost = numberOfRadiatorsToUpgrade * RADIATOR_UPGRADE_COST

  return <Money value={Math.max(heatPumpCost + radiatorCost, 0)} />
}

function getUpgrades(current: heatingCalculator.Inputs, upgrade: heatingCalculator.Inputs): string[] {
  return current.heater !== 'heatPump' && upgrade.heater === 'heatPump'
    ? [
        'Switch to a heat pump',
        `${formatNumber(GRANT, 'currency')} grant`,
      ]
    : []
}

function useHeatingFields(model: Model, instance: 'current' | 'upgrade'): {
  [K in keyof heatingCalculator.Inputs]: FieldState<heatingCalculator.Inputs[K]>
} {
  const { heater, radiatorCount, flowTemp, tariff } = useModelInputs(`heating.${instance}`)

  const fields = {
    heater: useFieldState(HEATER_PARSER, String, heater, (heater) => {
      model.setInputs(`heating.${instance}`, { heater })
      if (instance === 'current') {
        model.setInputs('heating.upgrade', { heater })
      }
    }),
    radiatorCount: useFieldState(INT_PARSER, String, radiatorCount, (radiatorCount) => {
      model.setInputs(`heating.${instance}`, { radiatorCount })
      if (instance === 'current') {
        model.setInputs('heating.upgrade', { radiatorCount })
      }
    }),
    flowTemp: useFieldState(INT_PARSER, String, flowTemp, (flowTemp) => {
      model.setInputs(`heating.${instance}`, { flowTemp })
      if (instance === 'current') {
        model.setInputs('heating.upgrade', { flowTemp })
      }
    }),
    tariff: useFieldState(z.string(), String, tariff, (tariff) => {
      model.setInputs(`heating.${instance}`, { tariff })
    }),
  }

  const { tariffType } = useModelOutputs(`heating.${instance}`)
  React.useEffect(() => {
    fields.heater.setValue(heater)
    fields.radiatorCount.setValue(radiatorCount)

    const validTariffs = heatingCalculator.TARIFFS[tariffType]
    if (!Object.hasOwn(validTariffs, tariff)) {
      // NOTE: this mirrors the fallback logic in the calculator, which is a bit subtle
      fields.tariff.setValue(Object.keys(validTariffs)[0]!)
    }
  }, [
    fields.heater,
    fields.radiatorCount,
    fields.tariff,
    heater,
    radiatorCount,
    tariff,
    tariffType,
  ])

  return fields
}

type HeaterLabel = string | ((peakHeatLossPerHour: fabricEnergy.Outputs['peakHeatLossPerHour']) => string)

type HeaterOptions<Hs extends readonly [...any[]]> = {
  [H in keyof Hs]: [Hs[H], HeaterLabel]
} & { length: Hs['length'] }

const HEATER_OPTIONS: HeaterOptions<typeof heatingCalculator.HEATERS> = [
  ['gasBoiler', 'Gas boiler'],
  ['combiBoiler', 'Gas combi'],
  ['electric', 'Electric'],
  ['oil', 'Oil'],
  ['lpg', 'LPG'],
  ['heatPump', heatPumpLabel],
]

function heatPumpLabel(peakHeatLossPerHour: fabricEnergy.Outputs['peakHeatLossPerHour']): string {
  return `${getHeatPumpOption(peakHeatLossPerHour)[0]} kW Heat pump (air source)`
}

export const HEATER_PARSER = z.enum([
  'gasBoiler',
  'combiBoiler',
  'electric',
  'oil',
  'lpg',
  'heatPump',
]) satisfies z.ZodType<heatingCalculator.Heater>

function getTariffOption(
  tariff: string,
  tariffType: heatingCalculator.TariffType,
): string {
  const tariffs = heatingCalculator.TARIFFS[tariffType]

  // NOTE: this mirrors the fallback logic in the calculator, which is a bit subtle
  const value = (tariffs[tariff] ?? Object.values(tariffs)[0]!)[1]

  return `${value.toFixed(1)} p/kWh ${tariff}`
}
