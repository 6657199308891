'use client'

import React from 'react'
import { z } from 'zod'
import { Box, Select as JoySelect, Slider as JoySlider, Stack } from '@mui/joy'
import NumberInputComponent, { NumberInputOnChangeEvent } from '@/lib/components/NumberInput'
import { Unit } from './Content'

export const FLOAT_PARSER = z.string().transform((s, ctx) => {
  const value = parseFloat(s)

  if (!isNaN(value)) {
    return value
  }

  ctx.addIssue({
    code: z.ZodIssueCode.custom,
    message: 'invalid value',
  })
  return z.NEVER
})

export const INT_PARSER = z.string().transform((s, ctx) => {
  const value = parseInt(s)
  if (isNaN(value)) {
    ctx.addIssue({
      code: z.ZodIssueCode.custom,
      message: 'invalid number',
    })
    return z.NEVER
  }

  return value
})

export type FieldState<T> = {
  input: string
  setInput: (input: string) => void

  value: z.SafeParseReturnType<string, T>
  setValue: (value: T) => void
}

export type FieldStateListener<T> = (value: T) => void

export function useFieldState<T>(
  parser: z.ZodType<T, z.ZodTypeDef, string>,
  render: (value: T) => string,
  data?: T | undefined,
  listener?: FieldStateListener<T>,
): FieldState<T> {
  const [input, setInput_] = React.useState(data !== undefined ? render(data) : '')
  const [value, setValue_] = React.useState(parser.safeParse(input))

  function setInput(newInput: string): void {
    if (newInput !== input) {
      const newValue = parser.safeParse(newInput)

      setInput_(newInput)
      setValue_(newValue)

      if (newValue.success) {
        listener?.(newValue.data)
      }
    }
  }

  function setValue(newData: T): void {
    if (!value.success || newData !== value.data) {
      const newInput = render(newData)
      const newValue = { success: true as const, data: newData }

      setInput_(newInput)
      setValue_(newValue)
    }
  }

  return {
    input,
    setInput,

    value,
    setValue,
  }
}

export function NumberInput({ name, state, min, max, separator, unit, placeholder, onFocus }: {
  name: string
  state: FieldState<number>
  min?: number
  max?: number
  separator?: boolean
  unit: React.ReactNode
  placeholder?: string
  onFocus?: () => void
}): React.ReactNode {
  function onChange(event: NumberInputOnChangeEvent): void {
    if (event.target.value !== null) {
      state.setInput(String(event.target.value))
    } else {
      state.setInput('')
    }
  }

  return (
    <Stack direction="row" alignItems="center" flexGrow={1}>
      <Box sx={{ flexBasis: 0, flexGrow: 4, minWidth: 0 }}>
        <NumberInputComponent
          name={name}
          value={state.input}
          onChange={onChange}
          size="sm"
          min={min}
          max={max}
          separator={separator}
          placeholder={placeholder}
          onFocus={onFocus}
        />
      </Box>
      <Unit>
        {unit}
      </Unit>
    </Stack>
  )
}

export function Select<T>({ name, state, disabled, placeholder, children, onFocus }: {
  name: string
  state: FieldState<T>
  disabled?: boolean
  placeholder?: string
  children: React.ReactNode
  onFocus?: () => void
}): React.ReactNode {
  function onChange(_event: unknown, value: string | null): void {
    state.setInput(value ?? '')
  }

  return (
    <JoySelect
      name={name}
      value={state.input}
      onChange={onChange}
      disabled={disabled}
      sx={{ flexGrow: 1 }}
      size="sm"
      placeholder={placeholder}
      onListboxOpenChange={onFocus}
      slotProps={{
        button: {
          sx: {
            display: 'block',
            textAlign: 'left',
            textOverflow: 'ellipsis',
          },
        },
      }}
    >
      {children}
    </JoySelect>
  )
}

export function Slider({ name, state, min, max, unit, onFocus }: {
  name: string
  state: FieldState<number>
  min: number
  max: number
  unit?: string
  onFocus?: () => void
}): React.ReactNode {
  function onChange(_event: unknown, value: number | number[]): void {
    state.setInput(String(value))
    onFocus?.()
  }

  return (
    <Stack direction="row" alignItems="center" flexGrow={1}>
      <Box sx={{ flexGrow: 3 }}>
        <JoySlider
          name={name}
          value={state.value.data}
          onChange={onChange}
          sx={{ '--Slider-size': 32, 'display': 'flex', 'alignItems': 'center' }}
          min={min}
          max={max}
        />
      </Box>
      <Unit>
        {state.value.data}
        {unit && ` ${unit}`}
      </Unit>
    </Stack>
  )
}
