import { z } from 'zod'
import { fromError } from 'zod-validation-error'

export function getEnv<T>(schema: z.ZodType<T, z.ZodTypeDef, unknown>): T {
  try {
    return schema.parse(buildEnv(), { path: ['process', 'env'] })
  } catch (cause) {
    const error = fromError(cause, { prefix: 'Invalid environment' })

    if (typeof window === 'undefined') {
      console.error()
      console.error(error.message)
      process.exit(1)
    }

    throw error
  }
}

export function getEnvKey(key: string): string {
  const env = getEnv(z.object({ [key]: z.string() }))
  return env[key]!
}

// This works around the fact that nextjs does naive name substitution for public env vars.
// Unfortunately, it needs to be updated whenever we add a new public env var
// (the benefit is clear errors for missing environment variables and consistent access patterns).
function buildEnv(): unknown {
  return {
    ...process.env,
    ...typeof window !== undefined
      ? {
          NODE_ENV: process.env.NODE_ENV ?? 'development',
          NEXT_PUBLIC_GETADDRESS_API_KEY: process.env.NEXT_PUBLIC_GETADDRESS_API_KEY,
          NEXT_PUBLIC_GOOGLE_TAG_ID: process.env.NEXT_PUBLIC_GOOGLE_TAG_ID,
          NEXT_PUBLIC_GOOGLE_MAPS_API_KEY: process.env.NEXT_PUBLIC_GOOGLE_MAPS_API_KEY,
          NEXT_PUBLIC_PUBLIC_BASE_URL: process.env.NEXT_PUBLIC_PUBLIC_BASE_URL,
        }
      : {},
  }
}
