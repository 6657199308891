// The property component of the model is concerned with the general structure of the building.

import { z } from 'zod'

import type { Epc } from '@/lib/epc'

export type Dependencies = {
  epc: Epc | null
}

export const PropertyTypeSchema = z.enum(['detached', 'semiDetached', 'terrace', 'endTerrace', 'bungalow', 'flat'])

export type PropertyType = z.infer<typeof PropertyTypeSchema>

export type Inputs = {
  propertyType: PropertyType
  buildYear: number
  floorArea: number
  ceilingHeight: number
  floorCount: number
}

export type Outputs = {
  hasEpc: boolean
  floorArea: number
  buildYear: number
  aspectRatio: number
  width: number
  depth: number
  exteriorWallCount: ExteriorWallCount
  exteriorWallLength: number
  footprint: number
  groundFloorArea: number
  roofArea: number
  exteriorWallArea: number
  windowArea: number
  windowAreaProportion: number
  exteriorWallMinusWindowArea: number
  airVolume: number
}

export type ExteriorWallCount = 2 | 3 | 4

export function getOutputs(
  { epc }: Dependencies,
  inputs: Inputs,
): Outputs {
  const hasEpc = epc != null

  const floorArea = inputs.floorArea

  const buildYear = inputs.buildYear

  const aspectRatio = getAspectRatio(inputs)

  const footprint = getFootprint(inputs)

  const width = Math.sqrt(footprint * aspectRatio)

  const depth = footprint / width

  const exteriorWallCount = getExternalWallCount(inputs)

  const exteriorWallLength = getExteriorWallLength({ width, depth, exteriorWallCount })

  const groundFloorArea = footprint

  const roofArea = getRoofArea(inputs, { footprint })

  const exteriorWallArea = getExteriorWallArea(inputs, { exteriorWallLength })

  const windowAreaProportion = getWindowAreaProportion(inputs)

  const windowArea = windowAreaProportion * floorArea

  const exteriorWallMinusWindowArea = exteriorWallArea - windowArea

  const airVolume = getAirVolume(inputs)

  return {
    hasEpc,
    floorArea,
    buildYear,
    aspectRatio,
    footprint,
    width,
    depth,
    exteriorWallCount,
    exteriorWallLength,
    groundFloorArea,
    roofArea,
    exteriorWallArea,
    windowAreaProportion,
    windowArea,
    exteriorWallMinusWindowArea,
    airVolume,
  }
}

function getAspectRatio({ propertyType }: Inputs): Outputs['aspectRatio'] {
  switch (propertyType) {
    case 'detached':
    case 'semiDetached':
      return 1
    case 'terrace':
    case 'endTerrace':
    case 'bungalow':
    case 'flat':
      return 0.5
  }
}

function getWindowAreaProportion({ propertyType }: Inputs): Outputs['windowAreaProportion'] {
  switch (propertyType) {
    case 'detached':
    case 'bungalow':
      return 0.144
    case 'semiDetached':
    case 'terrace':
    case 'endTerrace':
    case 'flat':
      return 0.115
  }
}

function getFootprint({ floorArea, floorCount }: Inputs): Outputs['footprint'] {
  return floorArea / floorCount
}

function getExternalWallCount({ propertyType }: Inputs): Outputs['exteriorWallCount'] {
  switch (propertyType) {
    case 'detached':
    case 'bungalow':
      return 4
    case 'semiDetached':
    case 'endTerrace':
      return 3
    case 'terrace':
    case 'flat':
      return 2
  }
}

function getExteriorWallLength(
  { width, depth, exteriorWallCount }: Pick<Outputs, 'width' | 'depth' | 'exteriorWallCount'>,
): Outputs['exteriorWallLength'] {
  switch (exteriorWallCount) {
    case 2:
      return 2 * width
    case 3:
      return 2 * width + depth
    case 4:
      return 2 * width + 2 * depth
  }
}

function getRoofArea(
  { propertyType }: Inputs,
  { footprint }: Pick<Outputs, 'footprint'>,
): Outputs['roofArea'] {
  switch (propertyType) {
    case 'flat':
      return 0
    default:
      return footprint
  }
}

function getExteriorWallArea(
  { floorCount, ceilingHeight }: Inputs,
  { exteriorWallLength }: Pick<Outputs, 'exteriorWallLength'>,
): Outputs['exteriorWallArea'] {
  return floorCount * ceilingHeight * exteriorWallLength
}

function getAirVolume({ floorArea, ceilingHeight }: Inputs): Outputs['airVolume'] {
  return floorArea * ceilingHeight
}
