import z from 'zod'

const BUILT_FORM = [
  'Detached',
  'Semi-Detached',
  'Mid-Terrace',
  'Enclosed End-Terrace',
  'End-Terrace',
  'Enclosed Mid-Terrace',
] as const

const PROPERTY_TYPE = ['Bungalow', 'House', 'Flat', 'Park home', 'Maisonette'] as const

const EFFICIENCY_VALUES = ['Very Good', 'Good', 'Average', 'Poor', 'Very Poor'] as const

const MAIN_FUEL = ['gas', 'oil', 'lpg'] as const

const AGE_BAND = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L'] as const

export type AgeBand = typeof AGE_BAND[number]

const EfficiencyEnumSchema = z.enum(EFFICIENCY_VALUES).optional().catch(undefined)

export type EpcEfficiencyEnum = z.infer<typeof EfficiencyEnumSchema>

const NumberSchema = z
  .coerce
  .string()
  .transform((val) => {
    if (val !== '' && val.match(/^(?:[1-9]\d*|0)?(?:\.\d+)?$/)) {
      return Number(val)
    }
    return undefined
  })
  .optional()
  .catch(undefined)

export const EpcSchema = z.object({
  'inspection-date': z.coerce.date().optional().catch(undefined),
  'total-floor-area': NumberSchema,
  'construction-age-band': z.enum(AGE_BAND).optional(),
  'walls-energy-eff': EfficiencyEnumSchema,
  'roof-energy-eff': EfficiencyEnumSchema,
  'windows-energy-eff': EfficiencyEnumSchema,
  'floor-energy-eff': EfficiencyEnumSchema,
  'floor-height': NumberSchema,
  'built-form': z.enum(BUILT_FORM).optional().catch(undefined),
  'property-type': z.enum(PROPERTY_TYPE).optional().catch(undefined),
  'main-fuel': z.string().transform((val) => {
    for (const fuel of MAIN_FUEL) {
      const re = new RegExp(`\\b${fuel}\\b`, 'i')
      if (val.match(re)) {
        return fuel
      }
    }
    return undefined
  }).optional().catch(undefined),
})

export type Epc = z.infer<typeof EpcSchema>

export function compareEpc(
  { 'inspection-date': aInspectionDate }: Epc,
  { 'inspection-date': bInspectionDate }: Epc,
): number {
  const aTime = aInspectionDate?.getTime()
  const bTime = bInspectionDate?.getTime()

  if (aTime == null && bTime == null) {
    return 0
  } else if (aTime == null) {
    return 1
  } else if (bTime == null) {
    return -1
  }

  if (aTime < bTime) {
    return 1
  } else if (aTime > bTime) {
    return -1
  } else {
    return 0
  }
}
