import { z } from 'zod'
import { getEnv } from './env'

const { NEXT_PUBLIC_PUBLIC_BASE_URL } = getEnv(z.object({
  NEXT_PUBLIC_PUBLIC_BASE_URL: z.string().default(''),
}))

export function publicUrl(path: string): string {
  return `${NEXT_PUBLIC_PUBLIC_BASE_URL}${path}`
}
