import { Address } from './components/AddressAutocomplete'

export type NumberStyle = 'currency' | 'percent' | 'distance' | Intl.NumberFormatOptions

export function formatNumber(n: number, style?: NumberStyle): string {
  let options: Intl.NumberFormatOptions
  switch (style) {
    case 'currency':
      options = {
        style: 'currency',
        currency: 'GBP',
        maximumFractionDigits: 0,
        // TODO: TS rejects, but this is valid and will hide signs for -ve values that round to 0
        signDisplay: 'negative' as any,
      }
      break
    case 'percent':
      options = {
        style: 'percent',
      }
      break
    case 'distance':
      options = {
        style: 'unit',
        unit: 'mile',
      }
      break
    case undefined:
      options = {}
      break
    default:
      options = style
  }

  return n.toLocaleString(undefined, options)
}

export function formatAddress({ address, postcode }: Pick<Address, 'address' | 'postcode'>): string {
  return [address, postcode].join(', ')
}
