import { Input, InputTypeMap } from '@mui/joy'
import React from 'react'
import { NumberFormatValues, NumericFormat, NumericFormatProps } from 'react-number-format'

export type NumberInputOnChangeEvent = {
  target: {
    name: string
    value: number | null
  }
}

export type Props = Pick<InputTypeMap['props'], 'sx' | 'required' | 'onFocus'> & {
  onChange: (event: NumberInputOnChangeEvent) => void
  name: string
  value?: string | number | null
  min?: number
  max?: number
  separator?: boolean
  size?: React.ComponentProps<typeof Input>['size']
  placeholder?: string
}

const NumberInput = React.forwardRef<NumericFormatProps, Props>(
  function NumericFormatAdapter(props, ref) {
    const { onChange, min, max, value, separator, ...other } = props

    const isAllowed = (values: NumberFormatValues) => {
      const value = Number(values.value)
      if (props.min !== undefined && value < props.min) {
        return false
      }
      if (props.max !== undefined && value > props.max) {
        return false
      }
      return true
    }

    return (
      <NumericFormat
        {...other}
        getInputRef={ref}
        isAllowed={isAllowed}
        customInput={Input}
        value={value == null ? '' : typeof value === 'number' ? String(value) : value}
        onValueChange={(values) => {
          onChange({
            target: {
              name: props.name,
              value: values.value === '' ? null : Number(values.value),
            },
          })
        }}
        thousandSeparator={separator ?? true}
        allowNegative={false}
        decimalScale={2}
        autoComplete="off"
      />
    )
  },
)

export default NumberInput
