import * as propertyCalculator from './property'
import * as locationCalculator from './location'

const STANDARD_ELECTRICITY_TARIFF = 24.5 // p/kWh
const TYPICAL_MONTHLY_USAGE = 225 // kWh

const SOLAR_PANEL_PEAK_POWER = 450 // W
const SOLAR_PANEL_SIZE = 2.1 // m2
const SOLAR_PANEL_INVERTER_EFFICIENCY = 0.9

const BATTERY_COST = 600 // £/kWh

export type Dependencies = {
  property: propertyCalculator.Outputs
  location: locationCalculator.Outputs
}

export type Inputs = {
  roofType: RoofType
  roofSlope: RoofSlope
  roofAzimuth: RoofAzimuth
  solarPanelCount: number
}

export type Outputs = {
  roofCoverage: number
  solarPeakPower: number
  solarEnergyUsed: number
  batterySize: number
  upgradeCost: number
  estimatedSavings: number
}

export type RoofType = 'flat' | 'pitched'

export type RoofSlope = 25 | 30 | 35 | 40 | 45

export type RoofAzimuth = -90 | -45 | 0 | 45 | 90

export function getOutputs({ property, location }: Dependencies, inputs: Inputs): Outputs {
  const roofCoverage = getRoofCoverage(property, inputs.solarPanelCount)

  const solarPeakPower = SOLAR_PANEL_PEAK_POWER * inputs.solarPanelCount / 1000

  const solarFactor = SOLAR_FACTOR[location.ddRegion]

  const solarEnergyUsed = SOLAR_PERFORMANCE[inputs.roofSlope][inputs.roofAzimuth]
    .reduce((acc, performance) => acc + energyUsed(solarPeakPower, solarFactor, performance), 0)

  const batterySize = Math.ceil(TYPICAL_MONTHLY_USAGE / 30)

  const upgradeCost = 1500 + (SOLAR_PANEL_PEAK_POWER * inputs.solarPanelCount) + (BATTERY_COST * batterySize)

  const estimatedSavings = STANDARD_ELECTRICITY_TARIFF * solarEnergyUsed / 100

  return {
    roofCoverage,
    solarPeakPower,
    solarEnergyUsed,
    batterySize,
    upgradeCost,
    estimatedSavings,
  }
}

export function getRoofCoverage(
  { footprint }: Dependencies['property'],
  solarPanelCount: Inputs['solarPanelCount'],
): number {
  return SOLAR_PANEL_SIZE * solarPanelCount / footprint
}

function energyUsed(solarPeakPower: number, solarFactor: number, monthPerformance: number): number {
  const energyGenerated = solarFactor * SOLAR_PANEL_INVERTER_EFFICIENCY * solarPeakPower * monthPerformance
  return Math.min(TYPICAL_MONTHLY_USAGE, energyGenerated)
}

type SolarPerformanceMonths = [number, number, number, number, number, number, number, number, number, number, number, number]

const SOLAR_PERFORMANCE: { [slope in RoofSlope]: { [azimuth in RoofAzimuth]: SolarPerformanceMonths } } = {
  [25]: {
    [-90]: [21.43, 37.07, 73.58, 112.03, 134.21, 135.25, 135.6, 111.62, 85.31, 50.74, 26.25, 16.77],
    [-45]: [33.45, 50.2, 88.45, 125.36, 142.32, 141.14, 142.34, 121.12, 98.83, 64.8, 39.52, 27.85],
    [0]: [38.83, 56.36, 95.09, 130, 144.76, 142.46, 143.86, 124.98, 104.1, 71.21, 45.63, 33.33],
    [45]: [33.09, 50.47, 88.97, 125.03, 142.47, 141.67, 142.63, 122.7, 98.42, 64.91, 39.58, 28.26],
    [90]: [21.01, 37.37, 74.23, 111.54, 134.25, 136.04, 136.3, 113.82, 84.49, 50.48, 26.33, 16.94],
  },
  [30]: {
    [-90]: [21.55, 37.03, 72.85, 110.97, 132.01, 133.2, 133.71, 110.02, 84.66, 50.47, 26.23, 16.82],
    [-45]: [35.3, 51.89, 89.71, 125.93, 141.31, 139.32, 140.81, 120.79, 99.82, 66.49, 41.44, 29.63],
    [0]: [41.58, 59.11, 97.44, 120.89, 143.61, 140.63, 142.32, 124.84, 105.9, 73.94, 48.59, 36.05],
    [45]: [34.89, 52.24, 90.33, 125.52, 141.29, 139.93, 141.18, 122.78, 99.37, 66.56, 41.52, 30.12],
    [90]: [21.04, 37.31, 73.68, 110.3, 132.18, 134.09, 134.26, 112.58, 83.62, 50.26, 26.31, 17.12],
  },
  [35]: {
    [-90]: [21.57, 36.76, 72.22, 109.18, 129.96, 130.27, 131.02, 108.39, 83.82, 50.05, 26.24, 16.8],
    [-45]: [36.89, 53.26, 90.49, 125.68, 140.02, 137.46, 129.24, 120.01, 100.34, 67.81, 43.07, 31.18],
    [0]: [44.02, 61.46, 99.23, 131.11, 141.88, 138.15, 140.13, 124.12, 107.12, 76.22, 51.2, 38.47],
    [45]: [36.43, 53.65, 91.28, 125.25, 140.04, 138.14, 139.69, 122.03, 99.8, 67.83, 43.18, 31.75],
    [90]: [20.94, 37.19, 73, 108.58, 129.94, 131.27, 132.02, 11.06, 82.61, 49.86, 26.24, 17.16],
  },
  [40]: {
    [-90]: [21.43, 36.47, 71.17, 107.46, 127.1, 127.66, 128.5, 106.22, 82.69, 49.47, 26.01, 16.72],
    [-45]: [38.22, 54.32, 90.81, 124.83, 137.79, 134.73, 136.77, 118.53, 100.46, 68.69, 44.4, 32.51],
    [0]: [46.14, 63.4, 100.46, 130.65, 139.49, 135.05, 137.3, 122.8, 107.76, 78.03, 53.43, 40.6],
    [45]: [37.71, 54.72, 91.77, 124.36, 137.82, 135.47, 137.21, 120.71, 99.73, 68.72, 44.54, 33.15],
    [90]: [20.76, 36.9, 72, 106.77, 127.33, 128.73, 129.36, 109.12, 81.54, 49.18, 26.11, 17.13],
  },
  [45]: {
    [-90]: [21.2, 36.04, 69.82, 105.27, 124.13, 124.18, 125.15, 103.92, 81.19, 48.65, 25.73, 16.61],
    [-45]: [39.28, 55.05, 90.71, 123.46, 135, 131.4, 133.69, 115.54, 100.12, 69.17, 45.44, 33.6],
    [0]: [47.92, 64.95, 101.13, 129.53, 136.46, 131.35, 133.87, 120.89, 107.82, 79.37, 55.3, 42.43],
    [45]: [38.74, 55.46, 91.8, 122.97, 134.99, 132.2, 134.17, 118.92, 99.23, 69.24, 45.61, 34.31],
    [90]: [20.53, 36.4, 70.79, 104.61, 124.24, 125.31, 126.3, 107.01, 79.93, 48.44, 25.78, 17.02],
  },
}

const SOLAR_FACTOR: { [_ in locationCalculator.DegreeDayRegion]: number } = {
  'Thames Valley': 1.00,
  'South East': 0.99,
  'South': 1.11,
  'South West': 1.06,
  'Severn Valley': 1.01,
  'West Pennines': 0.96,
  'Midland': 0.91,
  'North West': 0.90,
  'Borders': 0.96,
  'North East': 0.95,
  'East Pennines': 1.00,
  'East Anglia': 0.97,
  'West Scotland': 0.82,
  'East Scotland': 0.89,
  'North East Scotland': 0.93,
  'Wales': 0.98,
  'Northern Ireland': 0.88,
  'North West Scotland': 0.82,
}
