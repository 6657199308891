import * as fabricCalculator from '@/lib/calculators/fabric'
import * as fabricEnergyCalculator from '@/lib/calculators/fabricEnergy'
import * as fabricEnergyCostCalculator from '@/lib/calculators/fabricEnergyCost'
import * as heatingCalculator from '@/lib/calculators/heating'
import * as heatingEnergyCostCalculator from '@/lib/calculators/heatingEnergyCost'
import * as locationCalculator from '@/lib/calculators/location'
import * as propertyCalculator from '@/lib/calculators/property'
import * as usageCalculator from '@/lib/calculators/usage'
import * as usageCostCalculator from '@/lib/calculators/usageCost'
import * as solarCalculator from '@/lib/calculators/solar'
import { Graph } from './graph'

const CALCULATOR_KEYS = [
  'location',
  'property',
  'fabric',
  'fabricEnergy',
  'fabricEnergyCost',
  'heating',
  'heatingEnergyCost',
  'usage',
  'usageCost',
  'solar',
] as const

const NON_UPGRADABLE_KEYS = ['location', 'property'] as const satisfies CalculatorKey[]

const CALCULATORS = {
  location: locationCalculator,
  property: propertyCalculator,
  fabric: fabricCalculator,
  fabricEnergy: fabricEnergyCalculator,
  fabricEnergyCost: fabricEnergyCostCalculator,
  heating: heatingCalculator,
  heatingEnergyCost: heatingEnergyCostCalculator,
  usage: usageCalculator,
  usageCost: usageCostCalculator,
  solar: solarCalculator,
} as const satisfies {
  [C in CalculatorKey]: CalculatorImpl<any, any, any>
}

// This maps each `ModelKey` to a list of other `ModelKey`s that depend on it.
// TODO: we could probably generate a type that constrains the valid configuration's here, since we
// know which calculators depend on which other calculators.
const GRAPH = new Graph<ModelKey>([
  ['location', ['fabricEnergy.current', 'fabricEnergy.upgrade', 'usage.current', 'usage.upgrade', 'usageCost.current', 'usageCost.upgrade', 'solar.current', 'solar.upgrade']],
  ['property', ['fabric.current', 'fabric.upgrade', 'heating.current', 'heating.upgrade', 'solar.current', 'solar.upgrade']],
  ['fabric.current', ['fabricEnergy.current']],
  ['fabric.upgrade', ['fabricEnergy.upgrade', 'usage.current', 'usage.upgrade']],
  ['fabricEnergy.current', ['fabricEnergyCost.current']],
  ['fabricEnergy.upgrade', ['fabricEnergyCost.upgrade', 'heating.current', 'heating.upgrade']],
  ['heating.current', ['fabricEnergyCost.current', 'fabricEnergyCost.upgrade', 'heatingEnergyCost.current']],
  ['heating.upgrade', ['heatingEnergyCost.upgrade', 'usageCost.current', 'usageCost.upgrade']],
  ['usage.current', ['fabricEnergy.current', 'fabricEnergy.upgrade', 'heatingEnergyCost.current', 'heatingEnergyCost.upgrade', 'usageCost.current']],
  ['usage.upgrade', ['usageCost.upgrade']],
])

// NOTE: we precompute an inverse of the graph as well since it's useful for building dependencies
const GRAPH_INVERSE = GRAPH.invert()

export type CalculatorKey = typeof CALCULATOR_KEYS[number]

export type Calculator<C extends CalculatorKey> = CalculatorImpl<
  CalculatorTypes[C]['dependencies'],
  CalculatorTypes[C]['inputs'],
  CalculatorTypes[C]['outputs']
>

export type CalculatorTypes = {
  [C in CalculatorKey]: {
    dependencies: Parameters<typeof CALCULATORS[C]['getOutputs']>[0]
    inputs: Parameters<typeof CALCULATORS[C]['getOutputs']>[1]
    outputs: ReturnType<typeof CALCULATORS[C]['getOutputs']>
  }
}

export type ModelKey = {
  [C in CalculatorKey]: `${C}${C extends UpgradableKey ? '.current' | '.upgrade' : ''}`
}[CalculatorKey]

export type Dependencies<K extends ModelKey> = StateEntry<K>['dependencies']

export type Inputs<K extends ModelKey> = StateEntry<K>['inputs']

export type Outputs<K extends ModelKey> = StateEntry<K>['outputs']

export type InputsInit = {
  [K in ModelKey]: Inputs<K>
}

// Non-calculation dependencies
export type DependenciesInit = OptionalEmptyValues<{
  [K in ModelKey]: Omit<Dependencies<K>, CalculatorKey>
}>

export type SubscriberCallback = () => void

export type SubscriberCancel = () => void

type CalculatorImpl<D, I, O> = {
  getOutputs(dependencies: D, inputs: I): O
}

type State = {
  [K in ModelKey]: StateEntry<K>
}

type StateEntry<K extends ModelKey> = CalculatorTypes[StateEntryCalculatorKey<K>]

type StateEntryCalculatorKey<K extends ModelKey> = K extends `${infer C}${'' | '.current' | '.upgrade'}`
  ? C extends CalculatorKey
    ? C
    : never
  : never

type Subscriber = [ModelKey, SubscriberCallback]

// TODO: this probably doesn't need to be exported once model-inputs are updated
export type UpgradableKey = Exclude<CalculatorKey, NonUpgradableKey>

type NonUpgradableKey = typeof NON_UPGRADABLE_KEYS[number]

export default class Model {
  #state: State

  #inputSubscribers: Subscriber[]
  #outputSubscribers: Subscriber[]

  constructor(dependencies: DependenciesInit, inputs: InputsInit) {
    this.#state = initState(dependencies, inputs)
    this.#inputSubscribers = []
    this.#outputSubscribers = []
  }

  reset(
    initDependencies: OptionalEmptyValues<{
      // TODO: it's probably possible to type this so that keys with empty values can be omitted
      [K in ModelKey]: Omit<StateEntry<K>['dependencies'], CalculatorKey>
    }>,
    inputs: {
      [K in ModelKey]: StateEntry<K>['inputs']
    },
  ) {
    this.#state = initState(initDependencies, inputs)

    // TODO: this could probably be more targeted
    for (const calculatorKey of CALCULATOR_KEYS) {
      if (NON_UPGRADABLE_KEYS.includes(calculatorKey as any)) {
        const nonUpgradableKey = calculatorKey as NonUpgradableKey
        this.#callSubscribers(this.#inputSubscribers, nonUpgradableKey)
        this.#callSubscribers(this.#outputSubscribers, nonUpgradableKey)
      } else {
        const upgradableKey = calculatorKey as UpgradableKey
        this.#callSubscribers(this.#inputSubscribers, `${upgradableKey}.current`)
        this.#callSubscribers(this.#inputSubscribers, `${upgradableKey}.upgrade`)
        this.#callSubscribers(this.#outputSubscribers, `${upgradableKey}.current`)
        this.#callSubscribers(this.#outputSubscribers, `${upgradableKey}.upgrade`)
      }
    }
  }

  getInputs<K extends ModelKey>(key: K): State[K]['inputs'] {
    return this.#state[key].inputs
  }

  getOutputs<K extends ModelKey>(key: K): State[K]['outputs'] {
    return this.#state[key].outputs
  }

  setInputs<K extends ModelKey>(
    key: K,
    inputs: Partial<State[K]['inputs']>,
  ): void {
    this.#state[key].inputs = {
      ...this.#state[key].inputs,
      ...inputs,
    }
    this.#callSubscribers(this.#inputSubscribers, key)

    // TODO: we could precompute reachability and topological sorts for each key
    const affected = GRAPH.reachable(key).topologicalSort()

    for (const key of affected) {
      const dependencies = Object.assign(
        this.#state[key].dependencies,
        buildDependencies(this.#state, key),
      )

      this.#state[key].outputs = {
        ...this.#state[key].outputs,
        ...getOutputs(key, dependencies, this.#state[key].inputs),
      }
      this.#callSubscribers(this.#outputSubscribers, key)
    }
  }

  subscribeInputs(...subscriber: Subscriber): SubscriberCancel {
    return this.#pushSubscriber(this.#inputSubscribers, subscriber)
  }

  subscribeOutputs(...subscriber: Subscriber): SubscriberCancel {
    return this.#pushSubscriber(this.#outputSubscribers, subscriber)
  }

  #callSubscribers(subscribers: Subscriber[], key: ModelKey): void {
    for (const [, callback] of subscribers.filter(([k]) => k === key)) {
      callback()
    }
  }

  #pushSubscriber(subscribers: Subscriber[], item: Subscriber): SubscriberCancel {
    subscribers.push(item)

    return () => {
      const index = subscribers.findIndex(([, s]) => s === item[1])
      if (index < 0) {
        console.warn('BUG: attempted to remove a non-existing subscriber')
      }

      if (index >= 0) {
        const removed = subscribers.splice(index, 1)
        if (removed.length !== 1) {
          console.warn(`BUG: expected to remove 1 subscriber but removed ${removed.length}`)
        }
      }
    }
  }
}

// Calculate the full model state from initial dependencies and inputs.
//
// The whole state is only calculated together once. Thereafter it is updated incrementally by the
// model.
//
// NOTE: this could be defined inline in the `Model` constructor, but it's very large so it makes
// the `Model` implementation clearer to pull it out here.
function initState(dependencies: DependenciesInit, inputs: InputsInit): State {
  const state: Partial<State> = {}

  // TODO: we could precompute the topological sort
  for (const key of GRAPH.topologicalSort()) {
    state[key] = initStateEntry(state, key, dependencies[key], inputs[key]) as any
  }

  return state as State
}

// Initialise a state entry
function initStateEntry<K extends ModelKey>(
  state: Partial<State>,
  key: K,
  dependenciesInit: DependenciesInit[K],
  inputs: Inputs<K>,
): StateEntry<K> {
  const dependencies = {
    ...dependenciesInit,
    ...buildDependencies(state, key),
  } as Dependencies<K>

  return {
    dependencies,
    inputs,
    outputs: getOutputs(key, dependencies, inputs),
  } as any
}

// Build dependencies for a calculator
function buildDependencies<K extends ModelKey>(
  state: Partial<State>,
  key: K,
): Partial<Dependencies<K>> {
  const dependencies: Partial<Dependencies<K>> = {}
  const dependencyIndex: DependencyIndex = DEPENDENCY_INDEX

  for (const dep of GRAPH_INVERSE.edges(key)) {
    // split always has at least one element, and the graph definition means the cast is valid
    const calculatorKey = dep.split('.')[0]! as CalculatorKey

    if (dependencyIndex[key][calculatorKey]) {
      const dependency = state[dep]?.outputs
      if (dependency == null) {
        throw new Error(`BUG: dependency ${dep} for ${key} not ready`)
      }

      // the type of DEPENDENCY_INDEX ensures the casts are valid
      dependencies[calculatorKey as keyof Dependencies<K>] = dependency as any
    } else {
      throw new Error(`BUG: invalid dependent ${key} for node ${dep}`)
    }
  }

  return dependencies
}

// Generic interface to `Calculator<D, I, O>.getOutputs`.
function getOutputs<K extends ModelKey>(
  key: K,
  dependencies: Dependencies<K>,
  inputs: Inputs<K>,
): Outputs<K> {
  // split always has at least one element, and the type definitions means the cast is valid
  const calculatorKey = key.split('.')[0] as StateEntryCalculatorKey<K>

  // TypeScript can't seem to reason through this.
  const calculator = CALCULATORS[calculatorKey] as CalculatorImpl<
    Dependencies<K>,
    Inputs<K>,
    Outputs<K>
  >

  return calculator.getOutputs(dependencies, inputs)
}

type DependencyIndex = {
  [K in ModelKey]: Partial<{
    [C in CalculatorKey]: true
  }>
}

// This can only have one value, but it forces us to write and keep up-to-date a runtime value for
// checking dependency validity, which will hopefully make bugs in dependencies more apparent.
const DEPENDENCY_INDEX: {
  [K in ModelKey]: OmitNever<{
    [Dep in keyof StateEntry<K>['dependencies']]: Dep extends CalculatorKey
      ? StateEntry<K>['dependencies'][Dep] extends CalculatorTypes[Dep]['outputs']
        ? true
        : never
      : never
  }>
} = {
  'location': {},
  'property': {},
  'fabric.current': {
    property: true,
  },
  'fabric.upgrade': {
    property: true,
  },
  'fabricEnergy.current': {
    location: true,
    fabric: true,
    usage: true,
  },
  'fabricEnergy.upgrade': {
    location: true,
    fabric: true,
    usage: true,
  },
  'fabricEnergyCost.current': {
    fabricEnergy: true,
    heating: true,
  },
  'fabricEnergyCost.upgrade': {
    fabricEnergy: true,
    heating: true,
  },
  'heating.current': {
    property: true,
    fabricEnergy: true,
  },
  'heating.upgrade': {
    property: true,
    fabricEnergy: true,
  },
  'heatingEnergyCost.current': {
    heating: true,
    usage: true,
  },
  'heatingEnergyCost.upgrade': {
    heating: true,
    usage: true,
  },
  'usage.current': {
    location: true,
    fabric: true,
  },
  'usage.upgrade': {
    location: true,
    fabric: true,
  },
  'usageCost.current': {
    location: true,
    heating: true,
    usage: true,
  },
  'usageCost.upgrade': {
    location: true,
    heating: true,
    usage: true,
  },
  'solar.current': {
    location: true,
    property: true,
  },
  'solar.upgrade': {
    location: true,
    property: true,
  },
} as const satisfies DependencyIndex

type OmitNever<T> = Pick<T, {
  [K in keyof T]: [T[K]] extends [never] ? never : K
}[keyof T]>

type OptionalEmptyValues<T> = Omit<T, KeysWithEmptyValues<T>> & Partial<T>

type KeysWithEmptyValues<T> = {
  [K in keyof T]: T[K] extends { [_: string | number | symbol]: never } ? K : never
}[keyof T]
