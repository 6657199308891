import { VideoHomeBlock, useHomeBlockContext, useHomeBlockStateContext } from './HomeBlock'
import React from 'react'
import { Option, Switch, Typography } from '@mui/joy'
import { BlockImage, EducationParagraph, EducationTeaser, Money, Output, UpgradeSummary, UpgradeableExpansion, UpgradeableRowContent } from './Content'
import { FieldState, INT_PARSER, Select, Slider, useFieldState } from './form'
import { useModel, useModelInputs, useModelOutputs } from './ModelProvider'
import * as usageCalculator from '@/lib/calculators/usage'
import { z } from 'zod'
import Model from '@/lib/model'

const ID = 'usage'

const ROOM_HEAT_ON_OPTIONS: { [K in usageCalculator.HeatingMonth]: string } = {
  [1]: '1 Jan',
  [2]: '1 Feb',
  [3]: '1 Mar',
  [4]: '1 Apr',
  [5]: '1 May',
  [6]: '1 Jun',
  [7]: '1 Jul',
  [8]: '1 Aug',
  [9]: '1 Sep',
  [10]: '1 Oct',
  [11]: '1 Nov',
  [12]: '1 Dec',
}

const ROOM_HEAT_OFF_OPTIONS: { [K in usageCalculator.HeatingMonth]: string } = {
  [1]: '31 Jan',
  [2]: '28 Feb',
  [3]: '31 Mar',
  [4]: '30 Apr',
  [5]: '31 May',
  [6]: '30 Jun',
  [7]: '31 Jul',
  [8]: '31 Aug',
  [9]: '30 Sep',
  [10]: '31 Oct',
  [11]: '30 Nov',
  [12]: '31 Dec',
}

export default function UsageBlock({
  playNextButton,
}: {
  playNextButton?: React.ReactNode
}): React.ReactNode {
  const { expandedBlocks } = useHomeBlockContext()
  const { state } = useHomeBlockStateContext()

  const usageCurrentInputs = useModelInputs('usage.current')
  const usageUpgradeInputs = useModelInputs('usage.upgrade')

  const { totalEnergyCostPerYear: currentEnergyCost, hotWaterCostPerYear: currentHotWaterCost } = useModelOutputs('usageCost.current')
  const currentCost = currentEnergyCost + currentHotWaterCost

  const { totalEnergyCostPerYear: upgradeEnergyCost, hotWaterCostPerYear: upgradeHotWaterCost } = useModelOutputs('usageCost.upgrade')
  const upgradeCost = upgradeEnergyCost + upgradeHotWaterCost

  let title: React.ReactNode
  let instruction: React.ReactNode

  switch (state) {
    case 'setup':
      title = undefined
      instruction = (
        <Typography level="title-sm">
          {expandedBlocks.includes(ID)
            ? 'Describe your current daily routine'
            : 'Small changes to your daily routine add up. See how much you could save!'}
        </Typography>
      )
      break
    case 'play':
      title = 'Daily routine changes'

      const upgrades = getUpgrades(usageCurrentInputs, usageUpgradeInputs)
      instruction = upgrades.length > 0
        ? <UpgradeSummary upgrades={upgrades} />
        : 'See how much you can save by changing your daily routine'

      break
    case 'fix':
      title = 'Current daily routine'
      instruction = 'Update details of your current daily routine'
      break
  }

  return (
    <VideoHomeBlock
      id={ID}
      media={<BlockImage alt="An orangutan living smartly" src="/home_usage.png" />}
      title={title}
      instruction={instruction}
      savings={currentCost - upgradeCost}
      expansion={<SmartLivingExpansion playNextButton={playNextButton} />}
    />
  )
}

function SmartLivingExpansion({
  playNextButton,
}: {
  playNextButton?: React.ReactNode
}): React.ReactNode {
  const { model, searchId } = useModel()

  const upgrade = useUsageFields(model, 'upgrade')
  const current = useUsageFields(model, 'current', upgrade)

  const usageCurrentOutputs = useModelOutputs('usage.current')
  const usageUpgradeOutputs = useModelOutputs('usage.upgrade')

  const usageCostCurrentOutputs = useModelOutputs('usageCost.current')
  const usageCostUpgradeOutputs = useModelOutputs('usageCost.upgrade')

  const { waterUnitCost } = useModelOutputs('location')
  const { waterMeter: currentWaterMeter } = useModelInputs('usageCost.current')
  const { waterMeter: upgradeWaterMeter } = useModelInputs('usageCost.upgrade')

  const onChangeCurrentWaterMeter = ({ target }: React.ChangeEvent<HTMLInputElement>) => {
    model.setInputs('usageCost.current', { waterMeter: target.checked })
    model.setInputs('usageCost.upgrade', { waterMeter: target.checked })
  }

  const rows: UpgradeableRowContent[] = [
    {
      current: 'Hot water',
      upgrade: 'Hot water savings',
    },
    {
      icon: 'watertank',
      label: 'Baths per week',
      education: (
        <EducationParagraph>
          Enter the typical number of baths taken each week in your property.
        </EducationParagraph>
      ),
      current: (
        <Slider
          name="usage.current.bathCountPerWeek"
          state={current.bathCountPerWeek}
          min={0}
          max={7}
        />
      ),
      upgrade: (
        <Slider
          name="usage.upgrade.bathCountPerWeek"
          state={upgrade.bathCountPerWeek}
          min={0}
          max={7}
        />
      ),
    },
    {
      icon: 'watertank',
      label: 'Shower head',
      education: (
        <EducationParagraph>
          The type of shower head you have determines how much hot water you use every minute while you shower.
          Eco and hand shower heads have reduced water flow and therefore reduce both your energy bills and your water bills (if you have metered water).
        </EducationParagraph>
      ),
      current: (
        <Select name="usage.current.showerHeadFlowRate" state={current.showerHeadFlowRate}>
          <ShowerHeadFlowRateOptions />
        </Select>
      ),
      upgrade: (
        <Select name="usage.upgrade.showerHeadFlowRate" state={upgrade.showerHeadFlowRate}>
          <ShowerHeadFlowRateOptions />
        </Select>
      ),
    },
    {
      icon: 'watertank',
      label: 'Showers per day',
      education: (
        <EducationParagraph>
          Enter the typical number of showers taken each day in your property.
          For example, if three people take one shower a day, and one person takes two showers a day, then enter five.
        </EducationParagraph>
      ),
      current: (
        <Slider
          name="usage.current.showerCountPerDay"
          state={current.showerCountPerDay}
          min={0}
          max={10}
        />
      ),
      upgrade: (
        <Slider
          name="usage.upgrade.showerCountPerDay"
          state={upgrade.showerCountPerDay}
          min={0}
          max={10}
        />
      ),
    },
    {
      icon: 'watertank',
      label: 'Shower length',
      education: (
        <EducationParagraph>
          Enter the average length of showers taken.
          You'll probably need to guestimate this.
          Best not to time your housemates or teenage children, as you may not get a positive reaction.
          🙂
        </EducationParagraph>
      ),
      current: (
        <Slider
          name="usage.current.showerDuration"
          state={current.showerDuration}
          min={1}
          max={10}
          unit="min"
        />
      ),
      upgrade: (
        <Slider
          name="usage.upgrade.showerDuration"
          state={upgrade.showerDuration}
          min={1}
          max={10}
          unit="min"
        />
      ),
    },
    {
      icon: 'watertank',
      label: 'Water meter',
      education: (
        <>
          <EducationParagraph>
            Select this if you have a water meter.
            In some areas in the UK, you can opt to have a water meter.
            The meter measures how much water you use and is also used to estimate how much waste water you produce.
            Both will appear on your water bill.
          </EducationParagraph>
          <EducationParagraph>
            Installing a water meter typically reduces your water bill.
            It also means you can save a lot by cutting down on how much water you use.
          </EducationParagraph>
        </>
      ),
      current: (
        <Switch
          checked={currentWaterMeter}
          onChange={onChangeCurrentWaterMeter}
          disabled={waterUnitCost === 0}
        />
      ),
      upgrade: (
        <Switch
          checked={upgradeWaterMeter}
          disabled
        />
      ),
    },
    {
      icon: 'energybill',
      label: 'Water cost',
      education: (
        <EducationParagraph>
          'Water cost' is a rough estimate of the cost of water used for your showers and baths each year.
          If you have shorter or fewer showers, your water bill will go down.
          It includes the cost of waste water which is based on the water you use.
        </EducationParagraph>
      ),
      current: currentWaterMeter
        ? (
          <Money value={usageCostCurrentOutputs.hotWaterCostPerYear} suffix="pa" />
          )
        : null,
      upgrade: upgradeWaterMeter
        ? (
          <Money value={usageCostUpgradeOutputs.hotWaterCostPerYear} suffix="pa" />
          )
        : null,
    },
    {
      icon: 'gasburner',
      label: 'Water heating energy',
      current: (
        <Output value={usageCurrentOutputs.hotWaterEnergyPerYear} unit="kWh pa" roundingIncrement={100} />
      ),
      upgrade: (
        <Output value={usageUpgradeOutputs.hotWaterEnergyPerYear} unit="kWh pa" roundingIncrement={100} />
      ),
    },
    {
      icon: 'energybill',
      label: 'Energy cost',
      education: (
        <EducationParagraph>
          'Energy cost' is a rough estimate of the cost of heating the water for your showers and baths each year.
          If you have shorter or fewer showers, your energy bill will go down.
        </EducationParagraph>
      ),
      current: (
        <Money value={usageCostCurrentOutputs.hotWaterEnergyCostPerYear} suffix="pa" />
      ),
      upgrade: (
        <Money value={usageCostUpgradeOutputs.hotWaterEnergyCostPerYear} suffix="pa" />
      ),
    },
    {
      icon: 'energybill',
      label: 'Estimated savings',
      education: (
        <>
          <EducationParagraph>
            'Estimated savings' is how much you could save on your annual bills when you change your shower or bath routine.
          </EducationParagraph>
          <EducationParagraph>
            To see big savings, you'll need to be inventive about how to make shorter showers for your family or housemates fun.
            Keep an eye on Orangutan social channels for ideas.
          </EducationParagraph>
        </>
      ),
      current: null,
      upgrade: (
        <Money
          value={
              usageCostCurrentOutputs.hotWaterCostPerYear + usageCostCurrentOutputs.hotWaterEnergyCostPerYear
              - usageCostUpgradeOutputs.hotWaterCostPerYear - usageCostUpgradeOutputs.hotWaterEnergyCostPerYear
            }
          suffix="pa"
        />
      ),
    },
    {
      current: 'Heating controls',
      upgrade: 'Control savings',
    },
    {
      icon: 'thermostat',
      label: 'Heating on',
      education: (
        <EducationParagraph>
          Enter the month you typically turn on your room heating.
          This helps us estimate your energy bill for room heating.
        </EducationParagraph>
      ),
      current: (
        <Select name="usage.current.heatingOnMonth" state={current.heatingOnMonth}>
          {Object.entries(ROOM_HEAT_ON_OPTIONS).map(([key, value]) => (
            <Option key={key} value={key}>{value}</Option>
          ))}
        </Select>
      ),
      upgrade: (
        <Select name="usage.upgrade.heatingOnMonth" state={upgrade.heatingOnMonth}>
          {Object.entries(ROOM_HEAT_ON_OPTIONS).map(([key, value]) => (
            <Option key={key} value={key}>{value}</Option>
          ))}
        </Select>
      ),
    },
    {
      icon: 'thermostat',
      label: 'Heating off',
      education: (
        <EducationParagraph>
          Enter the month you typically turn off your room heating.
          This helps us estimate your energy bill for room heating.
        </EducationParagraph>
      ),
      current: (
        <Select name="usage.current.heatingOffMonth" state={current.heatingOffMonth}>
          {Object.entries(ROOM_HEAT_OFF_OPTIONS).map(([key, value]) => (
            <Option key={key} value={key}>{value}</Option>
          ))}
        </Select>
      ),
      upgrade: (
        <Select name="usage.upgrade.heatingOffMonth" state={upgrade.heatingOffMonth}>
          {Object.entries(ROOM_HEAT_OFF_OPTIONS).map(([key, value]) => (
            <Option key={key} value={key}>{value}</Option>
          ))}
        </Select>
      ),
    },
    {
      icon: 'thermostat',
      label: 'Room temperature',
      education: (
        <EducationParagraph>
          Enter the temperature you set on your thermostat or room temperature control.
          If you vary the temperature controls from room to room, please enter an average.
        </EducationParagraph>
      ),
      current: (
        <Slider
          name="usage.current.heatingTemp"
          state={current.heatingTemp}
          min={18}
          max={22}
          unit="°C"
        />
      ),
      upgrade: (
        <Slider
          name="usage.upgrade.heatingTemp"
          state={upgrade.heatingTemp}
          min={18}
          max={22}
          unit="°C"
        />
      ),
    },
    {
      icon: 'gasburner',
      label: 'Room heating energy',
      education: (
        <EducationParagraph>
          'Room heating energy' is the total heat energy needed to heat your rooms for the months your heating system is on.
          This estimate is based on hourly temperature data that covers the last few years.
          Properties with lower room temperatures use less energy.
        </EducationParagraph>
      ),
      current: (
        <Output value={usageCurrentOutputs.heatingEnergyPerYear} unit="kWh pa" roundingIncrement={100} />
      ),
      upgrade: (
        <Output value={usageUpgradeOutputs.heatingEnergyPerYear} unit="kWh pa" roundingIncrement={100} />
      ),
    },
    {
      icon: 'energybill',
      label: 'Room heating cost',
      education: (
        <>
          <EducationParagraph>
            'Room heating cost' is a rough estimate of the cost of gas or electricity used to heat your rooms each year.
          </EducationParagraph>
          <EducationParagraph>
            This estimate is based on any upgrades you have entered for your insulation or heating system.
            It does not include the cost of heating your hot water or the standing charge.
          </EducationParagraph>
        </>
      ),
      current: (
        <Money value={usageCostCurrentOutputs.heatingCostPerYear} suffix="pa" />
      ),
      upgrade: (
        <Money value={usageCostUpgradeOutputs.heatingCostPerYear} suffix="pa" />
      ),
    },
    {
      icon: 'energybill',
      label: 'Estimated savings',
      education: (
        <>
          <EducationParagraph>
            'Estimated savings' is how much you could save on your annual energy bill when you reduce your room temperature.
          </EducationParagraph>
          <EducationParagraph>
            Reducing your room temperature by just 1°C will make a big difference to your annual energy bill.
            However, this is not a good idea for everyone.
            Older people or people with certain medical conditions need higher room temperatures.
          </EducationParagraph>
        </>
      ),
      current: null,
      upgrade: (
        <Money
          value={usageCostCurrentOutputs.heatingCostPerYear - usageCostUpgradeOutputs.heatingCostPerYear}
          suffix="pa"
        />
      ),
    },
    {
      current: 'Energy costs',
      upgrade: 'Daily routine savings',
    },
    {
      icon: 'gasburner',
      label: 'Total heating energy',
      current: (
        <Output value={usageCurrentOutputs.hotWaterEnergyPerYear + usageCurrentOutputs.heatingEnergyPerYear} unit="kWh pa" roundingIncrement={100} />
      ),
      upgrade: (
        <Output value={usageUpgradeOutputs.hotWaterEnergyPerYear + usageUpgradeOutputs.heatingEnergyPerYear} unit="kWh pa" roundingIncrement={100} />
      ),
    },
    {
      icon: 'energybill',
      label: 'Energy cost',
      education: (
        <>
          <EducationParagraph>
            'Energy cost' is a rough estimate of the cost of heating your rooms and your water for your showers each year.
            If you change your daily routine, you will make a big difference to your bills!
          </EducationParagraph>
          <EducationParagraph>
            This estimate is based on any upgrades you have entered for your insulation or heating system.
            It does not include the standing charge.
          </EducationParagraph>
        </>
      ),
      current: (
        <Money value={usageCostCurrentOutputs.totalEnergyCostPerYear} suffix="pa" />
      ),
      upgrade: (
        <Money value={usageCostUpgradeOutputs.totalEnergyCostPerYear} suffix="pa" />
      ),
    },
    {
      icon: 'energybill',
      label: 'Estimated savings',
      education: (
        <>
          <EducationParagraph>
            'Estimated savings' is how much you could save on your annual energy bills when your household changes its daily routine.
          </EducationParagraph>
          <EducationParagraph>
            To make a real difference to your bills, aim to bring everyone in your household along with you using tact and good humour!
          </EducationParagraph>
        </>
      ),
      current: null,
      upgrade: (
        <Money
          value={
            usageCostCurrentOutputs.hotWaterCostPerYear + usageCostCurrentOutputs.totalEnergyCostPerYear
            - usageCostUpgradeOutputs.hotWaterCostPerYear - usageCostUpgradeOutputs.totalEnergyCostPerYear
          }
          suffix="pa"
        />
      ),
    },
  ]

  const upgradeableExpansionProps = playNextButton != null
    ? {
        playNextButton,
      }
    : {
        playNextText: searchId == null ? 'Now see for your property' : 'Explore insulation savings',
      }

  return (
    <>
      <EducationTeaser
        text="Small changes to your daily routine saves money."
        href="https://www.turbine.education/routine-changes"
      />
      <UpgradeableExpansion
        {...upgradeableExpansionProps}
        rows={rows}
      />
    </>
  )
}

const SHOWER_HEAD_FLOW_RATE_OPTIONS = {
  [6]: 'Eco',
  [8]: 'Hand',
  [12]: 'Overhead',
  [16]: 'Rain',
}

function ShowerHeadFlowRateOptions(): React.ReactNode {
  return Object.entries(SHOWER_HEAD_FLOW_RATE_OPTIONS).map(([key, value]) => (
    <Option key={key} value={key}>
      {value}
      {' '}
      (
      {key}
      L/min)
    </Option>
  ))
}

function getUpgrades(current: usageCalculator.Inputs, upgrade: usageCalculator.Inputs): string[] {
  return [
    ...upgrade.showerHeadFlowRate < current.showerHeadFlowRate
      ? ['More efficient shower head']
      : [],
    ...upgrade.showerCountPerDay < current.showerCountPerDay
      ? ['Take fewer showers']
      : [],
    ...upgrade.showerDuration < current.showerDuration
      ? ['Take shorter showers']
      : [],
    ...upgrade.heatingOnMonth > current.heatingOnMonth
      ? ['Turn on heating later']
      : [],
    ...upgrade.heatingOffMonth < current.heatingOffMonth
      ? ['Turn off heating earlier']
      : [],
    ...upgrade.heatingTemp < current.heatingTemp
      ? ['Turn down heating']
      : [],
  ]
}

type UsageFields = {
  [K in keyof usageCalculator.Inputs]: FieldState<usageCalculator.Inputs[K]>
}

function useUsageFields(
  model: Model,
  instance: 'current' | 'upgrade',
  upgrade?: UsageFields,
): UsageFields {
  const { bathCountPerWeek, showerHeadFlowRate, showerDuration, showerCountPerDay, heatingOnMonth, heatingOffMonth, heatingTemp } = useModelInputs(`usage.${instance}`)

  const fields = {
    bathCountPerWeek: useFieldState(INT_PARSER, String, bathCountPerWeek, (bathCountPerWeek) => {
      model.setInputs(`usage.${instance}`, { bathCountPerWeek })
      if (upgrade != null) {
        model.setInputs('usage.upgrade', { bathCountPerWeek })
        upgrade?.bathCountPerWeek.setValue(bathCountPerWeek)
      }
    }),
    showerHeadFlowRate: useFieldState(INT_PARSER, String, showerHeadFlowRate, (showerHeadFlowRate) => {
      model.setInputs(`usage.${instance}`, { showerHeadFlowRate })
      if (upgrade != null) {
        model.setInputs('usage.upgrade', { showerHeadFlowRate })
        upgrade?.showerHeadFlowRate.setValue(showerHeadFlowRate)
      }
    }),
    showerDuration: useFieldState(INT_PARSER, String, showerDuration, (showerDuration) => {
      model.setInputs(`usage.${instance}`, { showerDuration })
      if (upgrade != null) {
        model.setInputs('usage.upgrade', { showerDuration })
        upgrade?.showerDuration.setValue(showerDuration)
      }
    }),
    showerCountPerDay: useFieldState(INT_PARSER, String, showerCountPerDay, (showerCountPerDay) => {
      model.setInputs(`usage.${instance}`, { showerCountPerDay })
      if (upgrade != null) {
        model.setInputs('usage.upgrade', { showerCountPerDay })
        upgrade?.showerCountPerDay.setValue(showerCountPerDay)
      }
    }),
    heatingOnMonth: useFieldState(HEATING_MONTH_PARSER, String, heatingOnMonth, (heatingOnMonth) => {
      model.setInputs(`usage.${instance}`, { heatingOnMonth })
      if (upgrade != null) {
        model.setInputs('usage.upgrade', { heatingOnMonth })
        upgrade?.heatingOnMonth.setValue(heatingOnMonth)
      }
    }),
    heatingOffMonth: useFieldState(HEATING_MONTH_PARSER, String, heatingOffMonth, (heatingOffMonth) => {
      model.setInputs(`usage.${instance}`, { heatingOffMonth })
      if (upgrade != null) {
        model.setInputs('usage.upgrade', { heatingOffMonth })
        upgrade?.heatingOffMonth.setValue(heatingOffMonth)
      }
    }),
    heatingTemp: useFieldState(INT_PARSER, String, heatingTemp, (heatingTemp) => {
      model.setInputs(`usage.${instance}`, { heatingTemp })
      if (upgrade != null) {
        model.setInputs('usage.upgrade', { heatingTemp })
        upgrade?.heatingTemp.setValue(heatingTemp)
      }
    }),
  }

  return fields
}

const HEATING_MONTH_PARSER = INT_PARSER.pipe(z.union([
  z.literal(1),
  z.literal(2),
  z.literal(3),
  z.literal(4),
  z.literal(5),
  z.literal(6),
  z.literal(7),
  z.literal(8),
  z.literal(9),
  z.literal(10),
  z.literal(11),
  z.literal(12),
])) satisfies z.ZodType<usageCalculator.HeatingMonth, z.ZodTypeDef, unknown>
