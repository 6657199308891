// The heating component of the model is concerned with energy supply and heating output.

import type * as propertyCalculator from './property'
import type * as fabricEnergy from './fabricEnergy'

export type Dependencies = {
  property: propertyCalculator.Outputs
  fabricEnergy: fabricEnergy.Outputs
}

export type Inputs = {
  heater: Heater
  radiatorCount: number
  flowTemp: number
  tariff: string
}

export type Outputs = {
  efficiency: number
  tariffType: TariffType
  energyUnitCost: number
  heatUnitCost: number
}

export const HEATERS = [
  'gasBoiler',
  'combiBoiler',
  'electric',
  'oil',
  'lpg',
  'heatPump',
] as const

export type Heater = typeof HEATERS[number]

export function getOutputs({ fabricEnergy }: Dependencies, inputs: Inputs): Outputs {
  const efficiency = getEfficiency(fabricEnergy, inputs)

  const tariffType = HEATER_TARIFF_TYPE[inputs.heater]

  const energyUnitCost = TARIFFS[tariffType][inputs.tariff]?.[1] ?? Object.values(TARIFFS[tariffType])[0]![1]

  const heatUnitCost = energyUnitCost / efficiency / 100

  return {
    efficiency,
    tariffType,
    energyUnitCost,
    heatUnitCost,
  }
}

// TODO: this should use an actual calculation - numbers here are inferred from model example
function getEfficiency(fabricEnergy: Dependencies['fabricEnergy'], { heater, flowTemp }: Inputs): number {
  switch (heater) {
    case 'gasBoiler':
      return 0.85
    case 'combiBoiler':
      return 0.9
    case 'electric':
      return 1
    case 'oil':
    case 'lpg':
      return 0.8
    case 'heatPump':
      const { temp } = SCOP_TABLE.find(({ output }) => output >= fabricEnergy.peakHeatLossPerHour) ?? SCOP_TABLE[SCOP_TABLE.length - 1]!
      const { scop } = temp?.find(({ temp }) => flowTemp >= temp) ?? temp[temp.length - 1]!

      return scop ?? SCOP_TABLE[4].temp[5].scop
  }
}

const SCOP_TABLE = [
  {
    output: 3.5,
    temp: [
      { temp: 60, scop: 2.73 },
      { temp: 55, scop: 3.10 },
      { temp: 50, scop: 3.37 },
      { temp: 45, scop: 3.65 },
      { temp: 40, scop: 4.03 },
      { temp: 35, scop: 4.41 },
    ],
  },
  {
    output: 5,
    temp: [
      { temp: 60, scop: 2.70 },
      { temp: 55, scop: 3.06 },
      { temp: 50, scop: 3.41 },
      { temp: 45, scop: 3.77 },
      { temp: 40, scop: 4.13 },
      { temp: 35, scop: 4.48 },
    ],
  },
  {
    output: 7,
    temp: [
      { temp: 60, scop: 3.16 },
      { temp: 55, scop: 3.39 },
      { temp: 50, scop: 3.65 },
      { temp: 45, scop: 3.91 },
      { temp: 40, scop: 4.14 },
      { temp: 35, scop: 4.36 },
    ],
  },
  {
    output: 10,
    temp: [
      { temp: 60, scop: 3.15 },
      { temp: 55, scop: 3.58 },
      { temp: 50, scop: 3.85 },
      { temp: 45, scop: 4.13 },
      { temp: 40, scop: 4.58 },
      { temp: 35, scop: 5.03 },
    ],
  },
  {
    output: 12,
    temp: [
      { temp: 60, scop: 3.30 },
      { temp: 55, scop: 3.63 },
      { temp: 50, scop: 3.92 },
      { temp: 45, scop: 4.21 },
      { temp: 40, scop: 4.55 },
      { temp: 35, scop: 4.88 },
    ],
  },
] as const

export type TariffType = 'gas' | 'electric' | 'oil' | 'lpg' | 'heatPump'

const HEATER_TARIFF_TYPE: { [H in Heater]: TariffType } = {
  gasBoiler: 'gas',
  combiBoiler: 'gas',
  electric: 'electric',
  oil: 'oil',
  lpg: 'lpg',
  heatPump: 'heatPump',
}

type Tariff = [number, number]

export const TARIFFS: { [F in TariffType]: { [T: string]: Tariff } } = {
  gas: {
    'Octopus - gas': [29.6, 6.2],
    'EDF Energy - gas': [31.7, 6.2],
    'OVO Energy - gas': [31.4, 6.0],
    'British Gas - gas': [31.7, 6.2],
    'Scottish Power - gas': [32.4, 7.5],
  },
  electric: {
    'Octopus - electric': [58.9, 24.5],
    'EDF Energy - electric': [61.0, 24.5],
    'OVO Energy - electric': [65.8, 24.5],
    'British Gas - electric': [58.2, 25.0],
    'Scottish Power - electric': [56.7, 29.8],
  },
  oil: {
    Oil: [0, 7],
  },
  lpg: {
    LPG: [0, 9.9],
  },
  heatPump: {
    'Cosy Octopus': [62.1, 18.7],
    'EDF Energy Heat Pump Tracker Tariff': [61.1, 19.5],
    'Ovo Heat Pump Plus': [65.8, 19.7],
    'British Gas Heat Pump Tracker Tariff': [58.2, 19.5],
    'Scottish Power Heat Pump Tracker Tariff': [56.7, 20.4],
  },
}
