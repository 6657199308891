import * as heatingCalculator from './heating'
import * as usageCalculator from './usage'

export type Dependencies = {
  heating: heatingCalculator.Outputs
  usage: usageCalculator.Outputs
}

export type Inputs = {
  // NOTE: This fields is unused, but having `{}` as an input type weakens type-checking
  _heatingEnergyCost: null
}

export type Outputs = {
  totalEnergyConsumptionPerYear: number
  totalEnergyCostPerYear: number
}

export function getOutputs({ heating, usage }: Dependencies, {}: Inputs): Outputs {
  const totalEnergyConsumptionPerYear = usage.totalEnergyPerYear / heating.efficiency

  const totalEnergyCostPerYear = heating.heatUnitCost * usage.totalEnergyPerYear

  return {
    totalEnergyConsumptionPerYear,
    totalEnergyCostPerYear,
  }
}
