import * as locationCalculator from './location'
import * as heatingCalculator from './heating'
import * as usageCalculator from './usage'

export type Dependencies = {
  location: locationCalculator.Outputs
  heating: heatingCalculator.Outputs
  usage: usageCalculator.Outputs
}

export type Inputs = {
  waterMeter: boolean
}

export type Outputs = {
  hotWaterCostPerYear: number
  hotWaterEnergyCostPerDay: number
  hotWaterEnergyCostPerYear: number
  hotWaterTotalCostPerYear: number
  heatingCostPerYear: number
  totalEnergyCostPerYear: number
}

export function getOutputs(
  {
    location,
    heating,
    usage,
  }: Dependencies,
  inputs: Inputs,
): Outputs {
  const hotWaterCostPerYear = inputs.waterMeter
    ? getWaterCost({ location }, usage.hotWaterPerYear)
    : 0

  const hotWaterEnergyCostPerDay = getHeatingEnergyCost({ heating }, usage.hotWaterEnergyPerDay)

  const hotWaterEnergyCostPerYear = getHeatingEnergyCost({ heating }, usage.hotWaterEnergyPerYear)

  const hotWaterTotalCostPerYear = hotWaterEnergyCostPerYear + hotWaterCostPerYear

  const heatingCostPerYear = getHeatingEnergyCost({ heating }, usage.heatingEnergyPerYear)

  const totalEnergyCostPerYear = hotWaterEnergyCostPerYear + heatingCostPerYear

  return {
    hotWaterCostPerYear,
    hotWaterEnergyCostPerDay,
    hotWaterEnergyCostPerYear,
    hotWaterTotalCostPerYear,
    heatingCostPerYear,
    totalEnergyCostPerYear,
  }
}

function getWaterCost({ location }: Pick<Dependencies, 'location'>, water: number): number {
  return location.waterUnitCost / 100 * water
}

function getHeatingEnergyCost({ heating }: Pick<Dependencies, 'heating'>, energy: number): number {
  return heating.heatUnitCost * energy
}
