'use client'

import React from 'react'
import useLocalStorage from '@/lib/useLocalStorage'
import { CircularProgress, Stack } from '@mui/joy'
import { SearchWithBlockInputs, getSearch, saveFabric, saveHeating, saveProperty, saveSearch as saveSearchInner, saveUsage } from './actions'
import { Search } from '@prisma/client'
import { EpcSchema } from '@/lib/epc'
import Model, { CalculatorKey, Inputs, InputsInit, ModelKey, Outputs } from '@/lib/model'
import { getModelInputs } from './model-inputs'
import { Address } from '@/lib/components/AddressAutocomplete'

export type SaveableKeys = Extract<CalculatorKey, 'property' | 'fabric' | 'heating' | 'usage'>

export type BlockSaveInstance = 'current' | 'upgrade'

export type ModelContext = {
  model: Model
  searchId: string | null
  saveSearch: (address: Address) => Promise<Search>
  saveBlock: (id: SaveableKeys, instance: BlockSaveInstance, model: ModelContext) => Promise<void>
  ready: boolean
  setReady: (_: boolean) => void
  reset: () => void
}

const ModelContext = React.createContext<ModelContext | null>(null)

export function ModelProvider({
  children,
}: {
  children: React.ReactNode
}): React.ReactNode {
  const [loading, setLoading] = React.useState(true)

  const { value: searchId, setValue: setSearchId, ready: searchIdReady } = useLocalStorage('search-id')

  const [ready, setReady] = React.useState<boolean>(true)

  const { current: defaultInputs } = React.useRef(getModelInputs())
  const { current: model } = useModelRef(defaultInputs)

  React.useEffect(() => {
    if (!searchIdReady) {
      return
    }

    if (searchId === null) {
      setLoading(false)
      return
    }

    getSearch(searchId).then(
      (search) => {
        if (search !== null) {
          setSearch(search)
        }
        setLoading(false)
      },
      (error) => {
        console.error(error)
        setLoading(false)
      },
    )
    // We specifically want to run this once on first render
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchId, searchIdReady])

  async function saveSearch(address: Address): Promise<Search> {
    const search = await saveSearchInner(address)
    setSearch(search)

    return search
  }

  async function saveBlock(id: SaveableKeys, instance: BlockSaveInstance, { searchId, ready }: ModelContext): Promise<void> {
    if (searchId == null || !ready) return

    switch (id) {
      case 'property':
        await saveProperty(searchId, model.getInputs('property'))
        break
      case 'fabric':
        await saveFabric(searchId, instance, model.getInputs(`fabric.${instance}`))
        break
      case 'heating':
        await saveHeating(searchId, instance, model.getInputs(`heating.${instance}`))
        break
      case 'usage':
        await saveUsage(searchId, instance, model.getInputs(`usage.${instance}`))
        break
    }
  }

  function setSearch(search: SearchWithBlockInputs | null): void {
    setSearchId(search?.id ?? null)

    const epc = search?.epcData ? EpcSchema.parse(search.epcData) : null

    const inputs = getModelInputs(
      search,
      epc,
      // NOTE: preserve usage inputs after searching
      search
        ? {
            'usage.current': model.getInputs('usage.current'),
            'usage.upgrade': model.getInputs('usage.upgrade'),
          }
        : undefined,
    )

    model.reset(
      {
        'location': { _location: null },
        'property': { epc },
        'fabric.current': { epc },
        'fabric.upgrade': { epc },
      },
      inputs,
    )
  }

  function reset(): void {
    setSearch(null)
  }

  const context: ModelContext = {
    model,
    searchId,
    saveSearch,
    saveBlock,
    reset,
    ready,
    setReady,
  }

  if (loading) {
    return (
      <Stack direction="column" justifyContent="center" alignItems="center">
        <CircularProgress />
      </Stack>
    )
  }

  return (
    <ModelContext.Provider value={context}>
      {children}
    </ModelContext.Provider>
  )
}

export function useModel(): ModelContext {
  const model = React.useContext(ModelContext)

  if (model === null) {
    throw new Error('BUG: called useModel outside ModelProvider')
  }

  return model
}

export function useModelInputs<K extends ModelKey>(
  key: K,
): Inputs<K> {
  const { model } = useModel()

  const subscribe = React.useCallback(
    (callback: () => void) => model.subscribeInputs(key, callback),
    [model, key],
  )

  return React.useSyncExternalStore(subscribe, () => model.getInputs(key))
}

export function useModelOutputs<K extends ModelKey>(
  key: K,
): Outputs<K> {
  const { model } = useModel()

  const subscribe = React.useCallback(
    (callback: () => void) => model.subscribeOutputs(key, callback),
    [model, key],
  )

  return React.useSyncExternalStore(subscribe, () => model.getOutputs(key))
}

function useModelRef(defaultInputs: InputsInit): React.MutableRefObject<Model> {
  // NOTE: these type gymnastics ensure we don't have to keep dealing with a `null` value that
  // isn't there.
  const model = React.useRef<Model>(null as unknown as Model)

  if (model.current == null) {
    model.current = new Model(
      {
        'location': {
          _location: null,
        },
        'property': {
          epc: null,
        },
        'fabric.current': {
          epc: null,
        },
        'fabric.upgrade': {
          epc: null,
        },
      },
      defaultInputs,
    )
  }

  return model
}
