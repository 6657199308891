// The fabric energy calculator is concerned with the energy use to compensate for heat loss

import type * as locationCalculator from './location'
import type * as fabricCalculator from './fabric'
import type * as usageCalculator from './usage'

export type Dependencies = {
  location: locationCalculator.Outputs
  fabric: fabricCalculator.Outputs
  usage: usageCalculator.Outputs
}

export type Inputs = {
  // NOTE: This fields is unused, but having `{}` as an input type weakens type-checking
  _fabricEnergy: null
}

export type Outputs = {
  peakTempDelta: number

  peakHeatLossPerHour: number

  roofHeatLossPerYear: number
  wallHeatLossPerYear: number
  floorHeatLossPerYear: number
  windowHeatLossPerYear: number
  draughtHeatLossPerYear: number
  totalHeatLossPerYear: number
}

export function getOutputs({ location, fabric, usage }: Dependencies, {}: Inputs): Outputs {
  const peakTempDelta = usage.heatingTemp - location.designTemp

  const peakHeatLossPerHour = peakTempDelta * fabric.totalLossFactor / 1000

  const roofHeatLossPerYear = getHeatLossPerYear({ usage }, fabric.roofLossFactor)

  const wallHeatLossPerYear = getHeatLossPerYear({ usage }, fabric.wallLossFactor)

  const floorHeatLossPerYear = getHeatLossPerYear({ usage }, fabric.floorLossFactor)

  const windowHeatLossPerYear = getHeatLossPerYear({ usage }, fabric.windowLossFactor)

  const draughtHeatLossPerYear = getHeatLossPerYear({ usage }, fabric.draughtLossFactor)

  const totalHeatLossPerYear = getHeatLossPerYear({ usage }, fabric.totalLossFactor)

  return {
    peakTempDelta,
    peakHeatLossPerHour,
    roofHeatLossPerYear,
    wallHeatLossPerYear,
    floorHeatLossPerYear,
    windowHeatLossPerYear,
    draughtHeatLossPerYear,
    totalHeatLossPerYear,
  }
}

function getHeatLossPerYear(
  { usage }: Pick<Dependencies, 'usage'>,
  lossFactor: number,
): number {
  return lossFactor * usage.heatingDegreeDaysPerYear * 24 / 1000
}
