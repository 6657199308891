import z from 'zod'
import { AgeBand, EpcSchema, type Epc, compareEpc } from './common'
import { Address } from '../components/AddressAutocomplete'

const GET_REQUEST_URL = 'https://tap-prod.runtime-prod.digitalsociety.coop/api/apis/4c29aa46-7669-42c7-b5cd-076014e5f24e/results'

const BAND_MAPPING: Record<string, AgeBand> = {
  'before 1919': 'A',
  '1919-1929': 'B',
  '1930-1949': 'C',
  '1950-1964': 'D',
  '1965-1975': 'E',
  '1976-1983': 'F',
  '1984-1991': 'G',
  '1992-1998': 'H',
  '1999-2002': 'I',
  '2003-2007': 'J',
  '2008 onwards': 'K',
}

const EpcRowSchema = EpcSchema.extend({
  'construction-age-band': z.string().transform((val) => {
    const maybeMapped = BAND_MAPPING[val]
    if (maybeMapped) return maybeMapped

    return undefined
  }).optional().catch(undefined),
}) satisfies z.ZodType<Epc, z.ZodTypeDef, unknown>

const EpcResponseSchema = z.object({
  data: z.array(EpcRowSchema),
})

type Config = {
  username: string
  apiKey: string
}

export async function getEpc(
  address: Address,
  { username, apiKey }: Config
): Promise<Epc | null> {
  const url = `${GET_REQUEST_URL}?${new URLSearchParams({
    postcode: address.postcode,
    address1: address.line1.toUpperCase(),
    ...(address.line2 != null ? { address2: address.line2.toUpperCase() } : {}),
  })}`
  const auth = Buffer.from(username + ':' + apiKey).toString('base64')

  const response = await fetch(url, {
    headers: {
      accept: 'application/json',
      authorization: `Basic ${auth}`,
    },
  })

  if (!response.ok) {
    throw new Error(`EPC request failed: ${response.status} - ${response.statusText}`)
  }

  let result: unknown
  try {
    result = await response.json()
  } catch {
    throw new Error('Unexpected Scotland EPC response', { cause: 'Not valid JSON' })
  }

  try {
    const parsed = EpcResponseSchema.parse(result)

    const latest = parsed.data.sort(compareEpc)

    return latest[0] ?? null
  } catch (cause) {
    throw new Error('Unexpected Scotland EPC response', { cause })
  }
}
