'use client'

import { Box, Button, Sheet, Stack, Typography, useTheme } from '@mui/joy'
import useMediaQuery from '@mui/system/useMediaQuery'
import React from 'react'
import { useModel } from './ModelProvider'
import { formatNumber } from '@/lib/formatters'
import { publicUrl } from '@/lib/public'
import PropertyBlock from './PropertyBlock'
import FabricBlock from './FabricBlock'
import HeatingBlock from './HeatingBlock'
import SolarBlock from './SolarBlock'
import UsageBlock from './UsageBlock'

// This sets the block IDs and the order to show them in.
const HOME_BLOCK_IDS = [
  'property',
  'fabric',
  'heating',
  'usage',
  'solar',
] as const

export type HomeBlockId = typeof HOME_BLOCK_IDS[number]

export type VideoHomeBlockId = Exclude<HomeBlockId, 'property'>

export const VIDEO_HOME_BLOCK_IDS = [
  'fabric',
  'heating',
  'usage',
  'solar',
] as const

const HOME_BLOCKS: { [_ in HomeBlockId]: React.ElementType<{ playNextButton?: React.ReactNode }> } = {
  property: PropertyBlock,
  fabric: FabricBlock,
  heating: HeatingBlock,
  usage: UsageBlock,
  solar: SolarBlock,
}

const HOME_BLOCK_STATES = [
  'setup',
  'play',
  'fix',
] as const

export type HomeBlockState = typeof HOME_BLOCK_STATES[number]

const HOME_BLOCK_ACTIONS = [
  'next',
  'back',
] as const

export type StatefulHomeBlockId = HomeBlockId

export type HomeBlockAction = typeof HOME_BLOCK_ACTIONS[number]

export type BlockStateMachine = {
  [CurrentState in HomeBlockState]: {
    [Action in HomeBlockAction]: [StatefulHomeBlockId, HomeBlockState]
  }
}

type BlocksStateMachine<K extends string> = {
  [CurrentId in K]: BlockStateMachine
}

const BLOCK_STATE_MACHINE: BlocksStateMachine<StatefulHomeBlockId> = {
  property: {
    setup: {
      next: ['property', 'fix'],
      back: ['property', 'setup'],
    },
    play: {
      next: ['fabric', 'play'],
      back: ['property', 'fix'],
    },
    fix: {
      next: ['fabric', 'play'],
      back: ['property', 'setup'],
    },
  },
  fabric: {
    setup: {
      next: ['heating', 'play'],
      back: ['property', 'setup'],
    },
    play: {
      next: ['heating', 'play'],
      back: ['fabric', 'fix'],
    },
    fix: {
      next: ['fabric', 'play'],
      back: ['fabric', 'setup'],
    },
  },
  heating: {
    setup: {
      next: ['usage', 'play'],
      back: ['property', 'setup'],
    },
    play: {
      next: ['usage', 'play'],
      back: ['heating', 'fix'],
    },
    fix: {
      next: ['heating', 'play'],
      back: ['heating', 'setup'],
    },
  },
  usage: {
    setup: {
      next: ['usage', 'play'],
      back: ['property', 'setup'],
    },
    play: {
      next: ['fabric', 'play'],
      back: ['usage', 'fix'],
    },
    fix: {
      next: ['usage', 'play'],
      back: ['usage', 'setup'],
    },
  },
  solar: {
    setup: {
      next: ['solar', 'play'],
      back: ['solar', 'setup'],
    },
    play: {
      next: ['solar', 'play'],
      back: ['solar', 'fix'],
    },
    fix: {
      next: ['solar', 'play'],
      back: ['solar', 'play'],
    },
  },
}

type State = { [_ in StatefulHomeBlockId]: HomeBlockState }

export type HomeBlockContext = {
  expandedBlocks: HomeBlockId[]
  showVideo: boolean
  current: StatefulHomeBlockId | null
  setupBlocks: StatefulHomeBlockId[]
  nudged: StatefulHomeBlockId | null
  toggleExpanded: (_: StatefulHomeBlockId) => Promise<void>
}

const HomeBlockContext = React.createContext<HomeBlockContext | null>(null)

export function useHomeBlockContext(): HomeBlockContext {
  const context = React.useContext(HomeBlockContext)
  if (context == null) {
    throw new Error('BUG: called useHomeBlockContext without providing it')
  }

  return context
}

export type HomeBlockStateContext = {
  transition: (action: HomeBlockAction) => Promise<void>
  reset: () => void
  state: HomeBlockState
}

const HomeBlockStateContext = React.createContext<HomeBlockStateContext | null>(null)

export function useHomeBlockStateContext(): HomeBlockStateContext {
  const context = React.useContext(HomeBlockStateContext)
  if (context == null) {
    throw new Error('BUG: called useHomeBlockStateContext without providing it')
  }

  return context
}

const GAP = 1

const INITIAL_STATE: State = {
  property: 'play',
  fabric: 'play',
  heating: 'play',
  usage: 'play',
  solar: 'play',
}

const RESET_STATE: State = {
  property: 'setup',
  fabric: 'setup',
  heating: 'setup',
  usage: 'setup',
  solar: 'setup',
}

export function HomeBlockGrid(props: {}): React.ReactNode
export function HomeBlockGrid<Only extends readonly [...HomeBlockId[]]>(props: {
  only: Only
  stateMachine: BlocksStateMachine<Only[number]>
  expandedBlock?: HomeBlockId
  playNextButtons?: {
    [Id in Only[number]]?: React.ReactNode
  }
}): React.ReactNode
export function HomeBlockGrid({
  only,
  stateMachine = BLOCK_STATE_MACHINE,
  expandedBlock,
  playNextButtons,
}: {
  only?: typeof HOME_BLOCK_IDS
  stateMachine?: BlocksStateMachine<StatefulHomeBlockId>
  expandedBlock?: HomeBlockId
  playNextButtons?: { [Id in StatefulHomeBlockId]?: React.ReactNode }
}): React.ReactNode {
  const model = useModel()
  const { searchId, saveBlock } = model

  const [expandedBlocks, setExpandedBlocks] = React.useState<HomeBlockId[]>(searchId ? ['property'] : expandedBlock != null ? [expandedBlock] : [])
  React.useEffect(() => {
    if (expandedBlock != null) {
      setExpandedBlocks([expandedBlock])
    }
  }, [expandedBlock])

  const [current, setCurrent] = React.useState<StatefulHomeBlockId | null>(null)
  const [nudged, setNudged] = React.useState<StatefulHomeBlockId | null>(searchId ? null : 'usage')
  const [state, setState] = React.useState<State>(INITIAL_STATE)
  const [setupBlocks, setSetupBlocks] = React.useState<StatefulHomeBlockId[]>(
    Object.entries(state)
      .filter(([id, state]) => state === 'setup')
      .map(([id]) => id as StatefulHomeBlockId)
  )

  const showVideo
    = (searchId == null || setupBlocks.length === 0)
    && (expandedBlocks.length === 0 || (expandedBlocks.length === 1 && expandedBlocks.includes('property')))
    && (only == null || VIDEO_HOME_BLOCK_IDS.every(id => only.includes(id)))

  const reset = React.useCallback(() => {
    setState(only == null
      ? RESET_STATE
      : {
          ...INITIAL_STATE,
          ...only.reduce<Partial<State>>((obj, key) => Object.assign(obj, { [key]: 'setup' }), {}),
        })
    setCurrent('property')
    setNudged('property')

    const nextSetupBlocks = Object.entries(RESET_STATE)
      .filter(([id, state]) => id !== 'property' && state === 'setup')
      .map(([id]) => id as StatefulHomeBlockId)
    setSetupBlocks(nextSetupBlocks)
    setExpandedBlocks(['property'])
  }, [only])

  const transition = React.useCallback(async (id: StatefulHomeBlockId, action: HomeBlockAction) => {
    if (setupBlocks.length === 0 && searchId == null && id === 'usage' && state[id] === 'play' && action === 'next') {
      reset()
      return
    }

    const currentState = state[id]
    const [nextCurrent, nextStateForBlock] = stateMachine[id][currentState][action]

    if (id !== 'solar') {
      if (currentState === 'setup' || currentState === 'fix' || id === 'property') {
        await saveBlock(id, 'current', model)
      } else {
        await saveBlock(id, 'upgrade', model)
      }
    }

    const nextState = {
      ...state,
      [id]: nextStateForBlock,
    }
    setState(nextState)

    const nextSetupBlocks = Object.entries(nextState)
      .filter(([id, state]) => id !== 'property' && state === 'setup')
      .map(([id]) => id as StatefulHomeBlockId)
    setSetupBlocks(nextSetupBlocks)

    if (nextCurrent !== id) {
      const next = nextSetupBlocks.length > 0
        ? nextState[nextCurrent] === 'setup'
          ? nextCurrent
          : nextSetupBlocks[0]!
        : nextCurrent
      setCurrent(next)
      setNudged(next)
      setExpandedBlocks([next])
    }
  }, [setupBlocks.length, stateMachine, searchId, reset, state, model, saveBlock])

  const toggleExpanded = React.useCallback(async (id: StatefulHomeBlockId) => {
    const expanded = expandedBlocks.includes(id)

    if (expanded && id !== 'solar') {
      if (state[id] === 'setup' || state[id] === 'fix' || id === 'property') {
        await saveBlock(id, 'current', model)
      } else {
        await saveBlock(id, 'upgrade', model)
      }
    }

    if (setupBlocks.length > 0) {
      if (expanded) {
        setExpandedBlocks([])
        if (state[id] === 'fix' && id !== 'property') {
          await transition(id, 'next')
        }
      } else {
        setExpandedBlocks([id])
        setCurrent(id)
      }
      return
    }

    const set = new Set(expandedBlocks)

    if (expanded) {
      set.delete(id)
    } else {
      set.add(id)
    }

    if (state[id] === 'fix' && id !== 'property' && expanded) {
      await transition(id, 'next')
    }

    setExpandedBlocks([...set])
  }, [expandedBlocks, setupBlocks, state, transition, model, saveBlock])

  const context = {
    expandedBlocks,
    setExpandedBlocks,
    showVideo,
    current,
    setupBlocks,
    nudged,
    toggleExpanded,
  }

  const homeBlocks = HOME_BLOCK_IDS.map((id) => {
    if (only != null && !only.includes(id)) {
      return
    }

    const Block = HOME_BLOCKS[id]

    return (
      <StatefulBlock
        key={id}
        {...{
          transition: async (action: HomeBlockAction) => await transition(id, action),
          reset,
          state: state[id],
        }}
      >
        <Block key={id} playNextButton={playNextButtons?.[id]} />
      </StatefulBlock>
    )
  })

  const propertyHomeBlock = homeBlocks[0]
  const videoHomeBlocks = homeBlocks.slice(1, homeBlocks.length)

  const theme = useTheme()
  const isDesktop = useMediaQuery(theme.breakpoints.up('lg'))

  return (
    <HomeBlockContext.Provider value={context}>
      {isDesktop
        ? (
          <Stack
            direction="row"
            gap={GAP}
            width="calc(100vw - 2 * var(--joy-spacing))"
            overflow="scroll"
            whiteSpace="wrap"
            justifyContent="center"
          >
            <Columns homeBlocks={homeBlocks} />
          </Stack>
          )
        : (
          <Box
            sx={{
              width: 'calc(100% - 2 * var(--joy-spacing))',
            }}
            width="100%"
            justifyContent="center"
          >
            <Stack
              maxWidth={theme => theme.breakpoints.values.sm}
              direction="column"
              gap={GAP}
              flex="1"
              overflow="hidden"
            >
              {propertyHomeBlock}
              {showVideo
                ? (
                  <VideoHomeBlocks>
                    {videoHomeBlocks}
                  </VideoHomeBlocks>
                  )
                : (
                    videoHomeBlocks
                  )}
            </Stack>
          </Box>
          )}
    </HomeBlockContext.Provider>
  )
}

function Columns({ homeBlocks }: { homeBlocks: React.ReactNode[] }): React.ReactNode {
  const { expandedBlocks, showVideo } = useHomeBlockContext()

  const propertyHomeBlock = homeBlocks[0]
  const videoHomeBlocks = homeBlocks.slice(1, homeBlocks.length)

  switch (expandedBlocks.length) {
    case 0: {
      return (
        <>
          <Column>
            {null}
          </Column>
          <Column>
            {propertyHomeBlock}
            {showVideo
              ? (
                <VideoHomeBlocks>
                  {videoHomeBlocks}
                </VideoHomeBlocks>
                )
              : (
                  videoHomeBlocks
                )}
          </Column>
          <Column>
            {null}
          </Column>
        </>
      )
    }
    case 1: {
      if (expandedBlocks.includes('property')) {
        return (
          <>
            <Column>
              {null}
            </Column>
            <Column>
              {propertyHomeBlock}
            </Column>
            <Column>
              {showVideo
                ? (
                  <VideoHomeBlocks>
                    {videoHomeBlocks}
                  </VideoHomeBlocks>
                  )
                : (
                    videoHomeBlocks
                  )}
            </Column>
          </>
        )
      } else {
        const expanded = HOME_BLOCK_IDS.indexOf(expandedBlocks[0]!)
        const firstColumn = homeBlocks.slice(0, expanded)
        const secondColumn = homeBlocks.slice(expanded, homeBlocks.length)
        return (
          <>
            <Column>
              {firstColumn}
            </Column>
            <Column>
              {secondColumn}
            </Column>
            <Column>
              {null}
            </Column>
          </>
        )
      }
    }
    default: {
      const expandedIndexes = [
        ...expandedBlocks.map(id => HOME_BLOCK_IDS.indexOf(id)),
        homeBlocks.length,
      ]
        .filter(index => index !== 0)
        .sort()
      let from = 0
      const columns = expandedIndexes.map((to) => {
        const column = homeBlocks.slice(from, to)
        from = to
        return (
          <Column key={to}>
            {column}
          </Column>
        )
      })
      if (columns.length < 3) {
        if (expandedBlocks[0] === 'property') {
          return [<Column key="empty">{null}</Column>, ...columns]
        }
        return [...columns, <Column key="empty">{null}</Column>]
      }
      return columns
    }
  }
}

function Column({ children }: { children: React.ReactNode }): React.ReactNode {
  return (
    <Stack
      direction="column"
      gap={GAP}
      sx={{ width: { lg: '32%', xl: '27%' }, minWidth: { lg: '32%', xl: '27%' } }}
    >
      {children}
    </Stack>
  )
}

function VideoHomeBlocks({ children }: { children: React.ReactNode }): React.ReactNode {
  const { expandedBlocks } = useHomeBlockContext()

  const gridTemplateRows = VIDEO_HOME_BLOCK_IDS.flatMap(id => [
    '1fr',
    ...expandedBlocks.includes(id) ? ['auto'] : [],
  ]).join(' ')

  return (
    <Box
      display="grid"
      gridTemplateRows={gridTemplateRows}
      sx={{ gridTemplateColumns: '1fr 2fr' }}
    >
      <Box
        display="block"
        position="relative"
        gridColumn="1"
        gridRow="1 / 5"
        borderRadius="xs"
        overflow="hidden"
        sx={{ borderTopRightRadius: 0, borderBottomRightRadius: 0 }}
      >
        <video
          autoPlay
          muted
          loop
          playsInline
          style={{
            position: 'absolute',
            inset: 0,
            width: '100%',
            height: '100%',
            objectFit: 'cover',
          }}
        >
          <source src={publicUrl('/home_tall.mp4')} type="video/mp4" />
          Your browser does not support the video tag.
        </video>
      </Box>
      {children}
    </Box>
  )
}

function StatefulBlock({ children, ...stateContext }: { children: React.ReactNode } & HomeBlockStateContext): React.ReactNode {
  return (
    <HomeBlockStateContext.Provider value={stateContext}>
      {children}
    </HomeBlockStateContext.Provider>
  )
}

export function VideoHomeBlock({
  id,
  media,
  title,
  instruction,
  savings,
  expansion,
}: {
  id: Exclude<StatefulHomeBlockId, 'property'>
  media: React.ReactNode
  title: React.ReactNode
  instruction: React.ReactNode
  savings?: number
  expansion: React.ReactNode
}): React.ReactNode {
  const { ready: modelReady } = useModel()
  const { expandedBlocks, current, toggleExpanded, nudged } = useHomeBlockContext()
  const ref = React.useRef<HTMLElement>(null)

  React.useEffect(() => {
    if (ref.current && current === id) {
      ref.current.scrollIntoView(true)
    }
  }, [current, id])

  const { state } = useHomeBlockStateContext()

  const expanded = expandedBlocks.includes(id)

  return (
    <VideoHomeBlockBase
      ref={ref}
      id={id}
      media={state === 'setup' ? <Box sx={{ opacity: 0.5, height: '100%' }}>{media}</Box> : media}
      title={title}
      content={instruction}
      endRow={(!expanded && state === 'setup' && nudged === id
        ? (
          <Button
            size="sm"
            color="primary"
            onClick={async () => await toggleExpanded(id)}
            disabled={!modelReady}
          >
            Start
          </Button>
          )
        : (
          <Stack direction="row" justifyContent="space-between" alignItems="center">
            <Typography level="title-sm">
              {savings != null && <HomeBlockSavings id={id} state={state} savings={savings} />}
            </Typography>
            {modelReady && (
              <Button
                size="sm"
                variant="plain"
                sx={{ alignSelf: 'flex-end', minHeight: 0, padding: 0.5 }}
                color="primary"
                onClick={async () => await toggleExpanded(id)}
              >
                {expanded ? 'Close' : state === 'setup' ? 'Start' : 'Explore savings'}
              </Button>
            )}
          </Stack>
          )
      )}
      expansion={expansion}
    />
  )
}

type VideoHomeBlockBaseProps = {
  id: VideoHomeBlockId
  media: React.ReactNode
  title: React.ReactNode
  content: React.ReactNode
  endRow: React.ReactNode
  expansion?: React.ReactNode
}

export const VideoHomeBlockBase = React.forwardRef<HTMLElement, VideoHomeBlockBaseProps>(function HomeBlockBase(props, ref): React.ReactNode {
  const { showVideo } = useHomeBlockContext()

  return showVideo
    ? <VideoHomeBlockWithVideo {...props} />
    : <VideoHomeBlockWithImage ref={ref} {...props} />
})

function VideoHomeBlockWithVideo({
  id,
  title,
  content,
  endRow,
}: {
  id: VideoHomeBlockId
  title: React.ReactNode
  content: React.ReactNode
  endRow: React.ReactNode
}): React.ReactNode {
  const { nudged } = useHomeBlockContext()

  const gridRow = HOME_BLOCK_IDS.indexOf(id)

  return (
    <>
      <Box
        tabIndex={-1}
        position="relative"
        gridColumn="1"
        gridRow={gridRow}
        border="0 solid var(--joy-palette-background-body)"
        overflow="hidden"
        sx={{
          '&:before': {
            content: '""',
            float: 'left',
            width: '1px',
            height: 0,
            marginLeft: '-1px',
            paddingTop: '100%',
          },
          '&:after': {
            content: '""',
            display: 'table',
            clear: 'both',
          },
          'borderBottomWidth': `calc(${GAP} * var(--joy-spacing))`,
        }}
      />
      <Sheet
        variant={nudged === id ? 'glowing' : 'outlined'}
        sx={{
          gridColumn: '2',
          gridRow,
          marginBottom: GAP,
          borderRadius: 'xs',
          borderLeftWidth: 0,
          borderTopLeftRadius: 0,
          borderBottomLeftRadius: 0,
          boxShadow: 'none',
        }}
      >
        <VideoHomeBlockInformation title={title} content={content} endRow={endRow} />
      </Sheet>
    </>
  )
}

const VideoHomeBlockWithImage = React.forwardRef<HTMLElement, VideoHomeBlockBaseProps>(function HomeBlockBase({
  id,
  media,
  title,
  content,
  endRow,
  expansion,
}, ref): React.ReactNode {
  const { nudged, expandedBlocks } = useHomeBlockContext()

  const expanded = expandedBlocks.includes(id)

  return (
    <Box
      ref={ref}
      component={Sheet}
      variant={nudged === id ? 'glowing' : 'outlined'}
      sx={{
        display: 'flex',
        flexDirection: 'column',
        borderRadius: 'xs',
        boxShadow: 'none',
      }}
    >
      <Stack direction="row">
        <Box flex="1">
          {media}
        </Box>
        <Box flex="2">
          <VideoHomeBlockInformation title={title} content={content} endRow={endRow} />
        </Box>
      </Stack>
      {expanded && expansion}
    </Box>
  )
})

function VideoHomeBlockInformation({
  title,
  content,
  endRow,
}: {
  title: React.ReactNode
  content: React.ReactNode
  endRow: React.ReactNode
}): React.ReactNode {
  return (
    <Stack
      padding={1.5}
      height="100%"
      direction="column"
      gap={0.5}
    >
      <Typography
        sx={theme => ({
          typography: 'title-sm',
          [theme.breakpoints.up('md')]: {
            typography: 'title-md',
          },
        })}
      >
        {title}
      </Typography>
      <Stack direction="column" flex={1} gap={0.5} alignItems="stretch">
        <Typography
          flex={4}
          component="div"
          sx={theme => ({
            [theme.breakpoints.down('md')]: {
              typography: 'body-sm',
              color: theme.vars.palette.text.secondary,
            },
            [theme.breakpoints.up('md')]: {
              typography: 'body-md',
              color: theme.vars.palette.text.secondary,
            },
          })}
        >
          {typeof content === 'string'
            ? <Typography>{content}</Typography>
            : content}
        </Typography>
        {endRow}
      </Stack>
    </Stack>
  )
}

function HomeBlockSavings({
  id,
  state,
  savings,
}: {
  id: HomeBlockId
  state: HomeBlockState
  savings: number
}): React.ReactNode {
  if (state !== 'play') {
    return null
  }

  const [text, level] = id === HOME_BLOCK_IDS[0]
    ? ['Total savings', 'title-md' as const]
    : ['Savings', 'title-sm' as const]

  return (
    <>
      <Typography textColor={savings < 0 ? 'var(--joy-palette-danger-500)' : 'var(--joy-palette-neutral-900)'}>
        {`${text}:`}
      </Typography>
      {' '}
      <Typography
        textColor={savings < 0 ? 'var(--joy-palette-danger-500)' : 'var(--joy-palette-neutral-900)'}
        level={level}
      >
        {formatNumber(savings, 'currency')}
      </Typography>
    </>
  )
}
