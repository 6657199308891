'use client'

import React from 'react'

export default function useLocalStorage(key: string): {
  value: string | null
  setValue: (value: string | null) => void
  ready: boolean
} {
  const [ready, setReady] = React.useState<boolean>(false)
  const [value, setValue_] = React.useState<string | null>(null)

  const listener = React.useCallback(
    (event: StorageEvent): void => {
      if (event.key === key) {
        setValue_(localStorage.getItem(key))
      }
    },
    [key]
  )

  React.useEffect(() => {
    setValue_(localStorage.getItem(key))
    setReady(true)

    window.addEventListener('storage', listener)

    return (): void => {
      window.removeEventListener('storage', listener)
    }
  }, [key, listener])

  const setValue = (value: string | null): void => {
    if (value === null) {
      localStorage.removeItem(key)
    } else {
      localStorage.setItem(key, value)
    }
    setValue_(value)
    window.dispatchEvent(new StorageEvent('storage', { key }))
  }

  return {
    value,
    setValue,
    ready,
  }
}
