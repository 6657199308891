export class Graph<K> {
  #edges: Map<K, K[]>

  constructor(edges: [K, K[]][] = []) {
    // TODO: we should check if `edges` describes a DAG
    this.#edges = new Map(edges)
  }

  edges(key: K): K[] {
    return this.#edges.get(key) ?? []
  }

  invert(): Graph<K> {
    const inverted = new Graph<K>()

    for (const [key, edges] of this.#edges) {
      for (const edge of edges) {
        if (!inverted.#edges.has(edge)) {
          inverted.#edges.set(edge, [])
        }
        inverted.#edges.get(edge)!.push(key)
      }
    }

    return inverted
  }

  reachable(key: K): Graph<K> {
    const output = new Graph<K>([
      [key, this.#edges.get(key) ?? []],
    ])

    for (const edge of this.#edges.get(key) ?? []) {
      output.#edges.set(edge, this.#edges.get(edge) ?? [])
    }

    return output
  }

  topologicalSort(): K[] {
    const inDegrees: Map<K, number> = new Map()
    for (const [key, edges] of this.#edges) {
      if (!inDegrees.has(key)) {
        inDegrees.set(key, 0)
      }

      for (const edge of edges) {
        inDegrees.set(edge, (inDegrees.get(edge) ?? 0) + 1)
      }
    }

    const leaves = Array.from(inDegrees.entries())
      .filter(([, degree]) => degree === 0)
      .map(([key]) => key)

    const sorted: K[] = []
    while (leaves.length > 0) {
      const current = leaves.shift()!

      sorted.push(current)

      for (const edge of this.#edges.get(current) ?? []) {
        const degree = inDegrees.get(edge)
        if (degree != null && degree > 0) {
          const newDegree = degree - 1

          inDegrees.set(edge, newDegree)

          if (newDegree === 0) {
            leaves.push(edge)
          }
        }
      }
    }

    return sorted
  }
}
