// The fabric component of the model is concerned with insulation and heat loss.

import type { Epc } from '@/lib/epc'
import type * as propertyCalculator from './property'

const AIR_HEAT_CAPACITY = 0.342

export type Dependencies = {
  epc: Epc | null
  property: propertyCalculator.Outputs
}

export type Inputs = {
  [K in keyof FabricLevels]: FabricLevels[K]
}

export type Outputs = {
  roofLossFactor: number
  wallLossFactor: number
  floorLossFactor: number
  windowLossFactor: number
  draughtLossFactor: number
  totalLossFactor: number
}

export function getOutputs(
  { property }: Dependencies,
  inputs: Inputs,
): Outputs {
  const roofLossFactor = property.roofArea * inputs.roofU

  const wallLossFactor = property.exteriorWallMinusWindowArea * inputs.wallU

  const floorLossFactor = property.groundFloorArea * inputs.floorU

  const windowLossFactor = property.windowArea * inputs.windowU

  const draughtLossFactor = AIR_HEAT_CAPACITY * property.airVolume * inputs.infiltration

  const totalLossFactor = roofLossFactor + wallLossFactor + floorLossFactor + windowLossFactor + draughtLossFactor

  return {
    roofLossFactor,
    wallLossFactor,
    floorLossFactor,
    windowLossFactor,
    draughtLossFactor,
    totalLossFactor,
  }
}

export type FabricLevels = {
  [K in keyof typeof FABRIC_LEVELS]: typeof FABRIC_LEVELS[K][number]
}

// NOTE: this is a bit redundant, but it needed to build types through mapped tuples
export type FabricLevelValues = {
  [K in keyof typeof FABRIC_LEVELS]: typeof FABRIC_LEVELS[K]
}

const FABRIC_LEVELS = {
  roofU: [0.15, 0.3, 1, 2],
  wallU: [0.2, 0.35, 0.5, 1.1, 2],
  floorU: [0.2, 0.6, 1],
  windowU: [1.2, 1.8, 3, 4, 6],
  infiltration: [0.25, 0.5, 0.75, 1.0],
} as const
