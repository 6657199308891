import * as fabricCalculator from '@/lib/calculators/fabric'
import * as heatingCalculator from '@/lib/calculators/heating'
import * as locationCalculator from '@/lib/calculators/location'
import * as propertyCalculator from '@/lib/calculators/property'
import * as usageCalculator from '@/lib/calculators/usage'
import type { Epc, EpcEfficiencyEnum } from '@/lib/epc'
import { InputsInit } from '@/lib/model'
import { DEFAULT_LOCATION_INPUTS } from '@/app/default-location'
import { Fabric, Heating, Property, Usage } from '@prisma/client'
import { SearchWithBlockInputs } from './actions'
import { HEATER_PARSER } from './HeatingBlock'

export function getModelInputs(
  search: SearchWithBlockInputs | null = null,
  epc: Epc | null = null,
  previousUsage?: Pick<InputsInit, 'usage.current' | 'usage.upgrade'>,
): InputsInit {
  if (search == null) {
    return DEFAULT_INPUTS
  }

  const property = getPropertyInputs(epc, search.property)
  const location = {
    address: search.address,
    postcode: search.postcode,
    latitude: search.latitude,
    longitude: search.longitude,
  }
  const currentPersistedFabric = search.fabrics.find(fabric => fabric.instance === 'current')
  const upgradePersistedFabric = search.fabrics.find(fabric => fabric.instance === 'upgrade')
  const currentFabric = getFabricInputs(epc, property, currentPersistedFabric)

  const currentPersistedHeating = search.heatings.find(fabric => fabric.instance === 'current')
  const upgradePersistedHeating = search.heatings.find(fabric => fabric.instance === 'upgrade')
  const currentHeating = getHeatingInputs(property, currentPersistedHeating)

  const currentPersistedUsage = search.usages.find(fabric => fabric.instance === 'current')
  const upgradePersistedUsage = search.usages.find(fabric => fabric.instance === 'upgrade')
  const currentUsage = currentPersistedUsage
    ? getUsageInputs(currentPersistedUsage)
    : previousUsage?.['usage.current'] ?? DEFAULT_INPUTS['usage.current']

  const { waterUnitCost } = locationCalculator.getOutputs({ _location: null }, location)
  const usageCost = { waterMeter: waterUnitCost > 0 }

  return {
    ...DEFAULT_INPUTS,
    location,
    property,
    'fabric.current': currentFabric,
    'fabric.upgrade': upgradePersistedFabric == null
      ? currentFabric
      : getFabricInputs(epc, property, upgradePersistedFabric),
    'heating.current': currentHeating,
    'heating.upgrade': upgradePersistedHeating == null
      ? currentHeating
      : getHeatingInputs(property, upgradePersistedHeating),
    'usage.current': currentUsage,
    'usage.upgrade': upgradePersistedUsage
      ? getUsageInputs(upgradePersistedUsage)
      : currentPersistedUsage
        ? currentUsage
        : previousUsage?.['usage.upgrade'] ?? DEFAULT_INPUTS['usage.upgrade'],
    'usageCost.current': usageCost,
    'usageCost.upgrade': usageCost,
  }
}

const DEFAULT_INPUTS: InputsInit = {
  'location': DEFAULT_LOCATION_INPUTS,
  'property': {
    propertyType: 'detached',
    buildYear: 1964,
    floorArea: 100,
    ceilingHeight: 2.5,
    floorCount: 2,
  },
  'fabric.current': {
    infiltration: 0.75,
    roofU: 2,
    wallU: 1.1,
    floorU: 0.6,
    windowU: 6,
  },
  'fabric.upgrade': {
    infiltration: 0.25,
    roofU: 0.15,
    wallU: 0.5,
    floorU: 0.6,
    windowU: 1.8,
  },
  'fabricEnergy.current': {
    _fabricEnergy: null,
  },
  'fabricEnergy.upgrade': {
    _fabricEnergy: null,
  },
  'fabricEnergyCost.current': {
    _fabricEnergyCost: null,
  },
  'fabricEnergyCost.upgrade': {
    _fabricEnergyCost: null,
  },
  'heating.current': {
    heater: 'combiBoiler',
    flowTemp: 60,
    radiatorCount: 10,
    // NOTE: this assumes the translation of heater -> tariff type and the fallback behaviour defined in the calculator
    tariff: Object.keys(heatingCalculator.TARIFFS['gas'])[0]!,
  },
  'heating.upgrade': {
    heater: 'heatPump',
    flowTemp: 50,
    radiatorCount: 10,
    // NOTE: this assumes the translation of heater -> tariff type and the fallback behaviour defined in the calculator
    tariff: Object.keys(heatingCalculator.TARIFFS['heatPump'])[0]!,
  },
  'heatingEnergyCost.current': {
    _heatingEnergyCost: null,
  },
  'heatingEnergyCost.upgrade': {
    _heatingEnergyCost: null,
  },
  'usage.current': {
    bathCountPerWeek: 1,
    showerHeadFlowRate: 12,
    showerDuration: 6,
    showerCountPerDay: 4,
    heatingOnMonth: 10,
    heatingOffMonth: 5,
    heatingTemp: 20,
  },
  'usage.upgrade': {
    bathCountPerWeek: 1,
    showerHeadFlowRate: 6,
    showerDuration: 4,
    showerCountPerDay: 4,
    heatingOnMonth: 10,
    heatingOffMonth: 5,
    heatingTemp: 20,
  },
  'usageCost.current': {
    waterMeter: true,
  },
  'usageCost.upgrade': {
    waterMeter: true,
  },
  'solar.current': {
    roofType: 'pitched',
    roofSlope: 35,
    roofAzimuth: 0,
    solarPanelCount: 0,
  },
  'solar.upgrade': {
    roofType: 'pitched',
    roofSlope: 35,
    roofAzimuth: 0,
    solarPanelCount: 10,
  },
}

function getPropertyInputs(epc: Epc | null, persisted: Property | null): propertyCalculator.Inputs {
  if (persisted != null) return {
    ...persisted,
    propertyType: propertyCalculator.PropertyTypeSchema.parse(persisted.propertyType),
  }

  if (epc == null) return DEFAULT_INPUTS.property

  const propertyType = getPropertyPropertyTypeInput(epc)

  return {
    propertyType,
    buildYear: getPropertyBuildYearInput(epc),
    floorArea: getPropertyFloorAreaInput(epc),
    ceilingHeight: getPropertyCeilingHeightInput(epc),
    floorCount: getPropertyFloorCountInput(propertyType),
  }
}

function getPropertyPropertyTypeInput(epc: Epc): propertyCalculator.PropertyType {
  switch (epc['property-type']) {
    case 'Bungalow':
      return 'bungalow'
    case 'Flat':
      return 'flat'
  }

  switch (epc?.['built-form']) {
    case 'Detached':
      return 'detached'
    case 'Semi-Detached':
      return 'semiDetached'
    case 'Mid-Terrace':
    case 'Enclosed Mid-Terrace':
      return 'terrace'
    case 'End-Terrace':
    case 'Enclosed End-Terrace':
      return 'endTerrace'
  }

  return DEFAULT_INPUTS.property.propertyType
}

function getPropertyBuildYearInput(epc: Epc): number {
  switch (epc['construction-age-band']) {
    case 'A':
      return 1904
    case 'B':
      return 1914
    case 'C':
      return 1944
    case 'D':
      return 1954
    case 'E':
      return 1974
    case 'F':
      return 1974
    case 'G':
      return 1984
    case 'H':
      return 1994
    case 'I':
      return 1994
    case 'J':
      return 2004
    case 'K':
      return 2004
    case 'L':
      return 2014
    default:
      return DEFAULT_INPUTS.property.buildYear
  }
}

function getPropertyFloorAreaInput(epc: Epc): number {
  return epc['total-floor-area'] ?? DEFAULT_INPUTS.property.floorArea
}

function getPropertyCeilingHeightInput(epc: Epc): number {
  return epc['floor-height'] ?? DEFAULT_INPUTS.property.ceilingHeight
}

export function getPropertyFloorCountInput(propertyType: string): number {
  return ['bungalow', 'flat'].includes(propertyType) ? 1 : 2
}

function getFabricInputs(
  epc: Epc | null,
  { buildYear }: propertyCalculator.Inputs,
  persisted?: Fabric
): fabricCalculator.Inputs {
  if (persisted != null) return {
    infiltration: persisted.infiltration as fabricCalculator.FabricLevels['infiltration'],
    roofU: persisted.roofU as fabricCalculator.FabricLevels['roofU'],
    wallU: persisted.wallU as fabricCalculator.FabricLevels['wallU'],
    floorU: persisted.floorU as fabricCalculator.FabricLevels['floorU'],
    windowU: persisted.windowU as fabricCalculator.FabricLevels['windowU'],
  }

  return {
    roofU: getFabricInput('roofU', epc?.['roof-energy-eff'], buildYear),
    wallU: getFabricInput('wallU', epc?.['walls-energy-eff'], buildYear),
    floorU: getFabricInput('floorU', epc?.['floor-energy-eff'], buildYear),
    windowU: getFabricInput('windowU', epc?.['windows-energy-eff'], buildYear),
    infiltration: getFabricInput('infiltration', undefined, buildYear),
  }
}

function getFabricInput<K extends keyof fabricCalculator.FabricLevels>(
  field: K,
  energyEff: EpcEfficiencyEnum,
  buildYear: number,
): fabricCalculator.FabricLevels[K] {
  const epcEffMapping = EPC_EFF_MAPPING[field]
  if (epcEffMapping != null && energyEff != null) {
    return epcEffMapping[energyEff]
  }

  const epcAgeMapping = EPC_AGE_MAPPING[field]
  const epcAgeMapped = epcAgeMapping.find(([max]) => buildYear <= max)
  if (epcAgeMapped != null) {
    return epcAgeMapped[1]
  }

  return epcAgeMapping[epcAgeMapping.length - 1]![1]
}

function getHeatingInputs({ floorArea }: propertyCalculator.Inputs, persisted?: Heating): heatingCalculator.Inputs {
  if (persisted != null) return {
    ...persisted,
    heater: HEATER_PARSER.parse(persisted.heater),
    tariff: persisted.tariff,
  }

  return {
    ...DEFAULT_INPUTS['heating.current'],
    radiatorCount: getHeatingRadiatorCountInput(floorArea),
  }
}

export function getHeatingRadiatorCountInput(floorArea: number): number {
  return Math.min(
    Math.round(floorArea / FLOOR_AREA_PER_RADIATOR),
    MAX_RADIATORS,
  )
}

function getUsageInputs(persisted: Usage): usageCalculator.Inputs {
  return {
    ...persisted,
    heatingOnMonth: persisted.heatingOnMonth as usageCalculator.HeatingMonth,
    heatingOffMonth: persisted.heatingOffMonth as usageCalculator.HeatingMonth,
  }
}

const EPC_EFF_MAPPING: {
  [K in keyof fabricCalculator.FabricLevels]?: {
    [E in NonNullable<EpcEfficiencyEnum>]: fabricCalculator.FabricLevels[K]
  }
} = {
  roofU: {
    'Very Poor': 2,
    'Poor': 1,
    'Average': 1,
    'Good': 0.3,
    'Very Good': 0.15,
  },
  wallU: {
    'Very Poor': 2,
    'Poor': 2,
    'Average': 1.1,
    'Good': 0.35,
    'Very Good': 0.2,
  },
  floorU: {
    'Very Poor': 1,
    'Poor': 0.6,
    'Average': 0.6,
    'Good': 0.2,
    'Very Good': 0.2,
  },
  windowU: {
    'Very Poor': 6,
    'Poor': 4,
    'Average': 3,
    'Good': 3,
    'Very Good': 1.2,
  },
} as const

const EPC_AGE_MAPPING: {
  [K in keyof fabricCalculator.FabricLevels]: [number, fabricCalculator.FabricLevels[K]][]
} = {
  roofU: [
    [1975, 2],
    [2002, 1],
    [2011, 0.3],
    [9999, 0.15],
  ],
  wallU: [
    [1975, 2],
    [1990, 1.1],
    [2002, 0.5],
    [2011, 0.35],
    [9999, 0.2],
  ],
  floorU: [
    [1982, 1],
    [2011, 0.6],
    [9999, 0.2],
  ],
  windowU: [
    [1990, 6],
    [2002, 4],
    [2006, 3],
    [9999, 1.8],
  ],
  infiltration: [
    [1929, 1.0],
    [1966, 0.75],
    [2002, 0.5],
    [9999, 0.25],
  ],
} as const

export const MAX_RADIATORS = 20

const FLOOR_AREA_PER_RADIATOR = 10
