'use client'
import React from 'react'
import { Autocomplete, AutocompleteOption } from '@mui/joy'
import { Place } from '@mui/icons-material'
import { z } from 'zod'
import { SxProps } from '@mui/joy/styles/types'
import { getEnvKey } from '../env'

const API_KEY = getEnvKey('NEXT_PUBLIC_GETADDRESS_API_KEY')

const SuggestionSchema = z.object({
  id: z.string(),
  address: z.string(),
})

const SuggestionResponseSchema = z.object({
  suggestions: z.array(SuggestionSchema),
})

export type Suggestion = z.infer<typeof SuggestionSchema>

async function getSuggestions(term: string): Promise<Array<Suggestion>> {
  const response = await fetch(`https://api.getAddress.io/autocomplete/${term}?api-key=${API_KEY}`)

  if (!response.ok) {
    throw new Error('Something went wrong, please try again later!')
  }

  let json: unknown
  try {
    json = await response.json()
  } catch {
    throw new Error('Something went wrong, please try again later!')
  }

  const suggestionsResponse = SuggestionResponseSchema.parse(json)

  return suggestionsResponse.suggestions
}

const GetAddressSchema = z.object({
  formatted_address: z.array(z.string()),
  line_1: z.string(),
  line_2: z.string(),
  postcode: z.string(),
  latitude: z.number(),
  longitude: z.number(),
})

type GetAddress = z.infer<typeof GetAddressSchema>

async function getGetAddress(id: string): Promise<GetAddress> {
  const response = await fetch(`https://api.getAddress.io/get/${id}?api-key=${API_KEY}`)

  if (!response.ok) {
    throw new Error('Something went wrong, please try again later!')
  }

  let json: unknown
  try {
    json = await response.json()
  } catch {
    throw new Error('Something went wrong, please try again later!')
  }

  return GetAddressSchema.parse(json)
}

export type Address = {
  address: string
  line1: string
  line2: string | null
  postcode: string
  latitude: number
  longitude: number
}

export async function getAddress(id: string): Promise<Address> {
  const {
    postcode,
    latitude,
    longitude,
    formatted_address,
    line_1,
    line_2,
  } = await getGetAddress(id)
  return {
    address: formatted_address.filter(v => v !== '').join(', '),
    postcode,
    latitude,
    longitude,
    line1: line_1,
    line2: line_2 !== '' ? line_2 : null,
  }
}

export default function AddressAutocomplete({
  value,
  setValue,
  setError,
  size,
  sx,
  placeholder,
}: {
  value: Suggestion | null
  setValue: (value: Suggestion | null) => void
  setError: (error: string | null) => void
  size?: 'lg' | 'sm' | 'md'
  sx?: SxProps
  placeholder?: string
}): React.ReactNode {
  const [open, setOpen] = React.useState(false)
  const [loading, setLoading] = React.useState<boolean>(false)
  const [inputValue, setInputValue] = React.useState<string>('')
  const [options, setOptions] = React.useState<Array<Suggestion>>([])

  React.useEffect(() => {
    if (inputValue !== '') {
      setLoading(true)
      const debouncedGetSuggestion = setTimeout(() => {
        getSuggestions(inputValue)
          .then((v) => {
            setOptions(v)
            setLoading(false)
          })
          .catch((e) => {
            setError(String(e))
            setLoading(false)
          })
      }, 500)

      return () => clearTimeout(debouncedGetSuggestion)
    } else {
      setValue(null)
      setOptions([])
    }
  }, [inputValue, setError, setValue])

  return (
    <Autocomplete
      sx={sx}
      placeholder={placeholder}
      size={size}
      value={value}
      onChange={(_, v) => {
        if (v != null) {
          setValue(v)
          setInputValue(v.address)
        }
      }}
      inputValue={inputValue}
      onInputChange={(_e, v) => setInputValue(v)}
      startDecorator={<Place />}
      open={open}
      onOpen={() => {
        setOpen(true)
      }}
      onClose={() => {
        setOpen(false)
      }}
      isOptionEqualToValue={(option, value) => option.id === value.id}
      getOptionKey={option => option.id}
      getOptionLabel={option => option.address}
      clearOnBlur={false}
      loading={loading}
      options={options}
      renderOption={(props, option) => (
        <AutocompleteOption {...props} key={option.id}>
          {option.address}
        </AutocompleteOption>
      )}
    />
  )
}
