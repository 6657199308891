'use client'
import React from 'react'
import { Button, FormControl, FormHelperText, Stack, Typography } from '@mui/joy'
import AddressAutocomplete, { Address, Suggestion, getAddress } from '@/lib/components/AddressAutocomplete'
import { sendGTMEvent } from '@next/third-parties/google'

export default function AddressSelector({
  submit,
}: {
  submit: (address: Address) => Promise<void>
}): React.ReactNode {
  const [error, setError] = React.useState<string | null>(null)
  const [submitting, setSubmitting] = React.useState<boolean>(false)
  const [value, setValue] = React.useState<Suggestion | null>(null)

  async function handleSubmit(event: React.FormEvent<HTMLFormElement>): Promise<void> {
    event.preventDefault()
    setSubmitting(true)

    let address: Address
    if (value != null) {
      try {
        address = await getAddress(value.id)
      } catch (e) {
        setError(String(e))
        setSubmitting(false)
        return
      }
    } else {
      // find button disabled so this never happens
      return
    }

    try {
      sendGTMEvent({ event: 'select_content', content_type: 'address_search', content_id: address.postcode })
    } catch (e) {
      console.error('Failed to send GA event', e)
    }

    try {
      await submit(address)
    } catch (e) {
      setError(
        e instanceof Error
          ? e.message
          : String(e)
      )
    }

    setSubmitting(false)
  }

  return (
    <form
      onSubmit={handleSubmit}
      style={{
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <FormControl error={error !== null}>
        <Stack flexDirection="column" gap={1.5}>
          <Typography level="title-lg" textAlign="center">
            Enter your address
          </Typography>
          <AddressAutocomplete
            value={value}
            setValue={setValue}
            setError={setError}
            size="sm"
            placeholder="Start typing your address..."
            sx={{ flex: 1 }}
          />
          <Button
            size="sm"
            type="submit"
            loading={submitting}
            disabled={value == null}
            sx={{ alignSelf: 'center', width: 210 }}
          >
            Find
          </Button>
        </Stack>
        <FormHelperText>{error}</FormHelperText>
      </FormControl>
    </form>
  )
}
