import Image from 'next/image'
import { publicUrl } from './public'

const ICONS = {
  coins: '/icons/coins.svg',
  energybill: '/icons/energybill.svg',
  funding: '/icons/funding.svg',
  gasburner: '/icons/gasburner.svg',
  heatpump: '/icons/heatpump.svg',
  house: '/icons/house.svg',
  lightningbolt: '/icons/lightningbolt.svg',
  loftinsulation: '/icons/loftinsulation.svg',
  money: '/icons/money.svg',
  orangutan: '/icons/orangutan.svg',
  mcs: '/icons/mcs.webp',
  radiator: '/icons/radiator.svg',
  snowflake: '/icons/snowflake.svg',
  spanner: '/icons/spanner.svg',
  thermostat: '/icons/thermostat.svg',
  wallinsulation: '/icons/wallinsulation.svg',
  watertank: '/icons/watertank.svg',
  window: '/icons/window.svg',
  windsock: '/icons/windsock.svg',
}

export default function Icon({
  icon,
  width,
  height,
}: {
  icon: keyof typeof ICONS
  width?: number
  height?: number
}): React.ReactNode {
  return (
    <Image
      src={publicUrl(ICONS[icon])}
      width={width ?? 24}
      height={height ?? 24}
      alt={icon}
    />
  )
}
