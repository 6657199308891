import Icon from '@/lib/Icon'
import { formatNumber } from '@/lib/formatters'
import { Box, Button, Sheet, Stack, Typography, Tooltip } from '@mui/joy'
import React from 'react'
import { useHomeBlockContext, useHomeBlockStateContext } from './HomeBlock'
import { publicUrl } from '@/lib/public'

const MAX_SUMMARY_CHANGES = 2

export function UpgradeSummary({ upgrades }: {
  upgrades: string[]
}): React.ReactNode {
  return (
    <ul style={{ margin: 0, paddingLeft: 20 }}>
      {upgrades.slice(0, MAX_SUMMARY_CHANGES).map(upgrade => (
        <li key={upgrade} style={{ margin: 0 }}>
          <Typography level="body-sm">{upgrade}</Typography>
        </li>
      ))}
    </ul>
  )
}

export type TabType = 'upgrade' | 'current'

type SubheadingRow = {
  current: string
  upgrade: string
}

function isSubheadingRow(row: { current: unknown, upgrade: unknown }): row is SubheadingRow {
  return typeof row.current === 'string' && typeof row.upgrade === 'string'
}

export type UpgradeableRowContent = {
  icon: React.ComponentProps<typeof Icon>['icon']
  label: string
  education?: React.ReactNode
  current: React.ReactNode | null
  upgrade: React.ReactNode | null
} | {
  current: string
  upgrade: string
}

export function UpgradeableExpansion({
  rows,
  ...props
}: {
  rows: UpgradeableRowContent[]
} & (
  | { playNextText: string }
  | { playNextButton: React.ReactNode }
)): React.ReactNode {
  const { state, transition } = useHomeBlockStateContext()
  const { setupBlocks } = useHomeBlockContext()

  let nextButton: React.ReactNode

  switch (state) {
    case 'setup':
      nextButton = setupBlocks.length > 1
        ? 'Continue setup'
        : 'Calculate savings'
      break
    case 'play':
      nextButton = setupBlocks.length > 0
        ? 'Continue setup'
        : ('playNextText' in props ? props.playNextText : props.playNextButton)
      break
    case 'fix':
      nextButton = 'Return to savings'
      break
  }

  return (
    <>
      <Sheet
        component={Stack}
        variant={state === 'play' ? 'plain' : 'soft'}
        color={state === 'play' ? 'primary' : 'warning'}
        sx={{ padding: 1.5, borderRadius: 0 }}
      >
        <Table>
          {rows.map((row, i) => (
            <UpgradeableRow
              key={i}
              tab={state === 'play' ? 'upgrade' : 'current'}
              row={row}
              isFirstRow={state === 'play' ? i === 0 : false}
            />
          ))}
        </Table>
        {typeof nextButton === 'string'
          ? (
            <Button
              onClick={async () => await transition('next')}
              variant={state === 'play' ? 'soft' : 'solid'}
              color={state === 'play' ? 'primary' : 'warning'}
              sx={{ marginY: 1 }}
            >
              {nextButton}
            </Button>
            )
          : nextButton}
      </Sheet>
    </>
  )
}

function UpgradeableRow({ tab, row, isFirstRow }: {
  key: number
  tab: TabType
  row: UpgradeableRowContent
  isFirstRow: boolean
}): React.ReactNode {
  return isSubheadingRow(row)
    ? <Subheading label={row[tab]} isFirstRow={isFirstRow} />
    : row[tab] != null
      ? <Row icon={row.icon} label={row.label} education={row.education}>{row[tab]}</Row>
      : null
}

export function Row({
  label,
  icon,
  children,
  education,
  focus,
}: {
  label: string
  icon: React.ComponentProps<typeof Icon>['icon']
  children: React.ReactNode
  education?: React.ReactNode
  focus?: boolean
}): React.ReactNode {
  const [showEducation, setShowEducation] = React.useState<boolean>(false)

  const toggleEducation = (): void => {
    setShowEducation(!showEducation)
  }

  return (
    <>
      <Tooltip title="Learn more" variant="soft" placement="bottom-start" sx={{ typography: 'body-xs' }}>
        <Typography
          level="body-sm"
          startDecorator={<Icon height={16} width={16} icon={icon} />}
          onClick={education != null ? toggleEducation : undefined}
          aria-label={education != null ? `toggle information for ${label}` : undefined}
          sx={theme => ({
            'cursor': education != null ? 'pointer' : undefined,
            'textDecoration': focus === true ? 'underline' : undefined,
            ':hover': {
              color: theme.vars.palette.primary[400],
            },
          })}
        >
          {label}
        </Typography>
      </Tooltip>
      {children}
      {education && showEducation && (
        <Box sx={{ gridColumn: '1/-1' }}>
          <Education>{education}</Education>
        </Box>
      )}
    </>
  )
}

function Subheading({
  label,
  isFirstRow,
}: {
  label: string
  isFirstRow: boolean
}): React.ReactNode {
  const { transition } = useHomeBlockStateContext()

  if (isFirstRow) {
    return (
      <Stack direction="row" alignItems="center" gridColumn="1/-1">
        {label && (
          <Typography level="title-md" noWrap flexGrow={1}>
            {label}
          </Typography>
        )}
        <Button
          size="sm"
          variant="plain"
          sx={{ minHeight: 0, padding: 0.5, justifySelf: 'flex-end' }}
          onClick={async () => await transition('back')}
        >
          Change original
        </Button>
      </Stack>
    )
  }

  return label && (
    <Typography level="title-md" noWrap gridColumn="1/-1">
      {label}
    </Typography>
  )
}

export function Table({
  children,
}: {
  children: React.ReactNode
}): React.ReactNode {
  return (
    <Box sx={{ paddingY: 0.5 }}>
      <Box
        sx={{
          display: 'grid',
          gridTemplateColumns: 'auto 60%',
          gridAutoRows: `minmax(2rem, auto)`,
          alignItems: 'center',
          gap: 1,
        }}
      >
        {children}
      </Box>
    </Box>
  )
}

export function EducationTeaser({ text, href }: { text: string, href: string }): React.ReactNode {
  return (
    <Education>
      <Stack direction="row" alignItems="center" gap={0.5} justifyContent="space-between">
        <EducationParagraph>
          {text}
        </EducationParagraph>
        <Button
          size="sm"
          variant="plain"
          color="primary"
          sx={{
            alignSelf: 'flex-end',
            minHeight: 0,
            paddingX: 0.5,
            paddingY: 0,
            lineHeight: theme => theme.typography['body-sm'].lineHeight,
          }}
          component="a"
          href={href}
          target="_blank"
        >
          Learn
        </Button>
      </Stack>
    </Education>
  )
}

export function EducationParagraph({ children }: { children: React.ReactNode }): React.ReactNode {
  return <Typography level="body-sm">{children}</Typography>
}

function Education({ children }: { children: React.ReactNode }): React.ReactNode {
  return (
    <Sheet
      component={Stack}
      color="primary"
      variant="soft"
      direction="column"
      padding={1.5}
      gap={1}
      sx={{
        borderRadius: '0px',
      }}
    >
      {children}
    </Sheet>
  )
}

export function BlockImage({
  alt,
  src,
}: {
  alt: string
  src: string
}): React.ReactNode {
  return (
    <img
      alt={alt}
      src={publicUrl(src)}
      style={{ display: 'block', width: '100%', height: '100%', objectFit: 'cover' }}
    />
  )
}

export function Money({ value, suffix }: { value: number, suffix?: string }): React.ReactNode {
  const formattedValue = formatNumber(value, 'currency')

  return (
    <Typography
      level="body-sm"
      textAlign="left"
      sx={{ color: value < 0 && formattedValue != '£0' ? 'red' : undefined }}
    >
      {formattedValue}
      {suffix && ' '}
      {suffix}
    </Typography>
  )
}

export function Unit({ children }: { children: React.ReactNode }): React.ReactNode {
  return (
    <Typography flexBasis={0} flexGrow={1} textAlign="right" level="body-sm">
      {children}
    </Typography>
  )
}

export function Output({ value, unit, maximumFractionDigits = 0, roundingIncrement }: {
  value: number
  unit: string
  maximumFractionDigits?: number
  roundingIncrement?: Intl.NumberFormatOptions['roundingIncrement']
}): React.ReactNode {
  return (
    <Typography level="body-sm" justifySelf="left">
      {`${formatNumber(value, { maximumFractionDigits, roundingIncrement })} ${unit}`}
    </Typography>
  )
}
