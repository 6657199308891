// The location component of the model is concerned with the location of the building.

export type Dependencies = {
  _location: null
}

export type Inputs = {
  address: string
  postcode: string
  latitude: number
  longitude: number
}

export type Country = 'England' | 'Northern Ireland' | 'Scotland' | 'Wales'

export type Outputs = {
  country: Country
  designTemp: number
  ddRegion: DegreeDayRegion
  ddTable: DegreeDayTable
  waterUnitCost: number
}

export type DegreeDayRegion = typeof POSTCODE_TO_DEGREE_DAY_REGION[keyof typeof POSTCODE_TO_DEGREE_DAY_REGION]

export type DegreeDayTable = typeof DEGREE_DAYS[DegreeDayRegion]

export function getOutputs({}: Dependencies, inputs: Inputs): Outputs {
  const country = getCountry(inputs)

  const ddRegion = getDdRegion(inputs)

  const designTemp = DESIGN_TEMP[ddRegion]

  const ddTable = DEGREE_DAYS[ddRegion]

  const waterUnitCost = WATER_UNIT_COST[ddRegion]

  return {
    country,
    designTemp,
    ddRegion,
    ddTable,
    waterUnitCost,
  }
}

function getCountry({ postcode }: Inputs): Country {
  const mapping: Record<string, Country> = POSTCODE_TO_COUNTRY

  const postcodeArea = POSTCODE_AREA_MATCH.exec(postcode)?.[0]

  const country = postcodeArea != null ? mapping[postcodeArea] : undefined

  return country ?? 'England'
}

const POSTCODE_TO_COUNTRY: Record<string, Country> = {
  AB: 'Scotland',
  AL: 'England',
  B: 'England',
  BA: 'England',
  BB: 'England',
  BD: 'England',
  BH: 'England',
  BL: 'England',
  BN: 'England',
  BR: 'England',
  BS: 'England',
  BT: 'Northern Ireland',
  CA: 'England',
  CB: 'England',
  CF: 'England',
  CH: 'England',
  CM: 'England',
  CO: 'England',
  CR: 'England',
  CT: 'England',
  CV: 'England',
  CW: 'England',
  DA: 'England',
  DD: 'Scotland',
  DE: 'England',
  DG: 'England',
  DH: 'England',
  DL: 'England',
  DN: 'England',
  DT: 'England',
  DY: 'England',
  E: 'England',
  EC: 'England',
  EH: 'Scotland',
  EN: 'England',
  EX: 'England',
  FK: 'Scotland',
  FY: 'Scotland',
  G: 'Scotland',
  GL: 'England',
  GU: 'England',
  GY: 'England',
  HA: 'England',
  HD: 'England',
  HG: 'England',
  HP: 'England',
  HR: 'England',
  HS: 'Scotland',
  HU: 'England',
  HX: 'England',
  IG: 'England',
  IM: 'England',
  IP: 'England',
  IV: 'Scotland',
  JE: 'England',
  KA: 'Scotland',
  KT: 'England',
  KW: 'Scotland',
  KY: 'Scotland',
  L: 'England',
  LA: 'England',
  LD: 'Wales',
  LE: 'England',
  LL: 'Wales',
  LN: 'England',
  LS: 'England',
  LU: 'England',
  M: 'England',
  ME: 'England',
  MK: 'England',
  ML: 'Scotland',
  N: 'England',
  NE: 'England',
  NG: 'England',
  NN: 'England',
  NP: 'England',
  NR: 'England',
  NW: 'England',
  OL: 'England',
  OX: 'England',
  PA: 'Scotland',
  PE: 'England',
  PH: 'Scotland',
  PL: 'England',
  PO: 'England',
  PR: 'England',
  RG: 'England',
  RH: 'England',
  RM: 'England',
  S: 'England',
  SA: 'Wales',
  SE: 'England',
  SG: 'England',
  SK: 'England',
  SL: 'England',
  SM: 'England',
  SN: 'England',
  SO: 'England',
  SP: 'England',
  SR: 'England',
  SS: 'England',
  ST: 'England',
  SW: 'England',
  SY: 'Wales',
  TA: 'England',
  TD: 'Scotland',
  TF: 'England',
  TN: 'England',
  TQ: 'England',
  TR: 'England',
  TS: 'England',
  TW: 'England',
  UB: 'England',
  W: 'England',
  WA: 'England',
  WC: 'England',
  WD: 'England',
  WF: 'England',
  WN: 'England',
  WR: 'England',
  WS: 'England',
  WV: 'England',
  YO: 'England',
  ZE: 'Scotland',
}

function getDdRegion({ postcode }: Inputs): DegreeDayRegion {
  const mapping: Record<string, DegreeDayRegion> = POSTCODE_TO_DEGREE_DAY_REGION

  const postcodeArea = POSTCODE_AREA_MATCH.exec(postcode)?.[0]

  const ddRegion = postcodeArea != null ? mapping[postcodeArea] : undefined

  return ddRegion ?? DEFAULT_DEGREE_DAY_REGION
}

const POSTCODE_AREA_MATCH = /^[a-z]{1,2}/i

const DEFAULT_DEGREE_DAY_REGION: DegreeDayRegion = 'East Anglia'

const POSTCODE_TO_DEGREE_DAY_REGION = {
  AB: 'North East Scotland',
  AL: 'Thames Valley',
  B: 'Midland',
  BA: 'Severn Valley',
  BB: 'West Pennines',
  BD: 'North East',
  BH: 'South',
  BL: 'West Pennines',
  BN: 'South East',
  BR: 'Thames Valley',
  BS: 'Severn Valley',
  BT: 'Northern Ireland',
  CA: 'North West',
  CB: 'East Anglia',
  CF: 'Severn Valley',
  CH: 'West Pennines',
  CM: 'East Anglia',
  CO: 'East Anglia',
  CR: 'Thames Valley',
  CT: 'South East',
  CV: 'Midland',
  CW: 'West Pennines',
  DA: 'Thames Valley',
  DD: 'East Scotland',
  DE: 'Midland',
  DG: 'North West',
  DH: 'North East',
  DL: 'North East',
  DN: 'East Pennines',
  DT: 'South',
  DY: 'Midland',
  E: 'Thames Valley',
  EC: 'Thames Valley',
  EH: 'East Scotland',
  EN: 'Thames Valley',
  EX: 'South West',
  FK: 'West Scotland',
  FY: 'West Pennines',
  G: 'West Scotland',
  GL: 'Severn Valley',
  GU: 'South East',
  GY: 'South',
  HA: 'Thames Valley',
  HD: 'West Pennines',
  HG: 'North East',
  HP: 'Thames Valley',
  HR: 'Midland',
  HS: 'North West Scotland',
  HU: 'East Pennines',
  HX: 'West Pennines',
  IG: 'Thames Valley',
  IM: 'North West',
  IP: 'East Anglia',
  IV: 'North West Scotland',
  JE: 'South',
  KA: 'West Scotland',
  KT: 'Thames Valley',
  KW: 'North West Scotland',
  KY: 'East Scotland',
  L: 'West Pennines',
  LA: 'North West',
  LD: 'Wales',
  LE: 'Midland',
  LL: 'Wales',
  LN: 'East Pennines',
  LS: 'East Pennines',
  LU: 'Thames Valley',
  M: 'West Pennines',
  ME: 'South East',
  MK: 'Thames Valley',
  ML: 'West Scotland',
  N: 'Thames Valley',
  NE: 'Borders',
  NG: 'Midland',
  NN: 'Midland',
  NP: 'Severn Valley',
  NR: 'East Anglia',
  NW: 'Thames Valley',
  OL: 'West Pennines',
  OX: 'Thames Valley',
  PA: 'West Scotland',
  PE: 'East Anglia',
  PH: 'East Scotland',
  PL: 'South West',
  PO: 'South East',
  PR: 'West Pennines',
  RG: 'Thames Valley',
  RH: 'South East',
  RM: 'Thames Valley',
  S: 'East Pennines',
  SA: 'Wales',
  SE: 'Thames Valley',
  SG: 'Thames Valley',
  SK: 'West Pennines',
  SL: 'Thames Valley',
  SM: 'Thames Valley',
  SN: 'Severn Valley',
  SO: 'South',
  SP: 'South',
  SR: 'North East',
  SS: 'East Anglia',
  ST: 'Midland',
  SW: 'Thames Valley',
  SY: 'Wales',
  TA: 'Severn Valley',
  TD: 'Borders',
  TF: 'Midland',
  TN: 'South East',
  TQ: 'South West',
  TR: 'South West',
  TS: 'North East',
  TW: 'Thames Valley',
  UB: 'Thames Valley',
  W: 'Thames Valley',
  WA: 'West Pennines',
  WC: 'Thames Valley',
  WD: 'Thames Valley',
  WF: 'East Pennines',
  WN: 'West Pennines',
  WR: 'Midland',
  WS: 'Midland',
  WV: 'Midland',
  YO: 'North East',
  ZE: 'North East Scotland',
} as const

const DESIGN_TEMP: { [K in DegreeDayRegion]: number } = {
  'Thames Valley': -1.8,
  'South East': -1.8,
  'South': -1.0,
  'South West': -0.2,
  'Severn Valley': -1.6,
  'Midland': -3.4,
  'West Pennines': -2.2,
  'North West': -3.0,
  'Borders': -3.4,
  'North East': -2.8,
  'East Pennines': -2.8,
  'East Anglia': -1.8,
  'West Scotland': -3.9,
  'East Scotland': -3.4,
  'North East Scotland': -4.5,
  'Wales': -1.6,
  'Northern Ireland': -1.2,
  'North West Scotland': -4.5,
}

const WATER_UNIT_COST: { [K in DegreeDayRegion]: number } = {
  'Thames Valley': 0.2953,
  'South East': 0.4173,
  'South': 0.4505,
  'South West': 0.4853,
  'Severn Valley': 0.4505,
  'Midland': 0.2774,
  'West Pennines': 0.3181,
  'North West': 0.3181,
  'Borders': 0.2361,
  'North East': 0.3666,
  'East Pennines': 0.4025,
  'East Anglia': 0.4025,
  'West Scotland': 0,
  'East Scotland': 0,
  'North East Scotland': 0,
  'Wales': 0.3144,
  'Northern Ireland': 0.3470,
  'North West Scotland': 0,
}

type Tuple12<T> = [T, T, T, T, T, T, T, T, T, T, T, T]

// NOTE: we could keep this outside the file (e.g. in JSON) but at least this way the shape is validated
const DEGREE_DAYS: { [K in DegreeDayRegion]: Tuple12<Tuple12<number>> } = {
  'Borders': [
    [251.44, 266.84, 282.32, 297.82, 313.32, 328.82, 344.32, 359.82, 375.32, 390.82, 406.32, 421.82],
    [196.32, 210.46, 224.64, 238.82, 253.02, 267.2, 281.4, 295.6, 309.8, 324, 338.2, 352.4],
    [195.82, 210.58, 225.64, 240.7, 255.94, 271.2, 286.46, 301.82, 317.24, 332.62, 348.08, 363.5],
    [160.26, 173.9, 187.8, 201.92, 216.3, 230.78, 245.42, 260.1, 274.84, 289.64, 304.56, 319.46],
    [69.6, 79.46, 90.64, 102.02, 114.24, 126.84, 139.96, 153.34, 167.34, 181.44, 196.06, 210.7],
    [27.6, 33.64, 40.8, 48.22, 56.68, 65.72, 75.56, 85.68, 96.64, 107.96, 119.9, 132.14],
    [8.78, 11.74, 16.16, 20.94, 27.24, 34.18, 42.44, 50.94, 60.94, 71.18, 82.6, 94.44],
    [10.46, 13.6, 17.84, 22.4, 28.7, 35.26, 43.64, 52.14, 62.04, 72.4, 84.04, 95.92],
    [32.02, 37.94, 45.24, 53, 62.22, 71.62, 82.28, 93.3, 105.36, 117.6, 130.68, 144],
    [88.16, 100.32, 113.5, 126.7, 140.6, 154.56, 169.18, 183.84, 198.88, 213.86, 229.16, 244.44],
    [166.14, 180.46, 195.02, 209.66, 224.46, 239.26, 254.2, 269.12, 284.1, 299.08, 314.08, 329.08],
    [242.96, 258.3, 273.78, 289.26, 304.76, 320.26, 335.76, 351.26, 366.76, 382.26, 397.76, 413.26],
  ],
  'East Anglia': [
    [236.88, 252.14, 267.5, 282.94, 298.42, 313.9, 329.4, 344.9, 360.4, 375.9, 391.4, 406.9],
    [164.66, 178.06, 191.86, 205.72, 219.78, 233.86, 247.98, 262.14, 276.3, 290.48, 304.68, 318.88],
    [160, 173.9, 188.24, 202.74, 217.44, 232.3, 247.4, 262.62, 277.82, 293.08, 308.4, 323.76],
    [125, 136.64, 148.82, 161.34, 174.2, 187.44, 200.92, 214.58, 228.54, 242.7, 256.9, 271.24],
    [49.18, 56.88, 65.36, 74.36, 84.34, 94.56, 105.72, 117.22, 129.02, 141.46, 154.26, 167.52],
    [14.3, 17.9, 22.02, 26.92, 32.52, 38.62, 45.26, 52.54, 60.42, 68.66, 77.44, 86.74],
    [3.68, 5.1, 7.14, 9.36, 12.3, 15.76, 20.1, 24.78, 30.7, 37.04, 44.66, 52.5],
    [4.2, 5.78, 7.66, 9.88, 12.86, 16.22, 20.42, 24.86, 30.32, 36.44, 43.26, 50.88],
    [19.5, 23.32, 27.6, 32.26, 37.9, 44.06, 51.02, 58.68, 67.1, 76.26, 85.98, 96.26],
    [56.52, 65.32, 75.22, 85.56, 96.94, 108.76, 121.56, 134.72, 148.38, 162.58, 177.12, 192],
    [140.04, 153.66, 167.6, 181.76, 196.32, 211.04, 225.86, 240.74, 255.66, 270.6, 285.56, 300.54],
    [206.54, 221.44, 236.64, 251.96, 267.36, 282.8, 298.3, 313.8, 329.3, 344.8, 360.3, 375.8],
  ],
  'East Pennines': [
    [221.86, 237.1, 252.52, 267.94, 283.42, 298.92, 314.42, 329.92, 345.42, 360.92, 376.42, 391.92],
    [155.66, 169.02, 182.94, 196.86, 210.98, 225.1, 239.26, 253.42, 267.6, 281.76, 295.96, 310.16],
    [154.26, 168.2, 182.66, 197.02, 212.04, 227.02, 242.18, 257.46, 272.74, 288.08, 303.42, 318.76],
    [119.58, 132.02, 145.02, 158.18, 171.8, 185.46, 199.54, 213.74, 227.94, 242.42, 257.04, 271.7],
    [44.24, 51.88, 60.98, 70.32, 80.74, 91.5, 103.26, 115.14, 127.74, 140.68, 154.2, 168],
    [12.66, 16.66, 21.68, 26.94, 33.44, 40.22, 48.2, 56.36, 65.32, 74.5, 84.56, 94.96],
    [3.08, 4.42, 6.66, 9.14, 12.74, 16.3, 21.6, 27.02, 33.76, 40.74, 49.14, 57.66],
    [3.24, 4.44, 6.34, 8.32, 11.16, 14.36, 18.56, 23.04, 29.24, 35.72, 43.7, 51.92],
    [13.04, 16.54, 20.8, 25.48, 31.32, 37.4, 45.08, 53.06, 62.54, 71.94, 82.7, 93.84],
    [51.5, 60.84, 71.22, 81.74, 93.78, 106, 119.46, 132.9, 147.2, 161.58, 176.34, 191.22],
    [126.96, 140.58, 154.72, 168.92, 183.52, 198.2, 213.08, 227.96, 242.9, 257.82, 272.8, 287.76],
    [204.92, 220.04, 235.32, 250.6, 266.02, 281.46, 296.94, 312.44, 327.94, 343.44, 358.94, 374.44],
  ],
  'East Scotland': [
    [246.3, 261.72, 277.2, 292.68, 308.18, 323.68, 339.18, 354.68, 370.18, 385.68, 401.18, 416.68],
    [198.24, 212.44, 226.64, 240.84, 255.04, 269.24, 283.44, 297.64, 311.84, 326.04, 340.24, 354.44],
    [197.38, 212.22, 227.34, 242.48, 257.74, 272.96, 288.32, 303.7, 319.16, 334.58, 350.04, 365.5],
    [152.1, 165.9, 180.06, 194.42, 208.94, 223.52, 238.24, 253.04, 267.9, 282.78, 297.72, 312.7],
    [67.54, 77.7, 88.88, 100.52, 113.06, 125.96, 139.56, 153.38, 167.66, 182.16, 196.96, 211.8],
    [22.22, 27.68, 34.36, 41.38, 49.92, 58.86, 68.9, 79.64, 91.08, 102.76, 115.48, 128.18],
    [9.34, 12.5, 16.92, 21.9, 28.2, 35, 43.42, 52.06, 62.16, 72.68, 84.34, 96.52],
    [10.66, 13.58, 17.42, 21.8, 27.76, 34.08, 42.6, 51.18, 61.76, 72.34, 84.6, 96.94],
    [32.44, 38.36, 45.44, 53.1, 62.08, 71.52, 82.32, 93.46, 105.82, 118.46, 131.9, 145.54],
    [87.66, 100.16, 113.6, 127.08, 141.4, 155.72, 170.5, 185.34, 200.62, 215.9, 231.3, 246.72],
    [172.78, 187, 201.54, 216.08, 230.92, 245.74, 260.62, 275.52, 290.48, 305.46, 320.46, 335.46],
    [242.26, 257.7, 273.18, 288.64, 304.14, 319.64, 335.14, 350.64, 366.14, 381.64, 397.14, 412.64],
  ],
  'Midland': [
    [233.46, 248.72, 264.18, 279.64, 295.14, 310.64, 326.14, 341.64, 357.14, 372.64, 388.14, 403.64],
    [167.94, 181.6, 195.62, 209.62, 223.72, 237.84, 252.04, 266.2, 280.4, 294.6, 308.8, 323],
    [171.74, 186.18, 200.88, 215.84, 230.8, 246, 261.26, 276.5, 291.8, 307.08, 322.48, 337.86],
    [123.3, 135.46, 148.38, 161.46, 175.04, 188.58, 202.46, 216.5, 230.82, 245.16, 259.66, 274.22],
    [47.02, 54.92, 64.02, 73.54, 84.14, 95.06, 106.84, 118.76, 131.4, 144.3, 157.72, 171.34],
    [14.68, 18.36, 23.36, 28.52, 34.96, 41.86, 49.5, 57.36, 66.34, 75.46, 85.64, 96.18],
    [4.2, 5.78, 8.08, 10.46, 13.96, 17.86, 23.36, 29.16, 36.58, 44.38, 53.54, 62.98],
    [3.98, 5.36, 7.52, 9.88, 13.44, 17.28, 22.42, 27.96, 34.98, 42.44, 51.34, 60.5],
    [17.2, 20.9, 26.02, 31.38, 38.18, 45.18, 53.66, 62.28, 72.32, 82.6, 93.86, 105.44],
    [60.98, 70.6, 81.62, 92.7, 105.78, 118.78, 132.84, 146.9, 161.6, 176.36, 191.44, 206.56],
    [144.14, 158.14, 172.78, 187.44, 202.22, 217.06, 232, 246.92, 261.92, 276.9, 291.9, 306.9],
    [209.26, 224.32, 239.64, 255.02, 270.5, 286, 301.5, 317, 332.5, 348, 363.5, 379],
  ],
  'North East': [
    [249.4, 264.78, 280.24, 295.7, 311.2, 326.7, 342.2, 357.7, 373.2, 388.7, 404.2, 419.7],
    [182.12, 196.16, 210.3, 224.46, 238.64, 252.82, 267.02, 281.22, 295.42, 309.62, 323.82, 338.02],
    [180.88, 195.42, 210.3, 225.28, 240.44, 255.64, 270.86, 286.12, 301.5, 316.86, 332.28, 347.68],
    [140.04, 152.84, 166.24, 179.8, 193.78, 207.86, 222.26, 236.66, 251.2, 265.86, 280.66, 295.4],
    [58.92, 67.92, 77.98, 88.22, 99.8, 111.42, 123.96, 136.66, 149.98, 163.54, 177.62, 191.78],
    [21.5, 26.38, 32.38, 38.74, 46.18, 53.72, 62.4, 71.42, 81.18, 91.28, 102.24, 113.36],
    [5.38, 7.5, 10.56, 14.12, 19.04, 24.26, 30.92, 38.06, 46.28, 55.18, 65.16, 75.6],
    [7.32, 9.46, 12.46, 15.92, 20.22, 25.14, 31.64, 38.58, 46.86, 55.72, 65.8, 75.98],
    [25.06, 30.06, 36.02, 42.24, 49.82, 57.98, 67.38, 77.16, 87.86, 99, 111.1, 123.58],
    [77.42, 88.38, 100.58, 113.02, 126.5, 139.98, 154.26, 168.72, 183.46, 198.38, 213.5, 228.7],
    [155.1, 169.24, 183.72, 198.28, 213, 227.78, 242.68, 257.64, 272.64, 287.64, 302.64, 317.64],
    [232.4, 247.6, 263, 278.42, 293.92, 309.42, 324.92, 340.42, 355.92, 371.42, 386.92, 402.42],
  ],
  'North East Scotland': [
    [261.52, 276.92, 292.4, 307.88, 323.38, 338.88, 354.38, 369.88, 385.38, 400.88, 416.38, 431.88],
    [221.22, 235.32, 249.5, 263.66, 277.84, 292.04, 306.24, 320.44, 334.64, 348.84, 363.04, 377.24],
    [215.08, 230.02, 245.22, 260.44, 275.82, 291.12, 306.5, 321.94, 337.4, 352.82, 368.28, 383.74],
    [177.58, 191.92, 206.5, 221.16, 236, 250.82, 265.7, 280.6, 295.52, 310.46, 325.4, 340.38],
    [84.38, 95.82, 108.32, 120.88, 134.38, 147.8, 162.1, 176.46, 191.14, 205.98, 221.14, 236.2],
    [30.68, 37.3, 45.16, 53.74, 63.12, 72.9, 83.86, 95.02, 107.12, 119.24, 132.14, 145.14],
    [12.68, 16.8, 22.5, 28.92, 37, 45.5, 55.6, 65.94, 77.9, 89.78, 102.72, 115.92],
    [12.02, 15.86, 21.2, 26.76, 34.36, 42.12, 52.02, 62.04, 73.68, 85.8, 98.8, 112.22],
    [36.3, 43.26, 51.6, 60.42, 70.38, 80.82, 92.38, 104.12, 117.12, 130.14, 143.88, 157.62],
    [99.12, 112.42, 126.64, 141.08, 155.88, 170.74, 185.84, 201, 216.42, 231.76, 247.24, 262.68],
    [176.62, 191.02, 205.48, 220, 234.68, 249.4, 264.26, 279.16, 294.12, 309.1, 324.1, 339.1],
    [255.48, 270.92, 286.42, 301.92, 317.42, 332.92, 348.42, 363.92, 379.42, 394.92, 410.42, 425.92],
  ],
  'North West': [
    [237.7, 253.1, 268.54, 284.02, 299.5, 314.98, 330.48, 345.98, 361.48, 376.98, 392.48, 407.98],
    [178.26, 192.46, 206.66, 220.86, 235.06, 249.26, 263.46, 277.66, 291.86, 306.06, 320.26, 334.46],
    [176.62, 191.52, 206.5, 221.68, 236.9, 252.12, 267.44, 282.78, 298.16, 313.54, 328.96, 344.42],
    [136.08, 149.22, 162.86, 176.82, 190.94, 205.24, 219.8, 234.4, 249.2, 263.96, 278.8, 293.66],
    [52.78, 61.9, 71.88, 82.6, 94.12, 106.16, 118.76, 131.7, 145.12, 158.94, 172.9, 187.22],
    [16.48, 21.04, 26.3, 32.62, 39.8, 47.7, 56.62, 66.28, 76.8, 87.94, 99.5, 111.72],
    [5.3, 7.82, 11.02, 15.14, 20.58, 27.04, 34.38, 42.96, 52.52, 63.36, 74.94, 87.3],
    [5.74, 7.86, 10.58, 14.16, 18.78, 24.26, 30.88, 38.64, 47.56, 57.52, 68.62, 80.74],
    [25.44, 30.54, 36.66, 43.46, 51.18, 60.12, 69.94, 80.58, 92.14, 104.46, 117.22, 130.4],
    [73.52, 84.96, 97.2, 110, 123.5, 137.54, 152, 166.64, 181.6, 196.8, 212.16, 227.54],
    [155.3, 169.4, 183.78, 198.38, 213.12, 227.96, 242.84, 257.78, 272.74, 287.72, 302.72, 317.72],
    [231.4, 246.8, 262.28, 277.74, 293.24, 308.74, 324.24, 339.74, 355.24, 370.74, 386.24, 401.74],
  ],
  'North West Scotland': [
    [258.42, 273.86, 289.32, 304.8, 320.3, 335.8, 351.3, 366.8, 382.3, 397.8, 413.3, 428.8],
    [227.5, 241.68, 255.88, 270.08, 284.28, 298.48, 312.68, 326.88, 341.08, 355.28, 369.48, 383.68],
    [218.28, 233.32, 248.44, 263.7, 279.04, 294.36, 309.78, 325.16, 340.6, 356.06, 371.54, 387],
    [167.9, 181.56, 195.6, 209.68, 224.14, 238.66, 253.34, 268.06, 282.9, 297.8, 312.76, 327.72],
    [74.62, 85.4, 97.1, 109.02, 121.92, 134.9, 148.64, 162.62, 176.86, 191.22, 205.86, 220.64],
    [30.1, 35.98, 43.58, 51.22, 60.1, 69.4, 79.6, 90.1, 101.68, 113.24, 125.76, 138.4],
    [13.48, 17.6, 23.24, 29.52, 37.3, 45.68, 55.44, 65.66, 77.36, 89.18, 101.96, 114.94],
    [14.12, 18.06, 23.08, 29, 36.2, 44.04, 53.44, 63.32, 74.7, 86.5, 99.4, 112.58],
    [37.56, 44.28, 52.46, 61.24, 71.2, 81.5, 93.08, 104.92, 117.52, 130.32, 143.84, 157.42],
    [111.34, 124.64, 138.68, 152.96, 167.6, 182.42, 197.48, 212.68, 228.08, 243.44, 258.96, 274.44],
    [191.84, 206, 220.56, 235.16, 249.98, 264.82, 279.74, 294.68, 309.64, 324.6, 339.58, 354.56],
    [271.8, 287.26, 302.76, 318.26, 333.76, 349.26, 364.76, 380.26, 395.76, 411.26, 426.76, 442.26],
  ],
  'Northern Ireland': [
    [242.48, 257.9, 273.4, 288.88, 304.38, 319.88, 335.38, 350.88, 366.38, 381.88, 397.38, 412.88],
    [188.04, 202.24, 216.44, 230.64, 244.84, 259.04, 273.24, 287.44, 301.64, 315.84, 330.04, 344.24],
    [189.1, 203.96, 219.14, 234.38, 249.64, 264.96, 280.4, 295.82, 311.24, 326.72, 342.2, 357.7],
    [139.74, 153.18, 167.04, 181.16, 195.66, 210.24, 224.92, 239.68, 254.6, 269.5, 284.46, 299.42],
    [63.12, 73.06, 84.18, 95.68, 108.2, 120.92, 134.32, 148.06, 162.22, 176.46, 191.12, 205.8],
    [22.66, 28.38, 35.28, 42.68, 51.22, 60.02, 70, 80.24, 91.62, 103.34, 115.82, 128.6],
    [9.8, 13.3, 18.4, 23.96, 31.26, 38.92, 48.34, 58.2, 69.7, 81.4, 94.02, 107.08],
    [8.3, 10.96, 15.24, 19.64, 26.2, 33.16, 42.02, 51.02, 61.8, 73.2, 85.48, 98.34],
    [26.48, 32.12, 39.24, 46.52, 55.5, 65.08, 75.98, 87.24, 99.56, 112.18, 125.68, 139.36],
    [81.7, 93.54, 106.7, 119.98, 133.98, 148.16, 162.8, 177.54, 192.64, 207.84, 223.2, 238.68],
    [153.5, 167.68, 182.32, 196.96, 211.78, 226.6, 241.56, 256.54, 271.52, 286.52, 301.52, 316.52],
    [219.26, 234.7, 250.2, 265.7, 281.2, 296.7, 312.2, 327.7, 343.2, 358.7, 374.2, 389.7],
  ],
  'Severn Valley': [
    [230.16, 245.58, 261.08, 276.58, 292.08, 307.58, 323.08, 338.58, 354.08, 369.58, 385.08, 400.58],
    [170.86, 185.02, 199.2, 213.38, 227.58, 241.78, 255.98, 270.18, 284.38, 298.58, 312.78, 326.98],
    [173.32, 188.16, 203.18, 218.18, 233.34, 248.44, 263.76, 279.08, 294.46, 309.84, 325.26, 340.7],
    [124.24, 136.98, 150.22, 163.66, 177.56, 191.5, 205.64, 219.88, 234.24, 248.72, 263.36, 278.04],
    [50.02, 59.26, 69.7, 80.28, 91.9, 103.78, 116.54, 129.28, 142.76, 156.34, 170.3, 184.46],
    [13.72, 17.68, 22.92, 28.76, 35.82, 43.38, 52.16, 61.16, 71.32, 81.68, 92.9, 104.32],
    [2.66, 4.18, 6.96, 9.88, 14.48, 19.38, 26.4, 33.92, 42.9, 52.24, 63.06, 74.2],
    [2.56, 3.84, 6.12, 8.6, 12.64, 17.14, 23.08, 29.84, 38.08, 46.82, 57.14, 67.98],
    [12.84, 16.28, 21.08, 26.28, 33.06, 40.34, 49.16, 58.34, 69.2, 80.36, 92.56, 104.82],
    [51.2, 60.64, 71.76, 83.3, 96.46, 109.64, 123.9, 138.3, 153.24, 168.32, 183.64, 198.94],
    [138.06, 152.28, 166.88, 181.64, 196.52, 211.42, 226.34, 241.3, 256.3, 271.3, 286.3, 301.3],
    [195.06, 210.42, 225.9, 241.38, 256.88, 272.38, 287.88, 303.38, 318.88, 334.38, 349.88, 365.38],
  ],
  'South': [
    [216.58, 231.9, 247.4, 262.9, 278.4, 293.9, 309.4, 324.9, 340.4, 355.9, 371.4, 386.9],
    [148.28, 162.24, 176.4, 190.52, 204.7, 218.88, 233.08, 247.28, 261.48, 275.68, 289.88, 304.08],
    [146.68, 161.28, 176.18, 191.02, 206.1, 221.32, 236.56, 251.82, 267.16, 282.52, 297.94, 313.36],
    [112.26, 123.88, 136.62, 149.5, 162.76, 176.34, 190.32, 204.48, 218.82, 233.24, 247.82, 262.44],
    [45.78, 53.38, 62.16, 71.5, 81.62, 92.06, 103.48, 115.16, 127.5, 140.3, 153.7, 167.4],
    [12.68, 15.56, 19.06, 23.44, 28.38, 33.9, 40.3, 47.44, 55.54, 64.08, 73.8, 83.76],
    [4.96, 6.34, 8.38, 10.6, 13.48, 16.72, 21.06, 25.94, 31.9, 38.7, 46.36, 54.68],
    [5.54, 7.2, 9.44, 11.8, 14.7, 18.2, 22.08, 26.78, 32.18, 38.28, 45.64, 53.62],
    [21.7, 25.3, 29.46, 33.76, 38.88, 44.2, 50.54, 57.38, 65.2, 73.48, 83, 93.24],
    [44.94, 51.92, 59.92, 68.44, 78.16, 88.32, 99.98, 112.02, 125.42, 139.24, 153.82, 168.64],
    [118.38, 130.88, 144.34, 158.1, 172.38, 186.82, 201.62, 216.46, 231.42, 246.38, 261.38, 276.38],
    [170.64, 185.44, 200.84, 216.22, 231.72, 247.2, 262.7, 278.2, 293.7, 309.2, 324.7, 340.2],
  ],
  'South East': [
    [222.84, 238.12, 253.6, 269.08, 284.58, 300.08, 315.58, 331.08, 346.58, 362.08, 377.58, 393.08],
    [154.68, 168.52, 182.6, 196.62, 210.78, 224.96, 239.16, 253.36, 267.56, 281.76, 295.96, 310.16],
    [154.12, 168.56, 183.44, 198.34, 213.44, 228.56, 243.74, 258.94, 274.32, 289.54, 304.88, 320.24],
    [111.24, 122.82, 135.34, 147.8, 160.92, 174.1, 187.54, 201.36, 215.46, 229.56, 243.98, 258.44],
    [41.78, 49.26, 58.06, 66.84, 77.04, 87.3, 98.36, 109.94, 122.14, 134.66, 147.94, 161.18],
    [8.88, 11.68, 15.42, 19.28, 24.36, 29.68, 35.98, 42.74, 50.58, 58.82, 68.14, 77.48],
    [2.02, 2.88, 4.48, 6.16, 8.56, 11.2, 14.84, 19.02, 24.56, 30.48, 37.76, 45.7],
    [2.12, 3.04, 4.58, 6.34, 8.88, 11.64, 15.84, 20.02, 25.66, 31.58, 38.56, 46.04],
    [14.36, 17.46, 21.26, 25.36, 30.26, 35.52, 42.1, 48.6, 56.92, 65.26, 75.28, 85.56],
    [45.66, 53.48, 62.56, 71.9, 82.78, 93.92, 106.32, 119, 132.98, 146.98, 161.68, 176.56],
    [126.26, 139.46, 153.42, 167.44, 182, 196.6, 211.46, 226.3, 241.3, 256.26, 271.26, 286.26],
    [181.78, 196.8, 212.22, 227.68, 243.18, 258.66, 274.16, 289.66, 305.16, 320.66, 336.16, 351.66],
  ],
  'South West': [
    [182.56, 198.02, 213.52, 229.02, 244.52, 260.02, 275.52, 291.02, 306.52, 322.02, 337.52, 353.02],
    [130.3, 144.48, 158.68, 172.88, 187.08, 201.28, 215.48, 229.68, 243.88, 258.08, 272.28, 286.48],
    [130.88, 145.74, 160.78, 175.86, 191.12, 206.46, 221.8, 237.16, 252.56, 267.94, 283.4, 298.86],
    [90.48, 102.92, 116.04, 129.48, 143.38, 157.48, 171.84, 186.24, 200.9, 215.56, 230.3, 245.12],
    [39.98, 48.54, 58.1, 68.3, 79.06, 90.76, 102.62, 115.4, 128.68, 142.12, 156.08, 170.2],
    [8.28, 11.28, 14.88, 19.54, 25.26, 31.96, 39.58, 47.98, 57.44, 67.54, 78.26, 89.68],
    [2.9, 4.12, 5.72, 7.94, 11.08, 15.46, 20.8, 27.28, 34.96, 43.6, 53.18, 63.82],
    [2.22, 3.2, 4.54, 6.24, 8.64, 11.7, 15.66, 20.78, 27.32, 34.96, 43.78, 53.52],
    [10.04, 12.76, 15.96, 19.68, 24.26, 29.72, 36.1, 43.72, 52.38, 62.34, 73.32, 84.84],
    [25.92, 32.6, 40.48, 49.6, 59.86, 71.54, 84.34, 98.08, 112.5, 127.34, 142.48, 157.8],
    [89.5, 102.66, 116.5, 130.84, 145.66, 160.56, 175.52, 190.5, 205.48, 220.48, 235.48, 250.48],
    [139.4, 154.88, 170.38, 185.88, 201.38, 216.88, 232.38, 247.88, 263.38, 278.88, 294.38, 309.88],
  ],
  'Thames Valley': [
    [210.98, 226.06, 241.52, 257, 272.48, 287.98, 303.48, 318.98, 334.48, 349.98, 365.48, 380.98],
    [143.38, 156.9, 170.84, 184.88, 198.98, 213.14, 227.32, 241.5, 255.7, 269.88, 284.08, 298.28],
    [138.5, 152.42, 166.98, 181.68, 196.54, 211.48, 226.56, 241.72, 256.96, 272.2, 287.46, 302.84],
    [97.46, 108.66, 120.68, 132.8, 145.72, 158.62, 171.94, 185.44, 199.28, 213.32, 227.52, 241.78],
    [31.4, 37.84, 45.64, 53.72, 63, 72.78, 83.26, 94.22, 106, 117.76, 130.42, 143.18],
    [5.96, 7.9, 10.96, 14.02, 18.28, 22.64, 28.18, 33.92, 40.98, 48.16, 56.3, 64.66],
    [0.9, 1.3, 2.22, 3.38, 5.16, 7.3, 10.34, 13.52, 18.26, 23.32, 29.56, 36.14],
    [0.84, 1.36, 2.28, 3.34, 5.16, 7.26, 10.36, 13.66, 18.24, 23.22, 29.42, 35.96],
    [9, 11.32, 14.26, 17.64, 21.94, 26.44, 32.08, 38.18, 45.34, 53.06, 62.2, 71.56],
    [37.66, 44.94, 53.88, 62.78, 73.56, 84.4, 96.68, 109.28, 123.02, 136.84, 151.5, 166.18],
    [118.58, 131.86, 145.88, 159.96, 174.46, 189.06, 203.94, 218.8, 233.74, 248.7, 263.7, 278.7],
    [175.8, 190.56, 205.78, 221.02, 236.5, 251.94, 267.44, 282.94, 298.44, 313.94, 329.44, 344.94],
  ],
  'Wales': [
    [195.34, 210.82, 226.3, 241.8, 257.3, 272.8, 288.3, 303.8, 319.3, 334.8, 350.3, 365.8],
    [156.66, 170.86, 185.06, 199.26, 213.46, 227.66, 241.86, 256.06, 270.26, 284.46, 298.66, 312.86],
    [160.84, 175.94, 191.1, 206.28, 221.6, 236.88, 252.2, 267.58, 282.98, 298.46, 313.9, 329.34],
    [112.94, 126.54, 140.52, 154.72, 169.06, 183.58, 198.22, 212.92, 227.66, 242.44, 257.34, 272.26],
    [50, 60.92, 72.76, 85.24, 98.44, 112.18, 126.22, 140.44, 155.06, 169.8, 184.64, 199.6],
    [13, 18.38, 24.9, 32.6, 41.44, 51.54, 62.36, 73.86, 85.94, 98.82, 112.08, 125.58],
    [2.86, 4.82, 8.06, 12.48, 18.26, 25.58, 34.48, 44.9, 56.28, 68.58, 81.46, 94.82],
    [1.58, 2.68, 4.16, 6.76, 10.36, 15.48, 22.48, 30.66, 40.34, 51.2, 63.04, 75.56],
    [6.74, 9.92, 13.96, 19.1, 25.68, 33.42, 42.86, 53.38, 64.88, 77.24, 90.06, 103.32],
    [43.22, 53.06, 64.32, 76.52, 89.78, 103.78, 118.38, 133.34, 148.6, 163.96, 179.4, 194.9],
    [108, 122.28, 136.84, 151.54, 166.44, 181.34, 196.3, 211.28, 226.26, 241.26, 256.26, 271.26],
    [168.5, 183.9, 199.4, 214.9, 230.4, 245.9, 261.4, 276.9, 292.4, 307.9, 323.4, 338.9],
  ],
  'West Pennines': [
    [236.06, 251.46, 266.92, 282.4, 297.9, 313.4, 328.9, 344.4, 359.9, 375.4, 390.9, 406.4],
    [172.18, 186.1, 200.22, 214.36, 228.54, 242.72, 256.92, 271.12, 285.32, 299.52, 313.72, 327.92],
    [165.26, 179.58, 194.32, 209.1, 224.12, 239.12, 254.26, 269.46, 284.72, 300.02, 315.32, 330.74],
    [120.72, 133.06, 145.86, 158.92, 172.6, 186.16, 200.2, 214.3, 228.62, 242.94, 257.64, 272.34],
    [49.2, 57.36, 66.76, 76.56, 87.46, 98.6, 110.58, 122.92, 135.78, 148.86, 162.54, 176.36],
    [14.9, 18.82, 23.98, 29.28, 36.12, 43.06, 51.08, 59.46, 68.82, 78.46, 88.88, 99.48],
    [4.94, 6.88, 9.84, 13.22, 17.98, 22.98, 29.82, 37.06, 46.18, 55.54, 66.14, 77.16],
    [5.64, 7.34, 9.84, 12.34, 16.58, 20.86, 26.86, 33.2, 41.42, 49.86, 59.76, 70.16],
    [19.7, 24.22, 29.5, 35.42, 42.64, 50.2, 59.08, 68.64, 79.2, 89.94, 101.82, 113.76],
    [64.68, 75.34, 87.08, 99.3, 112.44, 125.86, 140.1, 154.44, 169.36, 184.3, 199.5, 214.74],
    [146.28, 160.44, 174.9, 189.42, 204.2, 218.98, 233.94, 248.84, 263.8, 278.78, 293.78, 308.78],
    [218.4, 233.52, 248.9, 264.28, 279.78, 295.28, 310.78, 326.28, 341.78, 357.28, 372.78, 388.28],
  ],
  'West Scotland': [
    [248.86, 264.3, 279.8, 295.28, 310.78, 326.28, 341.78, 357.28, 372.78, 388.28, 403.78, 419.28],
    [196.36, 210.56, 224.76, 238.96, 253.16, 267.36, 281.56, 295.76, 309.96, 324.16, 338.36, 352.56],
    [195.56, 210.5, 225.6, 240.74, 256, 271.3, 286.64, 301.98, 317.4, 332.84, 348.3, 363.78],
    [141.18, 154.32, 167.9, 181.7, 195.9, 210.18, 224.8, 239.5, 254.3, 269.16, 284.06, 299.02],
    [59.32, 68.66, 79.52, 90.48, 102.48, 114.92, 127.92, 141.18, 155.1, 169.02, 183.5, 198.06],
    [19.2, 23.76, 29.86, 36.2, 44.2, 52.18, 61.72, 71.54, 82.48, 93.68, 105.92, 118.3],
    [10.68, 13.86, 18.44, 23.18, 29.66, 36.46, 45.18, 54.2, 64.68, 75.46, 87.52, 99.78],
    [10.96, 13.68, 17.5, 21.76, 27.5, 33.8, 42, 50.52, 60.8, 71.52, 83.62, 96],
    [33.02, 38.64, 45.72, 53.06, 62.1, 71.38, 82.18, 93.44, 105.94, 118.68, 132.08, 145.7],
    [89.92, 102.4, 115.94, 129.8, 144.12, 158.56, 173.54, 188.7, 204.1, 219.5, 234.98, 250.48],
    [174.68, 188.96, 203.66, 218.34, 233.2, 248.04, 262.98, 277.9, 292.88, 307.88, 322.88, 337.88],
    [243.3, 258.78, 274.26, 289.76, 305.26, 320.76, 336.26, 351.76, 367.26, 382.76, 398.26, 413.76],
  ],
}
