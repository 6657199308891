import * as locationCalculator from './location'
import * as fabricCalculator from './fabric'

const DAYS_IN_YEAR = 365
const DAYS_IN_WEEK = 7
const WATER_HEAT_CAPACITY = 1.161
const HEATING_BASE_TEMP_DELTA = -4
const MAINS_TEMP = 10
const SHOWER_TEMP = 40
const BATH_WATER_USAGE = 80

export type Dependencies = {
  location: locationCalculator.Outputs
  fabric: fabricCalculator.Outputs
}

export type Inputs = {
  bathCountPerWeek: number
  showerHeadFlowRate: number
  showerDuration: number
  showerCountPerDay: number

  heatingOnMonth: HeatingMonth
  heatingOffMonth: HeatingMonth
  heatingTemp: number
}

export type Outputs = {
  hotWaterTempEnergy: number

  showerWaterPerDay: number
  showerWaterPerYear: number

  bathWaterPerDay: number
  bathWaterPerYear: number

  hotWaterPerDay: number
  hotWaterPerYear: number
  hotWaterEnergyPerDay: number
  hotWaterEnergyPerYear: number

  heatingTemp: number
  heatingBaseTemp: number
  heatingDegreeDaysPerYear: number
  heatingEnergyPerYear: number

  totalEnergyPerYear: number
}

export type HeatingMonth = 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12

export function getOutputs(
  { location, fabric }: Dependencies,
  inputs: Inputs,
): Outputs {
  const hotWaterTempEnergy = (SHOWER_TEMP - MAINS_TEMP) * WATER_HEAT_CAPACITY / 1000

  const showerWaterPerShower = inputs.showerHeadFlowRate * inputs.showerDuration

  const showerWaterPerDay = showerWaterPerShower * inputs.showerCountPerDay

  const showerWaterPerYear = showerWaterPerDay * DAYS_IN_YEAR

  const bathWaterPerWeek = BATH_WATER_USAGE * inputs.bathCountPerWeek

  const bathWaterPerDay = bathWaterPerWeek / DAYS_IN_WEEK

  const bathWaterPerYear = bathWaterPerDay * DAYS_IN_YEAR

  const hotWaterPerDay = showerWaterPerDay + bathWaterPerDay

  const hotWaterPerYear = showerWaterPerYear + bathWaterPerYear

  const hotWaterEnergyPerDay = hotWaterPerDay * hotWaterTempEnergy

  const hotWaterEnergyPerYear = hotWaterPerYear * hotWaterTempEnergy

  const heatingTemp = inputs.heatingTemp

  const heatingBaseTemp = heatingTemp + HEATING_BASE_TEMP_DELTA

  const heatingDegreeDaysPerYear = getHeatingDegreeDaysPerYear({ location }, inputs, { heatingBaseTemp })

  const heatingEnergyPerYear = fabric.totalLossFactor * heatingDegreeDaysPerYear * 24 / 1000

  const totalEnergyPerYear = hotWaterEnergyPerYear + heatingEnergyPerYear

  return {
    hotWaterTempEnergy,
    showerWaterPerDay,
    showerWaterPerYear,
    bathWaterPerDay,
    bathWaterPerYear,
    hotWaterPerDay,
    hotWaterPerYear,
    hotWaterEnergyPerDay,
    hotWaterEnergyPerYear,
    heatingTemp,
    heatingBaseTemp,
    heatingDegreeDaysPerYear,
    heatingEnergyPerYear,
    totalEnergyPerYear,
  }
}

function getHeatingDegreeDaysPerYear(
  { location }: Pick<Dependencies, 'location'>,
  { heatingOnMonth, heatingOffMonth }: Inputs,
  { heatingBaseTemp }: Pick<Outputs, 'heatingBaseTemp'>,
): number {
  let tableIndex = Math.floor(heatingBaseTemp - 12.5) / 0.5
  let degreeDaysPerYear = 0

  for (let i = heatingOnMonth - 1; i !== heatingOffMonth; i = (i + 1) % 12) {
    degreeDaysPerYear += location.ddTable[i]![tableIndex]!
  }

  return degreeDaysPerYear
}
